<div class="container">
  <div class="row">
    <div class="col-12 d-flex align-items-center flex-wrap">
      <h4>{{me?.admin ? 'Usuários' : 'Gestores'}}</h4>

      <div class="col-12 col-sm mt-2 my-sm-0 px-0 px-sm-2 d-flex justify-content-center justify-content-sm-end">
        <form class="col-12 col-sm-8 col-lg-5 px-0 px-sm-2 ml-sm-auto">
          <input type="search" class="form-control icon bg-light" placeholder="Nome do usuário" (change)="query.page=1; query.search.filter = $event.target.value; list();">
        </form>
      </div>
    </div>
    <!-- <div class="col-12 col-md-6 offset-md-3 mt-2">
      <form class="inline" #inviteForm="ngForm">
        <label [class.error]="email.touched && email.invalid" for="email" class="mb-2">
          Adicionar usuário
        </label>
        <div class="form-group row">
          <div class="col-12">
            <div class="d-flex align-items-center">
              <input [class.error]="email.touched && email.invalid" type="text" class="form-control w-100" id="email" placeholder="email@email.com.br" name="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}" #email="ngModel" [(ngModel)]="user.email" />
              <div class="col-4 p-0 pl-3">
                <ng-select *ngIf="me?.admin" name="role" [(ngModel)]="query.search.role" (ngModelChange)="list()" [clearable]="false">
                  <ng-option [value]="'admin'">Administradores</ng-option>
                  <ng-option [value]="'manager'">Gestores</ng-option>
                </ng-select>
              </div>
            </div>
            <div *ngIf="email.invalid && email.touched" class="error">
              E-mail inválido
            </div>
          </div>
        </div>
      </form>
    </div> -->
  </div>

  <div class="table-header col-12 mb-2 mt-3 p-0 d-flex flex-wrap">
    <div class="col-5 col-md-3">
      <span class="weight-medium">Usuário</span>
    </div>
    <div class="col-10 col-sm-5 col-md-3 d-none d-sm-flex row-item">
      <span class="weight-medium">E-mail</span>
    </div>
    <div class="col-3 d-none d-md-flex">
      <span class="weight-medium">Telefone</span>
    </div>
    <div class="col-1 d-none d-md-flex">
      <span class="weight-medium">Status</span>
    </div>
  </div>

  <div class="table-row col-12 d-flex flex-wrap" *ngFor="let user of (me?.admin ? users.data : (company?.managers | slice:query.limit*(query.page-1):query.limit*query.page))">
    <div class="col-1 d-none d-sm-flex row-item">
      <img [src]="user.photo || '/assets/icons/avatar.svg'" class="img-lg rounded-circle">
    </div>
    <div class="col-12 col-sm-4 col-md-2 d-none d-sm-flex row-item d-flex align-items-center">
      <span class="weight-medium text-wrap text-medium" [title]="user.name">
        {{ user.name }}
      </span>
    </div>
    <div class="col-10 col-sm-5 col-md-3 row-item">
      <span class="weight-medium text-wrap text-medium" [title]="user.email">
        {{ user.email }}</span>
    </div>
    <div class="col-3 row-item d-none d-md-flex">
      <span class="weight-medium text-medium">
        {{ (user.phone | mask:(user.phone?.length > 10 ? '(99) 9 9999-9999' : '(99) 9999-9999')) || 
            'Sem telefone'}}
      </span>
    </div>
    <div class="col-1 d-none d-md-flex row-item">
      <small class="weight-medium text-nowrap text-white rounded px-2 py-1" [class.bg-danger]="!user.active" [class.bg-green]="user.active">
        {{ user.active ? 'ATIVO' : 'INATIVO' }}
      </small>
    </div>
    <div class="col-2 row-item d-flex justify-content-end align-items-center">
      <button type="button" class="bg-gray border-0 p-1 rounded-circle d-flex align-items-center pointer hover" (click)="confirm($event, user._id)" [tooltip]="me?.admin ? 'Desativar usuário' : 'Remover gestor'" placement="bottom" *ngIf="user.active || me?.manager">
        <div [inlineSVG]="'assets/icons/clear.svg'" class="d-flex align-items-center icon-md icon-medium"></div>
      </button>

      <button type="button" class="bg-gray border-0 p-2 rounded-circle d-flex align-items-center pointer hover" (click)="restore(user._id)" tooltip="Reativar usuário" placement="bottom" *ngIf="me?.admin && !user.active">
        <div [inlineSVG]="'assets/icons/complete.svg'" class="d-flex align-items-center icon-xs icon-medium"></div>
      </button>
    </div>
  </div>

  <!-- <div class="mb-1 p-0" *ngIf="me?.admin">
    <div class="users-wrapper col-12 col-md-6 offset-md-3 p-0">
      <div class="col-12 p-0" *ngFor="let user of users.data">
        <div class="user d-flex align-items-center item-wrapper bg-light p-3 my-2 mx-0 text-left">
          <div class="weight-medium mr-3">
            {{ user.email }}
            {{ !user.active ? '(pendente)' : ''}}
          </div>
          <div class="bg-danger rounded-circle hover pointer ml-auto img-md d-flex align-items-center justify-content-center" (click)="confirm($event, user._id)">
            <div class="d-flex icon-sm icon-white" [inlineSVG]="'/assets/icons/delete.svg'"></div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 d-flex">
        <span *ngIf="users?.data?.length === 0" class="weight-medium text-medium mx-auto mt-3">
          Nenhum usuário encontrado
        </span>
      </div>
    </div>
  </div>

  <div class="users-wrapper col-12 col-md-6 offset-md-3 p-0" *ngIf="!me?.admin">
    <div class="col-12 p-0 px-2" *ngFor="let user of company.managers">
      <div class="user item-wrapper bg-light p-3 m-0 my-2 text-left">
        <span>{{ user.email }}</span>
        <span *ngIf="!user.active">(pendente)</span>
      </div>
    </div>
  </div> -->

  <div class="row px-3 mt-3 d-flex align-items-center">
    <pagination
      *ngIf="(me?.admin ? users.total : company?.managers?.length) > query.limit"
      class="ml-auto"
      [totalItems]="me?.admin ? users.total : company?.managers?.length"
      [maxSize]="3"
      [itemsPerPage]="query.limit"
      (pageChanged)="query.page = $event.page; list()"
      [boundaryLinks]="true"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
      maxSize="5">
    </pagination>

    <form class="d-flex align-items-center ml-auto mr-auto mt-3 mt-md-0 mr-md-0">
      <input type="email" class="form-control icon bg-light" placeholder="E-mail do {{me?.admin ? 'administrador' : 'gestor'}}" [(ngModel)]="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}" #emailEl="ngModel" name="email">
      <button class="bg-secondary text-white px-3 py-2 rounded border-0 weight-medium text-spaced font-md ml-2 pointer" (click)="me?.admin ? invite() : inviteManager()" [disabled]="emailEl.invalid">
        CONVIDAR
      </button>
    </form>
  </div>
<!-- 
  <div class="row mt-2">
    <div class="col-12 col-md-6 offset-md-3">
      <action-button label="Enviar convite" (click)="this.company?.name? inviteManager() : invite()">
      </action-button>
    </div>
  </div> -->
</div>
