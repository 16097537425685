<search-box
  [title]="'Fornecedores'"
  (textChange)="query.search.filter = $event; list()"
  link="./new"
  [search]="true">
</search-box>

<div class="row mb-3">
  <div class="col-12 col-sm-6 col-lg-3 p-1" *ngFor="let provider of providers.data">
    <box-item [data]="provider" title="provider" [onDelete]="deleteProvider()">
    </box-item>
  </div>

  <empty-list
    class="w-100 my-5"
    *ngIf="!loading && (!providers.data || providers.data?.length === 0)">
    <span class="text-primary weight-medium">
      Nenhum fornecedor {{query.search.filter ? 'encontrado' : 'cadastrado'}}
    </span>
  </empty-list>
</div>

<div class="d-flex align-items-center text-center">
  <pagination
    class="ml-auto"
    [totalItems]="providers?.total"
    [itemsPerPage]="query.limit"
    (pageChanged)="query.page = $event.page; list()"
    [boundaryLinks]="true"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
    [rotate]="true"
    [maxSize]="5">
  </pagination>
</div>