<search-box
  title="Produtos"
  (textChange)="query.search.filter = $event; list()"
  link="./new"
  [search]="true">
</search-box>

<div class="row mb-4">
  <div class="col-12 mb-4 types-list">
    <a class="wrapper pointer mr-2 py-2 px-4" [ngClass]="{ 'active': query.search.category === undefined }" (click)="selectCategory(null)">
      Todos
    </a>
    <a *ngFor="let category of categories" class="wrapper pointer mr-2 py-2 px-4" [ngClass]="{ 'active': query.search.category == category._id }" (click)="selectCategory(category)">
      {{category.name}}
    </a>
  </div>

  <div class="col-12 mb-4 types-list" *ngIf="selectedCategory">
    <a class="wrapper pointer mr-2 py-2 px-4" [ngClass]="{ 'active': query.search.segment === undefined }" (click)="selectSegment(null)">
      Todos
    </a>
    <a *ngFor="let segment of selectedCategory.subcategories" class="wrapper pointer mr-2 py-2 px-4" [ngClass]="{ 'active': query.search.segment == segment }" (click)="selectSegment(segment)">
      {{segment}}
    </a>
  </div>

  <div class="col-12 col-sm-6 col-lg-3 p-1" *ngFor="let product of products.data">
    <box-item [data]="product" title="product" [onDelete]="deleteProduct()">
    </box-item>
  </div>
  
  <empty-list class="w-100 my-5" *ngIf="products.data?.length === 0">
    <span class="text-primary weight-medium">
      Nenhum produto encontrado
    </span>
  </empty-list>
</div>

<div class="d-flex align-items-center">
  <pagination
    class="ml-auto"
    [totalItems]="products?.total"
    [itemsPerPage]="query.limit"
    (pageChanged)="query.page = $event.page; list()"
    [boundaryLinks]="true"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
    [rotate]="true"
    [maxSize]="5">
  </pagination>
</div>
