import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  user: any = {};
  token;
  success: boolean;
  error: boolean;

  constructor(private service: UserService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe( paramMap => {
      this.token = paramMap.get('token');
    });
  }

  resetPassword() {
    this.service.resetPassword(this.user.password, this.token).subscribe( res => {
      this.success = true;
      this.router.navigateByUrl('/')
    }, err => {
      this.error = true;
    });
  }

  checkPassword(passwordInput, confirmInput) {
    return (this.user.password !== this.user.confirmPassword) && 
      (passwordInput.touched && confirmInput.touched);
  }
}
