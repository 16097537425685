import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

import { formatDate } from "@angular/common";
import { OrderService } from "../../services/order.service";

@Component({
  selector: "order-edit",
  templateUrl: "./order-edit.component.html",
  styleUrls: ["./order-edit.component.scss"],
})
export class OrderEdit implements OnInit {
  total = 0;
  number = "";
  status = "";
  paymentStatus = "";
  nfe = "";
  date = new Date();
  _date = "";
  nfeDate = new Date();
  _nfeDate = "";
  nfeTotal = 0;
  reason = "";
  id = "";
  mode = "admin";
  payment;
  _reasonReq = false;

  minDate = new Date();
  constructor(public modalRef: BsModalRef, private service: OrderService) {}

  ngOnInit() {
    this._date = formatDate(new Date(this.date), "dd/MM/yyyy", "en-US");
    this.nfeTotal = this.nfeTotal || this.total;

    this._nfeDate = formatDate(new Date(this.nfeDate || this.date), "dd/MM/yyyy", "en-US");
  }

  changeDate(date) {
    this._date = formatDate(date, "dd/MM/yyyy", "en-US");
    this.date = date;
  }

  changeNFeDate(date) {
    this._nfeDate = formatDate(date, "dd/MM/yyyy", "en-US");
    this.nfeDate = date;
  }

  isReasonRequired() {
    this._reasonReq = this.total * 1.1 <= this.nfeTotal;

    return this._reasonReq;
  }

  save() {
    if (this.isReasonRequired() && !this.reason) return;

    const order = {
      _id: this.id,
      date: new Date(this.date),
      nfeDate: new Date(this.nfeDate),
      status: this.status,
      paymentStatus: this.paymentStatus,
      nfe: this.nfe,
      nfeTotal: this.nfeTotal,
      reason: this.reason,
    };

    let service = this.service.save(order);

    if (this.mode == "provider")
      service = this.service.changeStatus(this.id, order);

    service.subscribe((o) => {
      this.modalRef.hide();
    });
  }
}
