import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ptBrLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";

import { UserService } from "src/app/shared/services/user.service";
import { OrderService } from "src/app/shared/services/order.service";
import { OfferService } from "src/app/shared/services/offer.service";
import { Query } from "src/app/shared/util/query";
import { CompanyService } from "src/app/shared/services/company.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { OrderEdit } from "src/app/shared/ui/order-edit/order-edit.component";

import getProductPrice from "src/app/shared/util/getProductPrice";
import getOrderTotal from "src/app/shared/util/getOrderTotal";

defineLocale("pt-br", ptBrLocale);

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit {
  isCollapsed = false;
  routeId: any;
  isPublicPage = false;
  me: any = {};
  providers;

  order: any = {};
  offers: any = {};
  selectedOffer: any;
  bestOffer: any;

  status = {
    w_waiting: "Pedido Realizado",
    i_accepted: "Pedido Confirmado",
    i_inprogress: "Pedido Faturado",
    f_completed: "Pedido Finalizado",
    f_canceled: "Cancelado",
  };

  paymentStatus = {
    pending: "Aguardando pagamento",
    paid: "Pagamento realizado",
    overdue: "Pagamento atrasado",
  };

  statusIndex: number;
  total: any = 0;

  isEditingDate = false;
  modalRef: BsModalRef;

  getPrice = getProductPrice;
  getTotal = getOrderTotal;

  constructor(
    private userService: UserService,
    private service: OrderService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private router: Router
  ) {}

  ngOnInit() {
    this.localeService.use("pt-br");
    this.routeId = this.route.snapshot.params.id;

    this.route.queryParams.subscribe((params) => {
      if (this.route.snapshot.url[0].path === "public") {
        this.isPublicPage = true;
        this.userService.showMenu.next(false);
      } else {
        this.userService.me().subscribe((user) => (this.me = user));
      }
    });

    this.companyService.getCompany().subscribe((company) => {
      this.order.client = company._id;
      this.get();
    });
  }

  loading = false;
  notify() {
    if (this.me.admin) {
      this.loading = true;
      this.service.notify(this.routeId).subscribe((_) => {
        this.loading = false;
      });
    }
  }

  open(order) {
    open("#/public/order/" + order._id);
  }

  get() {
    this.service.get(this.routeId).subscribe((order: any) => {
      this.order = order;

      this.order.date = new Date(this.order.date);
    });
  }

  openModal() {
    this.modalRef = this.modalService.show(OrderEdit, {
      initialState: {
        id: this.order._id,
        total: this.order.total,
        nfeTotal: this.order.nfeTotal,
        number: this.order.number,
        status: this.order.status,
        payment: this.order.payment,
        paymentStatus: this.order.paymentStatus,
        nfe: this.order.nfe,
        date: this.order.date,
        nfeDate: this.order.nfeDate,
        reason: this.order.reason,
      },
    });

    this.modalService.onHide.subscribe((r) => {
      this.get();
    });
  }

  payment() {

    return this.order.client.paymentMethod || 'Faturado'
  }

  repeat() {
    this.router.navigateByUrl("/home/" + this.order._id);
  }
}
