import { tap, catchError } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Query } from '../util/query';
import { EMPTY, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  API = environment.url;
  TOKEN = localStorage.getItem(environment.token);

  constructor(private http: HttpClient,
              private loadingService: LoadingService ) { }

  list(q = new Query(), showLoading = true) {
    if (showLoading) this.loadingService.show();
    return this.http.get(`${this.API}/provider${q.get()}`).pipe(
      tap(() => { if (showLoading) this.loadingService.hide(); }),
      catchError(() => {
        if (showLoading) this.loadingService.hide();
        return EMPTY;
      })
    );
  }

  get(id) {
    return this.http.get(`${this.API}/provider/${id}`);
  }

  save(provider) {
    if (provider._id) return this.http.put(`${this.API}/provider/${provider._id}`, provider)
    return this.http.post(`${this.API}/provider`, provider);
  }

  delete(id) {
    this.loadingService.show();
    return this.http.delete(`${this.API}/provider/${id}`)
      .pipe(
        tap(() => this.loadingService.hide()),
        catchError( (error) => {
          this.loadingService.hide();
          return of(error);
        })
      );
  }

  upload(id, file) {
    const fd = new FormData();
    fd.append('image', file);
    return this.http.post(`${this.API}/provider/${id}/upload`, fd);
  }
}
