import { Router } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { EMPTY, of, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LoadingService } from './loading.service';
import { Query } from '../util/query';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  API = environment.url;
  TOKEN = localStorage.getItem(environment.token);
  showMenu = new EventEmitter<boolean>();
  user: any;

  constructor(private http: HttpClient,
              private loadingService: LoadingService,
              private companyService: CompanyService,
              private router: Router) { }

  list(query = new Query()) {
    return this.http.get(`${this.API}/user${query.get()}`);
  }

  create(user) {
    return this.http.post(`${this.API}/user`, user)
  }

  edit(user) {
    return this.http.put(`${this.API}/me`, user);
  }

  login(credentials) {
    this.loadingService.show();
    return this.http.post(`${this.API}/me`, credentials)
    .pipe(
      tap((res: any) => {
        const { token, ...user } = res;
        this.loadingService.hide();
        localStorage.setItem(environment.token, token);
        this.TOKEN = token;
        // this.user = user;
        this.showMenu.emit(true);
        return token;
      }),
      catchError((err) => {
        this.loadingService.hide();
        return throwError(err);
      })
    );
  }

  setToken(token) {
    localStorage.setItem(environment.token, token);
    this.TOKEN = token;
  }

  setMe(user) { this.user = user; }

  logout() {
    delete this.user;
    delete this.TOKEN;
    localStorage.clear();
    this.companyService.set()
    this.router.navigateByUrl('/user/signin');
  }

  me() {
    if (!this.user)
      return this.http.get(`${this.API}/me`).pipe(
        tap((user) => this.user = user ),
        catchError((err) => {
          this.loadingService.hide();
          this.logout()
          return err
        })
      );
    return of(this.user);
  }

  acceptTerms() {
    return this.http.post(`${this.API}/me/acceptTerms`, { })
  }

  recoveryPassword(email) {
    return this.http.post(`${this.API}/me/password`, { email: email })
  }

  resetPassword(password, token) {
    return this.http.post(`${this.API}/me/password/${token}`, { password });
  }

  invite(user) {
    this.loadingService.show();
    return this.http.put(`${this.API}/user/invite`, user).pipe(
      tap(this.loadingService.hide),
      catchError(() => {
        this.loadingService.hide();
        return EMPTY;
      })
    );
  }

  upload(image: File) {
    const fd = new FormData();
    fd.append('image', image);
    return this.http.put(`${this.API}/me/photo`, fd);
  }

  get(id) {
    return this.http.get(`${this.API}/user/${id}`);
  }

  delete(id) {
    return this.http.put(`${this.API}/user/${id}`, {active: false});
  }

  restore(id) {
    return this.http.put(`${this.API}/user/${id}`, {active: true});
  }
}
