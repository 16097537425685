import { Query } from 'src/app/shared/util/query';
import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { CategoryService } from 'src/app/shared/services/category.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  query: Query = new Query();
  products: any = {};

  types = [];
  categories;
  selectedCategory;

  constructor(private service: ProductService,
              private categoryService: CategoryService) { }

  ngOnInit() {
    this.query.limit = 20;
    this.query.search = {
      isActive: false
    };
    this.list();
    this.listCategories();
  }

  list() {
    this.service.list(this.query).subscribe((products: any) => {
      this.products = products;

      if (this.types.length === 0) {
        const typesMap = {};
        this.products.data.filter( p => p.type?.length).forEach( p => typesMap[p.type] = 1 );
        this.types = Object.keys(typesMap);
      }
    });
  }

  listCategories() {
    const query = new Query();
    query.limit = 0
    this.categoryService.list(query).subscribe((categories: any) => {
      this.categories = categories.data;
    })
  }

  selectCategory(category) {
    delete this.query.search.segment;
    if (category) {
      this.selectedCategory = category;
      this.query.search.category = category._id;
    } else {
      this.selectedCategory = null;
      delete this.query.search.category;
    }

    this.list();
  }

  selectSegment(segment) {
    if (segment) {
      this.query.search.segment = segment;
    } else {
      delete this.query.search.segment;
    }

    this.list();
  }

  deleteProduct() {
    return (id) =>
      this.service.delete(id).subscribe(() => {
        this.products.data = this.products.data.filter(p => p._id !== id);
      });
  }
}
