import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


import { ConfirmWindowComponent } from '../confirm-window/confirm-window.component';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.scss']
})
export class SchoolListComponent implements OnInit {

  @Input() school: any;
  @Input() onDelete;
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private service: CompanyService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  buy(company, e) {
    e.preventDefault()
    this.service.set(company)

    this.router.navigateByUrl('/home')
  }

  manager(school) {
    return (school && school.manager && school.manager[0])
  }

  openModal(e, id) {
    e.stopPropagation();
    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        title: 'school',
        id: id,
        onYes: this.onDelete
      }
    });
  }
}
