<div id="stepper1" class="bs-stepper">
  <header class="text-center">
    <div class="container">
      <div class="steps-wrapper row no-margin-sm">
        <div class="col-12">
          <h3>{{routeId ? 'Editar' : 'Cadastrar'}} Produto</h3>
        </div>
        <div class="col-12 col-md-6 offset-md-3 mb-4 mt-3">
          <p class="weight-medium mb-1">{{ stepper?.title }}</p>
          <small class="weight-medium mb-4">{{ product.code || 'Criação Manual Necessária' }}</small>
          <div class="bs-stepper-header">
            <div class="step" data-target="#product">
              <button class="step-trigger" (click)="stepper.to(0);">
                <span class="bs-stepper-circle"></span>
                <span class="bs-stepper-label">Definições</span>
                <span class="bs-stepper-desc">Definições básicas do produto</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#media">
              <button class="step-trigger" (click)="stepper.to(1);">
                <span class="bs-stepper-circle"></span>
                <span class="bs-stepper-label">Sobre</span>
                <span class="bs-stepper-desc">Informações adicionais sobre o produto</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#offers">
              <button class="step-trigger" (click)="stepper.to(2);">
                <span class="bs-stepper-circle"></span>
                <span class="bs-stepper-label">Fornecedores</span>
                <span class="bs-stepper-desc">Gerencie as ofertas dos fornecedores</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="container">
    <div class="bs-stepper-content">
      <div class="row">
        <div class="col-12 my-3 px-2">
          <back-button *ngIf="stepper?.step > 0" (click)="stepper?.previous()">
          </back-button>
        </div>
      </div>
      <div id="product" class="content">
        <form #step1="ngForm">
          <div class="row">
            <div class="col-md-3 mb-3">
              <label for="name">Segmento</label>
              <ng-select [items]="categories.data" bindLabel="name" name="category" [(ngModel)]="product.category" #category="ngModel" required></ng-select>
            </div>
            <div class="col-md-3 mb-3">
              <label for="name">Categoria</label>
              <input [class.error]="segment.invalid && (segment.dirty || segment.touched)" type="text" class="form-control" id="segment" type="segment" name="segment" [(ngModel)]="product.segment" #segment="ngModel">
            </div>
            <div class="col-md-3 mb-3">
              <label [class.error]="type.invalid && (type.dirty || type.touched)" for="type">
                Tipo / Grupo
              </label>
              <input [class.error]="type.invalid && (type.dirty || type.touched)" type="text" class="form-control" id="type" type="type" name="type" [(ngModel)]="product.type" #type="ngModel" required>
              <div *ngIf="type.invalid && (type.dirty || type.touched)" class="error">
                <div *ngIf="type.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <label [class.error]="type.invalid && (type.dirty || type.touched)" for="type">
                &nbsp;
              </label>
              <label class="custom-checkbox-container mt-3">
                <input type="checkbox" id="active" name="active" [(ngModel)]="product.active">
                <label for="active" class="text-primary">Disponível</label>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3 mb-3">
              <label>
                Marca
              </label>
              <input type="text" class="form-control" id="brand" name="brand" [(ngModel)]="product.brand" #brand="ngModel">
            </div>
            <div class="col-12 col-md-9 mb-3">
              <label [class.error]="name.invalid && (name.dirty || name.touched)" for="name">
                Produto
              </label>
              <input [class.error]="name.invalid && (name.dirty || name.touched)" type="text" class="form-control" id="name" name="name" [(ngModel)]="product.name" #name="ngModel" required>
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                <div *ngIf="name.errors.required">
                  Este campo é obrigatório
                </div>
                <div *ngIf="name.errors.duplicate">
                  Já existe um produto com este nome
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <h2>Configurações de oferta</h2>
            </div>

            <div class="col-md-3 mb-3">
              <label for="name">Preço no fornecedor</label>
              <ng-select name="provider" required [(ngModel)]="product.unit.provider" (ngModelChange)="updateUnit()">
                <ng-option value="un">Unidade</ng-option> 
                <ng-option value="kg">Peso (Kg)</ng-option>
              </ng-select>
            </div>

            <div class="col-12 col-md-3 mb-3" *ngIf="product.unit && product.unit.provider != 'un'">
              <label [class.error]="type.invalid && (type.dirty || type.touched)" for="type">
                &nbsp;
              </label>
              <label class="custom-checkbox-container mt-3">
                <input type="checkbox" name="sellUnit" [(ngModel)]="product.unit.sellUnit">
                <label for="sellUnit"  class="text-primary">Vendido por Unidade</label>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 mb-3" *ngIf="product.unit?.sellUnit">
              <div>
                <label for="name">Peso unitário</label>
                <input type="text" class="form-control text-right" id="weight" type="weight" name="weight" currencyMask [options]="{ prefix: '', precision: 3, suffix: ' Kg', thousands: '.', decimal: ',' }" [(ngModel)]="product.unit.amount" #weight="ngModel">
              </div>
            </div>

            <div class="col-md-3 mb-3" *ngIf="product.unit?.sellUnit">
              <label for="name">Opções</label>
              <ng-select name="options" required [(ngModel)]="product.unit.options">
                <ng-option value="all">Ambos</ng-option> 
                <ng-option value="un">Apenas Unidade</ng-option> 
                <ng-option value="kg">Apenas Peso (Kg)</ng-option>
              </ng-select>
            </div>

            <div class="col-md-3 mb-3" *ngIf="product.unit?.sellUnit">
              <label for="name">Padrão de venda</label>
              <ng-select name="unity" required [(ngModel)]="product.unit.label">
                <ng-option *ngIf="product.unit.options != 'kg'" value="un">Unidade</ng-option> 
                <ng-option *ngIf="product.unit.options != 'un'"value="kg">Peso (Kg)</ng-option>
              </ng-select>
            </div>
          </div>
        </form>
      </div>
      <div id="media" class="content">
        <form #step2="ngForm">
          <div class="row">
            <div class="row col-12 mb-3">
              <div class="col-md-6">
                <label>Palavras-chave</label>

                <div class="mb-2">
                  <small class="text-medium weight-medium" >
                    Estas palavras serão usadas para facilitar a busca do produto
                  </small>
                </div>
                
                <input type="text" class="form-control" id="weight" name="weight" placeholder="Palavra-chave" (change)="addTag($event.target.value); $event.target.value = ''" autocomplete="off">

                <div class="d-flex align-items-center flex-wrap mt-2">
                  <div class="d-flex align-items-center py-1 px-2 bg-secondary rounded mr-1 mb-1" *ngFor="let tag of product?.tags">
                    <small class="text-white weight-medium">{{ tag }}</small>
                    <small class="text-white weight-medium ml-2 pointer" (click)="removeTag(tag)">&#10005;</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label [class.error]="description.invalid && (description.dirty || description.touched)" for="description">
                Descrição
              </label>
              <textarea [class.error]="description.invalid && (description.dirty || description.touched)" rows="6" class="form-control" id="description" name="description" [(ngModel)]="product.description" #description="ngModel"></textarea>
              <div *ngIf="description.invalid && (description.dirty || description.touched)" class="error">
                <div *ngIf="description.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label [class.error]="observation.invalid && (observation.dirty || observation.touched)" for="observation">
                Informações Técnicas
              </label>
              <textarea [class.error]="observation.invalid && (observation.dirty || observation.touched)" rows="6" class="form-control" id="observation" name="observation" [(ngModel)]="product.observation" #observation="ngModel"></textarea>
              <div *ngIf="observation.invalid && (observation.dirty || observation.touched)" class="error">
                <div *ngIf="observation.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="logo">Foto principal</label>
              <div class="row">
                <div class="col-12 d-flex align-items-start">
                  <input type="text" class="form-control w-100" placeholder="Selecione uma imagem..." id="mainImage" name="mainImage" (click)="mainFileUpload.click()" required>
                  <input type="file" name="file" (change)="onMainFileSelect($event)" #mainFileUpload/>
                  <div class="ml-3 file-preview" *ngIf="mainImage">
                    <img [src]="mainImage">
                    <div class="remove p-0" (click)="emptyMainImage()">
                      <span>x</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 mb-3">
              <label for="logo">Outras fotos</label>
              <div class="row">
                <div class="col-12" *ngIf="product?.images?.length < 6">
                  <input type="text" class="form-control" placeholder="selecione uma imagem..." id="images" name="images" (click)="fileUpload.click()">
                  <input type="file" name="file" (change)="onFileSelect($event)" #fileUpload/>
                </div>
                <div class="file-preview d-flex align-items-start justify-items-between mt-0 mr-2 pl-3" *ngFor="let image of fileImages; let i = index;">
                  <input type="file" name="file" (change)="onFileSelect($event, i)" id="file{{i}}"/>
                  <label for="file{{i}}" class="pointer">
                    <img class="mr-3" [src]="image || '/assets/icons/image.svg'">
                  </label>
                  <div *ngIf="image" class="remove p-0" (click)="emptyImageSelect(i)">
                    <span>x</span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </form>
      </div>
      <div id="offers" class="content">
        <form #step3="ngForm">
          <div class="row" [ngClass]="{ 'first-row': i == 0 }" *ngFor="let offer of offers; let i = index">
            <div class="col-8 col-md-9 pr-0 mb-1">
              <label>Fornecedor</label>
              <ng-select [items]="providers.data" bindValue="_id" bindLabel="name" name="provider{{i}}" [(ngModel)]="offer.provider"></ng-select>
            </div>
            <div class="col-4 col-md-2 pr-0 mb-1">
              <label>Preço (R$)</label>
              <input type="text" class="form-control text-right" id="price{{i}}" name="price{{i}}" [ngModel]="offer.price" (ngModelChange)="offer.price = $event" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
            </div>
            <div class="col-12 col-md-1 text-center mb-1">
              <label class="mr-2">Remover</label>
              <img class="pointer" src="/assets/icons/cancel-lg.svg" (click)="removeProviderInput(i, offer)">
            </div>
          </div>
          <div class="col-12 p-0 my-3">
            <button class="btn button-rounded bg-gradient" (click)="addProviderInput()">+</button>
            <span class="ml-2 weight-medium">Adicionar fornecedor</span> 
          </div>
        </form>
      </div>

      <div class="col-12 col-lg-6 offset-lg-3 px-0">
        <action-button [label]="(!product._id && stepper?.step < 1) ? 'Próximo' : 'Salvar'" [fixed]="true" (click)="(!product._id && stepper?.step < 1) ? forward() : (stepper?.step < 2) ? saveProduct():save()" [disabled]="stepper?.step === 0 ? step1.invalid : step2.invalid"></action-button>
      </div>
    </div>
  </div>
</div>