import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Query } from 'src/app/shared/util/query';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  API = environment.url;
  TOKEN = localStorage.getItem(environment.token);
  selected;

  constructor(private http: HttpClient) { }

  list(q = new Query()) {
    return this.http.get(`${this.API}/category${q.get()}`)
  }

  get(id) {
    return this.http.get(`${this.API}/category/${id}`)
  }

  save(category) {
    if (category._id) return this.http.put(`${this.API}/category/${category._id}`, category)
    return this.http.post(`${this.API}/category`, category)
  }

  delete(id) {
    return this.http.put(`${this.API}/category/${id}/active`, { active: false })
  }

  setSelected(id) { this.selected = id;   }
  getSelected()   { return this.selected; }
}
