import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Query } from '../util/query';


@Injectable({
  providedIn: 'root'
})
export class OfferService {

  API = environment.url;
  TOKEN = localStorage.getItem(environment.token);

  constructor(private http: HttpClient) { }

  list(q = new Query()) {
    return this.http.get(`${this.API}/offer${q.get()}`);
  }

  getCheaper(id) {
    return this.http.get(`${this.API}/offer?cheaper=${id}`);
  }

  get(id) {
    return this.http.get(`${this.API}/offer/${id}`);
  }

  save(offer) {
    return this.http.post(`${this.API}/offer`, offer);
  }

  delete(id) {
    return this.http.delete(`${this.API}/offer/${id}`);
  }
}
