import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Input() placeholder;
  @Output() search = new EventEmitter();
  @Output() inputChange = new EventEmitter();
  value: string;

  constructor() { }

  ngOnInit(): void {
  }

}
