<app-navbar *ngIf="showMenu"></app-navbar>
<div class="whatsapp d-print-none">
  <a href="https://wa.me/5551999999016" target="_blank">
    <img src="/assets/icons/whatsapp.png" width="100%">
  </a>
</div>
<div class="content" [ngClass]="{'spaced-top': showMenu}">
  <router-outlet></router-outlet>
</div>
<ngx-loading
  [show]="showLoading"
  [config]="{
    primaryColour: '#01FB8D',
    animationType: loadingType,
    fullScreenBackdrop: true
  }">
</ngx-loading>
