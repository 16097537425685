<div class="row mb-3">
  <div class="col-md-12 text-center">
    <img [src]="company?.logo || '/assets/icons/avatar.svg'" class="logo">
  </div>
</div>

<div class="row px-3">
  <div class="col-md-12">
    <span class="school-name">{{ company.name }}</span>
  </div>
  <div class="col-12 col-md-10 p-0">
    <div class="item-wrapper border-bottom d-flex justify-content-between text-left">
      <div>
        <label class="placeholder">Nome</label>
        <p>{{ company.companyName }}</p>
      </div>
      <div>
        <label class="placeholder">{{isPhysical ? 'CPF' : 'CNPJ'}}</label>
        <p>{{isPhysical ? (company.cpf | mask:'000.000.000-00') : (company.cnpj | mask:'00.000.000/0000-00')}}</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-2 p-0" *ngIf="!isPhysical && company.category === 'escola'">
    <div class="item-wrapper border-bottom d-flex justify-content-between text-left">
      <div>
        <label class="placeholder">Nº de alunos</label>
        <p>{{ company.students || 'Indefinido'}}</p>
      </div>
    </div>
  </div>
  <div class="col-md-6 p-0">
    <div class="item-wrapper border-bottom text-left">
      <label class="placeholder">Endereço da entidade</label>
      <p>{{ company.address?.street }}, {{ company.address?.number }} - {{ company.address?.city }} / {{ company.address?.state }}</p>
      <p></p>
    </div>
  </div>
  <div class="col-md-6 p-0">
    <div class="item-wrapper border-bottom text-left">
      <label class="placeholder">Endereço de entrega</label>
      <p>{{ company.deliveryAddress?.street }}, {{ company.deliveryAddress?.number }} - {{ company.deliveryAddress?.city }} / {{ company.deliveryAddress?.state }}</p>
    </div>
  </div>
  <div class="col-md-12 p-0">
    <div class="item-wrapper border-bottom text-left">
      <label class="placeholder">Restrição de horários</label>
      <p>Horários</p>
    </div>
  </div>
</div>

<div class="row px-3 mt-3">
  <div class="col-md-12">
    <h5>Gestores</h5>
  </div>
  <div class="col-md-6 p-0" *ngFor="let manager of company.managers">
    <div class="manager item-wrapper pb-3 border-bottom d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <img [src]="manager.photo || '/assets/images/user-placeholder.png'" class="manager-photo rounded-circle">
        <div class="text-left ml-2">
          <span class="manager-name m-0">{{ manager.name }}</span>
          <p class="manager-email text-medium m-0">{{ manager.email }}</p>
          <p class="manager-phone text-medium m-0">{{ (manager.phone | mask:'(00) 0000-0000 || (00) 0 0000-0000') || 'Sem telefone'}}</p>
        </div>
      </div>
      <!-- <span class="text-right mb-auto">DIRETORA</span> -->
    </div>
  </div>
  <div class="col-md-12" *ngIf="company.managers?.length == 0">
    <label>Não existe nenhum gestor cadastrado para esta escola</label>
  </div>
  <div class="col-md-12 text-center actions my-3">
    <img src="/assets/icons/edit-lg.svg" class="mr-4" routerLink="../{{ company._id }}/edit">
    <img src="/assets/icons/cancel-lg.svg" (click)="openModal(company._id)">
  </div>
</div>