import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { CompanyService } from "src/app/shared/services/company.service";
import { UserService } from "src/app/shared/services/user.service";
import { BsModalService } from "ngx-bootstrap/modal";

import getProductPrice from "src/app/shared/util/getProductPrice";
import getOrderTotal from "src/app/shared/util/getOrderTotal";

import { Query } from "src/app/shared/util/query";

import PaymentMethods from "src/app/shared/util/paymentMethods";

import { ReportConfigModal } from "./config-modal/config-modal.component";

@Component({
  selector: "app-school-report",
  templateUrl: "./school-report.component.html",
  styleUrls: ["./school-report.component.scss"],
})
export class SchoolReportComponent implements OnInit {
  orders: any;
  providers: any;
  segments: any;
  totals: any;

  subtotal: number;
  total: number;
  bonus: number = 0;

  expiration: Date = new Date();
  generalComments;
  clientComments;

  startDate: Date = new Date();
  endDate: Date = new Date();

  paymentMethod;
  company: any;

  query;
  modalRef;

  loadingReceipt: boolean = false;

  getPrice = getProductPrice;
  getTotal = getOrderTotal;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private modalService: BsModalService,
    private userService: UserService
  ) {
    userService.showMenu.next(false);
  }

  ngOnInit() {
    const companyId = this.route.snapshot.params.id;

    this.query = this.route.snapshot.queryParams;

    this.startDate = new Date(
      this.query.nfeDate_start ||
        this.query.created_start ||
        this.query.date_start ||
        this.query.startDate ||
        new Date()
    );
    this.endDate = new Date(
      this.query.nfeDate_end ||
        this.query.created_end ||
        this.query.date_end ||
        this.query.endDate ||
        new Date()
    );

    this.paymentMethod =
      PaymentMethods.find(
        (p) => p.key == (localStorage.getItem("method") ?? this.query.method)
      ) || {};

    this.expiration = new Date(
      localStorage.getItem("expiration") ?? this.query.expiration
    );

    this.generalComments = localStorage.getItem("generalComments") ?? "";
    this.clientComments = this.query.clientComments ?? "";

    if (!this.expiration || !this.paymentMethod.label) this.setConfig();

    this.getCompany(companyId);
  }

  getCompany(companyId) {
    this.companyService.get(companyId).subscribe((company: any) => {
      this.company = company;

      this.getOrders();
    });
  }

  getOrders() {
    const query: Query = new Query();
    query.search = this.query;

    this.companyService
      .getReport(this.company._id, query)
      .subscribe((report: any) => {
        this.totals = report.totals;
        this.orders = report.orders;

        this.segments = report.segments.sort((a, b) => b.total - a.total);
        this.providers = report.providers.sort((a, b) => b.total - a.total);
      });
  }

  setConfig() {
    this.modalService.show(ReportConfigModal, {
      initialState: {
        expiration: this.expiration,
        method: this.paymentMethod.key,
        generalComments: this.generalComments,
        clientComments: this.clientComments,
      },
    });
  }

  print() {
    window.print();
  }

  createReceipt() {
    this.loadingReceipt = true;

    const payload = {
      ...this.query,
      client: this.company._id,
      expiration: new Date(localStorage.getItem("expiration") || this.query.expiration),
    };

    this.companyService.createReceipt(this.company._id, payload).subscribe(
      (report: any) => {
        this.loadingReceipt = false;
      },
      (error) => {
        this.loadingReceipt = false;
      }
    );
  }
}
