import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProductService } from 'src/app/shared/services/product.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Query } from 'src/app/shared/util/query';
import { CategoryService } from 'src/app/shared/services/category.service';
import { LoadingService } from 'src/app/shared/services/loading.service';

import getProductPrice from 'src/app/shared/util/getProductPrice';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductListComponent implements OnInit {

  category: any;
  company: any;
  products: any = [];
  offers: any = [];
  cart: any = [];
  total = 0;
  query = new Query();
  types: any = [];
  type = "";
  filter: string;
  maxItems = 10;

  categoryId;
  segment;

  getPrice = getProductPrice;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: ProductService,
              private loadingService: LoadingService,
              private orderService: OrderService,
              private companyService: CompanyService,
              private categoryService: CategoryService) { }

  ngOnInit() {
    const orderId = this.route.snapshot.params.order
    
    if (orderId) {
      return this.orderService.get(orderId).subscribe((_order: any) => {
        let order: any = {};

        order.products = _order.offers.map((order) => {
          let { amount, selectedUnit, offer } = order;
          let { product, segment } = offer;

          return {
            amount,
            selectedUnit,
            segment,
            product: product._id,
            ...product,
          };
        });

        order.category = _order.category;
        order.subcategory = order.products[0].segment;

        this.orderService.clearCart();
        this.orderService.setCart(order);
        this.orderService.saveCart();

        this.companyService.set(_order.client);

        this.categoryId = order.category
        this.segment = order.subcategory

        this.getCategory();
        this.getCompany();
      });
    }else {
      this.categoryId = this.route.snapshot.params.id
      this.segment = this.route.snapshot.params.sub

      this.getCategory();
      this.getCompany();
    }
  }

  getCompany() {
    this.companyService.watchCompany().subscribe( company => {
      this.company = company;
      
      if(!company.deliveryAddress?.city && !company.setAddress)
        this.setAddress(company.address)
      
      this.list();
    });
  }
  
  list() {
    if(!this.company?.setAddress && this.loadingService.loading) return
    this.query.search.company = this.company._id;
    this.query.search.city = `${this.company.setAddress.city}, ${this.company.setAddress.state}`
    this.query.search.neighbourhood = this.company.setAddress.neighbourhood;
    this.query.search.category = this.categoryId;
    this.query.search.segment = this.segment;
    this.query.search.prices = true;
    this.service.list(this.query).subscribe((products: any) => {
      const types = {};
      this.products = products;

      this.products.data = this.products.data.map( p => {
        types[p.type] = 1;

        const search = this.normalize(`${p.name} ${p.brand || ''} ${p.tags.join(' ')}`)

        return ({ ...p, amount: 0, price: p.cheapest, search });
      } );

      this.types = Object.keys(types);
      this.types.sort((a,b) => (a > b ? 1:-1) )
      this.syncWithCart();
    });
  }

  calculateDelivery() {
    if(!this.company.deliveryAddress?.city || this.company.address?.city == this.company.deliveryAddress?.city) {
      return `${this.company?.setAddress?.street}, ${this.company?.setAddress?.number}`
    }else
      return `${this.company?.setAddress?.city}, ${this.company?.setAddress?.state}`
  }

  normalize(text) {
    return text
           .toLocaleLowerCase()
           .replace(/[ÁÀÂÃ]/gi, 'a')
           .replace(/[ÉÈÊ]/gi, 'e')
           .replace(/[ÍÌÎ]/gi, 'i')
           .replace(/[ÓÒÔÕ]/gi, 'o')
           .replace(/[ÚÙÛ]/gi, 'u')
           .replace(/[Ç]/gi, 'c');
  }

  setFilter(text) {
    this.filter = this.normalize(text);
  }

  count = 0;
  showItem(product, index) {
    if(index == 0) this.count = 0

    if (this.count < this.maxItems
    && (!this.filter || product.search.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)
    && (!this.type || this.type == product.type)) {
      this.count++;

      return true
    }

    return false
  }

  setAddress(address) {
    this.companyService.set({ ...this.company, setAddress: address })
  }

  syncWithCart() {
    this.orderService.getCart().subscribe( cart => {
      for (const product of cart.products) {
        const found = this.products.data.find( p => p._id === product.product );
        if (!found) continue;

        const { _id, amount, name, mainImage, weight, selectedUnit } = product;
        this.cart.push({ _id, amount, name, mainImage, weight, selectedUnit });
        
        found.selectedUnit = selectedUnit;
        found.amount = product.amount;
        
        this.total += product.amount;
      }
    });
  }

  getCategory() {
    this.categoryService.get(this.categoryId).subscribe( category => {
      this.category = category;
    });
  }

  oldValue;
  change(product, cTotal = true) {
    if(cTotal) this.total+= product.amount - this.oldValue

    this.orderService.setProduct(product);
  }

  add(product) {
    this.total++;
    product.amount++;
    this.orderService.setProduct(product);
  }

  subtract(product) {
    this.total--;
    product.amount--;
    this.orderService.setProduct(product);
  }
}
