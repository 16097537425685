<search-box
  [title]="(me?.admin ? 'Pedidos':'Meus Pedidos')"
  [link]="(!me?.admin && '/home')"
  (textChange)="query.search.filter = $event; list()"
  [search]="true">
</search-box>

<div class="row mb-3">
  <ng-container>
    <table class="table">
      <tr>
        <th class="weight-medium">N°</th>
        <th class="weight-medium" *ngIf="me?.admin">Cliente</th>
        <th class="weight-medium">Fornecedor</th>
        <th class="weight-medium">Status</th>
        <th class="weight-medium">Pedido</th>
        <th class="weight-medium">Entrega</th>
        <th class="weight-medium text-right">Total</th>
        <th class="weight-medium text-right"></th>
      </tr>
      <tr class="table-row pointer" *ngFor="let order of orders?.data" [routerLink]="[order._id]">
        <td class="weight-medium">
          {{ order.number }}
          <span *ngIf="!order.number" class="order-test">TESTE</span>
        </td>
        <td *ngIf="me?.admin">{{ order.client?.name }}</td>
        <td>
          {{ order.provider.name }}
        </td>
        <td>
          <small class="weight-medium text-uppercase text-nowrap rounded px-2 py-1 {{ status[order.status].class }}">
            {{ status[order.status].label }}
          </small>
          <small *ngIf="order.status == 'i_inprogress' && me?.admin" class="bg-green rounded px-2 py-1 text-white ml-2" (click)="confirm($event, order)">OK</small>
        </td>
        <td>{{ order.createdAt | date:'dd/MM/yyyy HH:mm' }}</td>
        <td>{{ order.date | date:'dd/MM/yyyy' }}</td>
        <td class="weight-medium text-green text-right">
          {{ order.nfeTotal || order.total | currency:'BRL' }}
        </td>
        <td [routerLink]="['/home', order._id]">
          <small *ngIf="!me?.admin" class="bg-green rounded px-2 py-1 text-white text-nowrap mx-2">Repetir</small>
          <img *ngIf="me?.admin" src="/assets/images/logo.png" height="20">
        </td>
      </tr>
    </table>
  </ng-container>

  <div class="col-12 mt-3 d-flex flex-column flex-md-row align-items-center">
    <pagination
      class="mt-2 ml-auto"
      [(ngModel)]="query.page"
      [totalItems]="orders?.total"
      [itemsPerPage]="query.limit"
      (pageChanged)="query.page = $event.page; list()"
      [boundaryLinks]="true"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
      [rotate]="true"
      [maxSize]="5">
    </pagination>
  </div>
</div>

<div class="row mb-3" *ngIf="!this.me?.admin && !(this.company?.name) && !this.loading">
  <div class="col-12">
    <span class="text-medium weight-medium">Selecione uma escola para ver os pedidos realizados</span>
  </div>
</div>

<empty-list class="w-100 my-5" *ngIf="this.company?.name && !loading && (!orders.data || orders.data?.length === 0)">
  <span class="text-medium weight-medium">
    Nenhum pedido {{query.search.filter ? 'encontrado' : 'cadastrado'}}
  </span>
</empty-list>