import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CurrencyPipe, CommonModule } from "@angular/common";

import { InlineSVGModule } from "ng-inline-svg";
import { ModalModule, BsModalRef } from "ngx-bootstrap/modal";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TextMaskModule } from "angular2-text-mask";
import { NgSelectModule } from "@ng-select/ng-select";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { NgxLoadingModule } from "ngx-loading";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";

import { NgxMaskModule } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { ToastrModule } from "ngx-toastr";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpServiceInterceptor } from "./shared/auth/auth.interceptor";
import { AuthGuard } from "./shared/auth/auth.guard";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { NavbarComponent } from "./shared/ui/navbar/navbar.component";
import { ConfirmWindowComponent } from "./shared/ui/confirm-window/confirm-window.component";
import { StepperComponent } from "./shared/ui/stepper/stepper.component";
import { ActionButtonComponent } from "./shared/ui/action-button/action-button.component";
import { SchoolListComponent } from "./shared/ui/school-list/school-list.component";
import { SearchBoxComponent } from "./shared/ui/search-box/search-box.component";
import { OrderListComponent } from "./shared/ui/order-list/order-list.component";
import { BoxItemComponent } from "./shared/ui/box-item/box-item.component";
import { DashboardComponent } from "./compoments/dashboard/dashboard.component";
import { PasswordDirective } from "./modules/user/password-directive";

import { UserComponent } from "./modules/user/user.component";
import { SigninComponent } from "./modules/user/signin/signin.component";
import { NewPasswordComponent } from "./modules/user/new-password/new-password.component";
import { ForgotPasswordComponent } from "./modules/user/forgot-password/forgot-password.component";
import { ProfileComponent } from "./compoments/profile/profile.component";

import { OrderComponent } from "./compoments/order/order.component";
import { OrderDetailsComponent } from "./compoments/order/order-details/order-details.component";
import { OrderPrintComponent } from "./compoments/order/order-print/order-print.component";

import { HomeAdminComponent } from "./modules/admin/home/home.component";
import { WelcomeComponent } from "./modules/client/welcome/welcome.component";
import { SchoolComponent } from "./modules/admin/school/school.component";
import { SchoolDetailsComponent } from "./modules/admin/school/school-details/school-details.component";
import { SchoolFormComponent } from "./modules/admin/school/school-form/school-form.component";
import { ProviderComponent } from "./modules/admin/provider/provider.component";
import { ProviderDetailsComponent } from "./modules/admin/provider/provider-details/provider-details.component";
import { ProviderFormComponent } from "./modules/admin/provider/provider-form/provider-form.component";
import { ProductComponent } from "./modules/admin/product/product.component";
import { ProductDetailsComponent } from "./modules/admin/product/product-details/product-details.component";
import { ProductFormComponent } from "./modules/admin/product/product-form/product-form.component";
import { UserManagementComponent } from "./compoments/user-management/user-management.component";
import { ClientHomeComponent } from "./modules/client/home/home.component";
import { ProductListComponent } from "./modules/client/product/product.component";
import { EmptyListComponent } from "./shared/ui/empty-list/empty-list.component";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { BackButtonComponent } from "./shared/ui/back-button/back-button.component";
import { OrderEdit } from "./shared/ui/order-edit/order-edit.component";
import { CartAlertComponent } from "./modules/client/home/cart-alert/cart-alert.component";
import { OrderBagComponent } from "./compoments/order/order-bag/order-bag.component";
import { SignupComponent } from "./modules/user/signup/signup.component";
import { MessageCardComponent } from "./shared/ui/message-card/message-card.component";
import { DirectivesModule } from "./shared/directives/directives.module";
import { AddBtnComponent } from "./shared/ui/add-btn/add-btn.component";
import { TermsComponent } from "./shared/ui/terms/terms.component";
import { SearchInputComponent } from "./shared/ui/search-input/search-input.component";
import { OrderConfirmationComponent } from "./compoments/order/order-confirmation/order-confirmation.component";
import { OrderNewAddressComponent } from "./compoments/order/order-new-address/order-new-address.component";
import { CompanyInterceptor } from "./shared/auth/company.interceptor";
import { UnitSelectionComponent } from "./shared/ui/unit-selection/unit-selection.component";
import { SchoolReportModalComponent } from "./modules/admin/school/school-form/school-report-modal/school-report-modal.component";
import { SchoolReportComponent } from "./modules/admin/school/school-report/school-report.component";
import { ReportConfigModal } from "./modules/admin/school/school-report/config-modal/config-modal.component";

@NgModule({
  declarations: [
    OrderEdit,
    AppComponent,
    NavbarComponent,
    PasswordDirective,
    ConfirmWindowComponent,
    StepperComponent,
    ActionButtonComponent,
    SearchBoxComponent,
    BoxItemComponent,
    DashboardComponent,
    SchoolListComponent,
    OrderListComponent,
    OrderDetailsComponent,
    OrderPrintComponent,
    UserComponent,
    SigninComponent,
    NewPasswordComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    HomeAdminComponent,
    WelcomeComponent,
    OrderComponent,
    SchoolComponent,
    SchoolDetailsComponent,
    SchoolFormComponent,
    ProviderComponent,
    ProviderDetailsComponent,
    ProviderFormComponent,
    ProductComponent,
    ProductDetailsComponent,
    ProductFormComponent,
    ClientHomeComponent,
    ProductListComponent,
    UserManagementComponent,
    EmptyListComponent,
    BackButtonComponent,
    CartAlertComponent,
    OrderBagComponent,
    SignupComponent,
    MessageCardComponent,
    AddBtnComponent,
    TermsComponent,
    SearchInputComponent,
    OrderConfirmationComponent,
    OrderNewAddressComponent,
    UnitSelectionComponent,
    SchoolReportModalComponent,
    SchoolReportComponent,
    ReportConfigModal
  ],
  imports: [
    BrowserModule,
    InfiniteScrollModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TextMaskModule,
    NgSelectModule,
    NgxCurrencyModule,
    ToastrModule.forRoot(),
    PaginationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgxMaskModule.forRoot(),
    BsDropdownModule.forRoot(),
    DirectivesModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    BsModalRef,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CompanyInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [ProductListComponent],
})
export class AppModule {}
