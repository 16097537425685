import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Query } from 'src/app/shared/util/query';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmWindowComponent } from 'src/app/shared/ui/confirm-window/confirm-window.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  users: any = [];
  email: string;
  me: any;
  managers = [];
  company: any = {};
  companyId: any;
  query = new Query();
  modalRef: BsModalRef;

  constructor(private service: UserService,
              private companyService: CompanyService,
              private modalService: BsModalService,
              private router: Router) { }

  ngOnInit() {
    this.getMe();
    this.query.page = 1;
    this.query.limit = 10;
    const companyObserver = this.companyService.watchCompany();
    const userObserver = this.service.me();
    combineLatest([companyObserver, userObserver]).pipe(
      map(results => ({ company: results[0], user:  results[1] }) )
    ).subscribe(({ company, user }) => {
      if (user.manager && !user.admin) {
        this.company = company;
        this.managers = [...company.managers];
      }
      this.me = user;
      if (user) this.list();
    });
  }

  list() {
    if (this.me.admin) {
      return this.service.list(this.query).subscribe((users: any) => {
        this.users = users;
        this.users.data = users.data.filter( user => user._id !== this.me._id);
      });
    } else {
      if (this.query.search.filter && this.query.search.filter) {
        this.query.search.filter = this.query.search.filter.toLowerCase();
        this.company.managers = this.managers.filter( 
          m => m.name.toLowerCase().search(this.query.search.filter) !== -1 ||
          m.email.toLowerCase().search(this.query.search.filter) !== -1
        );
      } else this.company.managers = this.managers;
    }
  }

  getMe() {
    return this.service.me().subscribe((me: any) => {
      this.me = me;
      this.list();
    });
  }

  invite() {
    return this.service.invite({ email: this.email, admin: this.me?.admin })
      .subscribe(() => { this.list(); });
  }

  inviteManager() {
    return this.companyService.addManager(this.company._id, { email: this.email })
      .subscribe((_) => {
        this.updateCompany(this.company._id);
      });
  }

  getCompany() {
    return this.companyService.getCompany().subscribe((company: any) => {
      this.company = company;
    });
  }

  updateCompany(id) {
    return this.companyService.get(id)
    .subscribe((company) => {
      this.company = company;
      this.companyService.set(company);
    });
  }
  
  deleteUser() {
    return (id) => {
      if (this.me.admin) 
        this.service.delete(id).subscribe(() => {
          this.list();
        });
      else 
        this.companyService.removeManager(this.company._id, id).subscribe(() => {
          this.updateCompany(this.company._id);
        });
    };
  }

  confirm(e, id) {
    e.stopPropagation();
    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        id,
        onYes: this.deleteUser()
      }
    });
  }

  restore(id) {
    this.service.restore(id).subscribe(() => {
      this.list();
    });
  }
}
