import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

import { Router, ActivatedRoute } from "@angular/router";

import PaymentMethods from "src/app/shared/util/paymentMethods";

@Component({
  selector: "config-modal",
  templateUrl: "./config-modal.component.html",
  styleUrls: ["./config-modal.component.scss"],
})
export class ReportConfigModal implements OnInit {
  expiration;
  bonus;
  method;

  generalComments;
  clientComments;

  paymentMethods = PaymentMethods;

  error;

  constructor(
    public modalRef: BsModalRef,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  set() {
    localStorage.setItem('generalComments', this.generalComments)
    localStorage.setItem('expiration', this.expiration)
    localStorage.setItem('method', this.method)
    
    const urlTree = this.router.createUrlTree([], {
      queryParams: { clientComments: this.clientComments },
      queryParamsHandling: "merge",
      preserveFragment: true });
    
    this.router.navigateByUrl(urlTree);

    setTimeout(() => {
      location.reload()
    }, 300)
  }
}
