export class Query {
  page: any = false;
  limit: any = false;
  search: any = {};

  constructor(useDefault = true) {
    if (useDefault) {
      this.page = 1;
      this.limit = 12;
    }
  }

  get() {
    let q = [];

    if(typeof this.limit === 'number') { q.push('perPage=' + this.limit); }
    if(this.page) { q.push('page=' + this.page); }

    for(let s in this.search) {
      if (this.search[s] instanceof Array) {
          this.search[s].map(e => {
            if (e?.product) q.push(s + '=' + e.product);
            else q.push(s + '=' + e);
          });
      } else {
        q.push(s + '=' + this.search[s]);
      }
		}

    if(q.length == 0) return '';

    return '?' + q.join('&');
  }
}
