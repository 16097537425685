<div class="modal-body">
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <form autocomplete="off" #reportForm="ngForm">
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <h2>Imprimir relatório</h2>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <label
          [class.error]="
            startDateInput.invalid &&
            (startDateInput.dirty || startDateInput.touched)
          "
          >Data inicial</label
        >
        <input
          class="form-control text-right"
          name="date"
          bsDatepicker
          [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true, dateInputFormat: 'DD/MM/YYYY' }"
          [(ngModel)]="startDate"
          #startDateInput="ngModel"
          [class.error]="
            startDateInput.invalid &&
            (startDateInput.dirty || startDateInput.touched)
          "
          required
        />
        <div
          *ngIf="
            startDateInput.invalid &&
            (startDateInput.dirty || startDateInput.touched)
          "
          class="error"
        >
          <div *ngIf="startDateInput.errors.required">Selecione uma data</div>
        </div>
      </div>

      <div class="col-12 col-md-6 mb-3">
        <label
          [class.error]="
            endDateInput.invalid && (endDateInput.dirty || endDateInput.touched)
          "
        >
          Data Final
        </label>
        <input
          class="form-control text-right"
          name="endDate"
          bsDatepicker
          [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true, dateInputFormat: 'DD/MM/YYYY' }"
          [(ngModel)]="endDate"
          #endDateInput="ngModel"
          [class.error]="
            endDateInput.invalid && (endDateInput.dirty || endDateInput.touched)
          "
          required
        />
        <div
          *ngIf="
            endDateInput.invalid && (endDateInput.dirty || endDateInput.touched)
          "
          class="error"
        >
          <div *ngIf="endDateInput.errors.required">Selecione uma data</div>
        </div>
      </div>

      <div class="col-12 mb-3">
        <label
          [class.error]="
            expirationInput.invalid &&
            (expirationInput.dirty || expirationInput.touched)
          "
        >
          Data de vencimento
        </label>
        <input
          class="form-control text-right"
          name="expiration"
          bsDatepicker
          [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true, dateInputFormat: 'DD/MM/YYYY' }"
          [(ngModel)]="expiration"
          [minDate]="today"
          #expirationInput="ngModel"
          [class.error]="
            expirationInput.invalid &&
            (expirationInput.dirty || expirationInput.touched)
          "
          required
        />
        <div
          *ngIf="
            expirationInput.invalid &&
            (expirationInput.dirty || expirationInput.touched)
          "
          class="error"
        >
          <div *ngIf="expirationInput.errors.required">Selecione uma data</div>
        </div>
      </div>

      <div class="col-12 mb-3">
        <label> Bônus </label>
        <input
          type="text"
          class="form-control text-right"
          name="bonus"
          mask="00000000"
          [(ngModel)]="bonus"
          #bonusInput="ngModel"
          [validation]="false"
        />
      </div>

      <div class="col-12 mb-3">
        <label
          [class.error]="
            methodInput.invalid && (methodInput.dirty || methodInput.touched)
          "
          >Forma de pagamento</label
        >
        <ng-select
          class="w-100"
          [clearable]="false"
          name="method"
          [(ngModel)]="method"
          #methodInput="ngModel"
          required="true"
          [class.error]="
            methodInput.invalid && (methodInput.dirty || methodInput.touched)
          "
        >
          <ng-container *ngFor="let method of paymentMethods">
            <ng-option [value]="method.key">
              <span class="weight-medium text-medium">{{ method.label }}</span>
            </ng-option>
          </ng-container>
        </ng-select>
        <div
          *ngIf="
            methodInput.invalid && (methodInput.dirty || methodInput.touched)
          "
          class="error"
        >
          <div *ngIf="methodInput.errors.required">Selecione um item</div>
        </div>
      </div>
    </div>
  </form>
  <button
    class="mt-2 btn button secondary text-white"
    (click)="
      reportForm.form.valid ? openReport() : reportForm.form.markAllAsTouched()
    "
  >
    Imprimir
  </button>
</div>
