import { LoadingService } from 'src/app/shared/services/loading.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, BehaviorSubject, EMPTY, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { Query } from '../util/query';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  API = environment.url;
  TOKEN = localStorage.getItem(environment.token);
  order: any = JSON.parse(localStorage.getItem('cart')) || null;
  observer = new BehaviorSubject(this.order);

  constructor(private http: HttpClient,
              private loadingService: LoadingService) {
    if (!this.order) {
      this.order = {
        provider: '',
        total: '',
        products: []
      };
      this.observer.next(this.order);
    }
  }

  list(query = new Query(), loading = true) {
    if (loading) this.loadingService.show();
    return this.http.get(`${this.API}/order${query.get()}`).pipe(
      tap(() => this.loadingService.hide()),
      catchError((error) => {
        this.loadingService.hide();
        return throwError(error);
      })
    );
  }

  listCompanyOrders(id) {
    return this.http.get(`${this.API}/order?company=`);
  }

  get(id) {
    return this.http.get(`${this.API}/order/${id}`);
  }

  getLatest(query: Query) {
    return this.http.get(`${this.API}/order/latest${query.get()}`);
  }

  notify(id) {
    return this.http.patch(`${this.API}/order/${id}/notify`, {});
  }

  save(order) {
    if(order.date) order.date = new Date(order.date)
    if(order.nfeDate) order.nfeDate = new Date(order.nfeDate)

    if (order._id) return this.http.put(`${this.API}/order/${order._id}`, order);
    return this.http.post(`${this.API}/order`, order);
  }

  delete(id) {
    return this.http.delete(`${this.API}/order/${id}`)
  }

  changeStatus(id, data) {
    return this.http.patch(`${this.API}/order/${id}/status`, data);
  }

  setProduct(product) {
    if(!product.amount) product.amount = 0

    const found = this.order.products.find( p => p.product === product._id)

    if (!found) {
      const { _id, amount, name, mainImage, tags, weight } = product;
      this.order.products.push({ product: _id, ...product });
    } else {
      found.amount = product.amount;
      found.selectedUnit = product.selectedUnit;
      
      if(product.amount <= 0) {
        const index = this.order.products.findIndex( p => p.product === product._id);
        
        this.order.products.splice(index, 1);
      }
    }

    this.saveCart();
  }

  getCart() {
    if (this.order) return of(this.order);
    else return of(null);
  }

  watchCart() { return this.observer; }

  saveCart() {
    this.observer.next(this.order);

    localStorage.setItem('cart', JSON.stringify(this.order));
  }

  setClient(client) { this.order.client = client; }

  setCart(order) { this.order = order; }

  setCategory(categoryId, subcategory) {
    if(categoryId != this.order.category || subcategory != this.order.subcategory) this.clearCart()

    this.order.category = categoryId;
    this.order.subcategory = subcategory;
    this.saveCart();
  }

  selectCity(cities = [], address) {
    const normalize = (string = '') => {
      return string.toLowerCase()
        .replace(/[àãáâ]/g, 'a')
        .replace(/[èêé]/g, 'e')
        .replace(/[ìîí]/g, 'i')
        .replace(/[òõóô]/g, 'o')
        .replace(/[ùûú]/g, 'u')
        .replace(/[ç]/g, 'c')
    }

    return cities?.filter((c) => 
        normalize(c.name) == normalize(address?.city_name)
        && (
            !c?.neighborhood 
          || c?.neighborhood?.length == 0
          || c?.neighborhood?.filter((n) => normalize(n) == normalize(address.neighbourhood)).length > 0
        )
      ).sort((a,b) => (
        (a.min == b.min ? a.term - b.term : parseInt(a.min) - parseInt(b.min)))
      )[0]
  }

  clearCart() {
    localStorage.removeItem('cart')
    this.order = {
      provider: '',
      products: []
    };
    this.observer.next(this.order);
  }
}
