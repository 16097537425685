import { LoadingService } from './loading.service';
import { Query } from 'src/app/shared/util/query';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { EMPTY, of, throwError, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  API = environment.url;
  TOKEN = localStorage.getItem(environment.token);

  constructor(private http: HttpClient,
              private loadingService: LoadingService) { }

  list(q: Query = new Query(), showLoading = true) {
    if (showLoading) this.loadingService.show();

    return this.http.get(`${this.API}/product${q.get()}`).pipe(
      tap(() => { if (showLoading) this.loadingService.hide(); }),
      catchError(() => {
        if (showLoading) this.loadingService.hide();
        return EMPTY;
      })
    );
  }

  listByCategory(category) {
    return this.http.get(`${this.API}/product?category=${category}`);
  }

  listSimilar(q = new Query(), id) {
    return this.http.get(`${this.API}/product/${id}/similar${q.get()}`);
  }

  get(id) {
    return this.http.get(`${this.API}/product/${id}`);
  }

  save(product, loading = true) {
    let observable;
    if (loading) this.loadingService.show();
    
    if (product._id) observable = this.http.put(`${this.API}/product/${product._id}`, product);
    else observable = this.http.post(`${this.API}/product`, product);

    return observable.pipe(
      tap(() => this.loadingService.hide()),
      catchError( err => {
        this.loadingService.hide();
        return throwError(err);
      })
    );
  }

  delete(id) {
    this.loadingService.show();
    return this.http.delete(`${this.API}/product/${id}`)
      .pipe(
        tap(() => this.loadingService.hide()),
        catchError( (error) => {
          this.loadingService.hide();
          return of(error);
        })
      );
  }

  upload(id, file, index?: string) {
    const fd = new FormData();
    fd.append('image', file);
    return this.http.post(`${this.API}/product/${id}/upload/${index || ''}`, fd);
  }
}
