<div class="my-2 p-2 rounded bg-light d-flex align-items-center" *ngIf="success">
  <div [inlineSVG]="'/assets/icons/confirm.svg'" class="d-flex mr-2"></div>
  <small class="weight-medium">
    <ng-content></ng-content>
  </small>
</div>

<div class="my-2 p-2 rounded bg-light d-flex align-items-center" *ngIf="error">
  <div [inlineSVG]="'/assets/icons/cancel.svg'" class="d-flex mr-2"></div>
  <small class="weight-medium error">
    <ng-content></ng-content>
  </small>
</div>