import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  user: any = {};
  error: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router) {}

  ngOnInit(): void {}

  signin(user) {
    return this.userService.login(user).subscribe(
      (user) => {
        if (user.manager) this.router.navigate(["/home"]);
        else this.router.navigate(["/order"]);
      },
      (err) => {
        this.error = true;
      }
    );
  }

  signup() {
    window.open('https://wa.me/5551999999016?text=Olá, gostaria de me cadastrar na Plataforma IZZA')
  }
}
