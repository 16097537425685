import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderComplete {

  type: any = ''
  constructor(public modalRef: BsModalRef) {}

  close(): void {
    this.modalRef.hide();
  }
}
