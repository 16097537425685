import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NativeElementInjectorDirective } from "./addNativeEl.directive";
import { ContenteditableModel } from "./contentEditable.directive";

@NgModule({
  declarations: [NativeElementInjectorDirective, ContenteditableModel],
  exports: [NativeElementInjectorDirective, ContenteditableModel],
  imports: [CommonModule],
})
export class DirectivesModule {}
