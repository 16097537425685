import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

import { Router } from "@angular/router";

import PaymentMethods from "src/app/shared/util/paymentMethods";

@Component({
  selector: "app-school-report-modal",
  templateUrl: "./school-report-modal.component.html",
  styleUrls: ["./school-report-modal.component.scss"],
})
export class SchoolReportModalComponent implements OnInit {
  companyId: string;

  today = new Date();

  startDate: any;
  endDate;
  expiration;
  bonus: any = "";
  method: any = "";

  paymentMethods = PaymentMethods;

  error;

  constructor(public modalRef: BsModalRef, public router: Router) {}

  ngOnInit(): void {}

  openReport() {
    window.open(
      `/#/client/${
        this.companyId
      }/report?date_start=${this.startDate.toISOString()}&date_end=${this.endDate.toISOString()}&expiration=${this.expiration.toISOString()}${
        this.bonus ? `&bonus=${this.bonus}` : ""
      }${this.method ? `&method=${this.method}` : ""}`
    );
  }
}
