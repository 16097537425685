import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ConfirmWindowComponent } from 'src/app/shared/ui/confirm-window/confirm-window.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderService } from 'src/app/shared/services/provider.service';


@Component({
  selector: 'app-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss']
})
export class ProviderDetailsComponent implements OnInit {

  provider: any = {}
  modalRef: BsModalRef;
  routeId: any;

  constructor(private modalService: BsModalService,
              private route: ActivatedRoute,
              private router: Router,
              private service: ProviderService) { }

  ngOnInit() {
    this.routeId = this.route.snapshot.params.id;
    if (this.routeId) this.get();
  }

  get() {
    this.service.get(this.routeId)
    .subscribe((provider) => {
      this.provider = provider;
    });
  }

  openModal(id) {
    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        title: 'provider',
        data: id
      }
    });
  }
}