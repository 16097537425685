
<div class="row">
  <div class="col-12 col-md-4" *ngFor="let order of orders.data">
    <order-list [hasDelete]="false" [order]="order"></order-list>
  </div>  
</div>

<div class="row" *ngIf="companies?.length > 1">
  <div class="col-12 my-2" >
    <span class="text-medium weight-medium" *ngIf="!company?._id || isSelecting">Selecione para qual empresa deseja comprar.</span>
    &nbsp;
  </div>

  <div class="col-12 col-lg-6" *ngIf="!isSelecting">
    <div class="item-wrapper pointer selected bg-light justify-content-between d-flex align-items-center m-0 p-3 pr-0 mb-2" (click)="setCompany()">
      <div class="d-flex align-items-center mr-2">
        <img [src]="company?.logo || '/assets/icons/avatar.svg'" class="img-lg rounded-circle mr-2">
        <div class="text-left">
          <h5 class="m-0">{{ company.name }}</h5>
        </div>
      </div>
      <div *ngIf="!isSelecting && !user.admin">Alterar</div>
    </div>
  </div>

  <ng-container *ngFor="let client of companies">
    <div class="col-12 col-lg-6" *ngIf="isSelecting">
      <div class="item-wrapper pointer bg-light justify-content-between d-flex align-items-center m-0 p-3 pr-0 mb-2" [class.selected]="client?._id == company?._id" (click)="setCompany(client)">
        <div class="d-flex align-items-center mr-2">
          <img [src]="client?.logo || '/assets/icons/avatar.svg'" class="img-lg rounded-circle mr-2">
          <div class="text-left">
            <h5 class="m-0">{{ client.name }}</h5>
          </div>
        </div>
        <div *ngIf="!isSelecting && !user.admin">Alterar</div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="company?._id && !company?.phone">
  <div class="alert alert-warning" routerLink="/client/{{company._id}}/edit">
    <b>ATENÇÃO: </b>
    Complete seu cadastro para efetuar suas compras.
  </div>
</div>

<div class="row mb-3" *ngIf="company?._id && !isSelecting">
  <div class="col-12 my-2" *ngFor="let category of categories.data">
    <div class="d-flex flex-row p-2 text-center">
      <p class="mb-1 weight-medium text-medium" style="font-size: x-large;">{{ category.name }}</p>
    </div>

    <div class="mt-2 d-flex flex-wrap">
      <span *ngFor="let sub of category.subcategories" class="wrapper m-2 py-2 px-4 flex-nowrap pointer" (click)="details(category, sub)">{{sub}}</span>
    </div>
  </div>
</div>