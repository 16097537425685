import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm, ControlContainer, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { combineLatest, forkJoin, of } from "rxjs";

import { Query } from "src/app/shared/util/query";

import { SchoolReportModalComponent } from "./school-report-modal/school-report-modal.component";

import { CompanyService } from "src/app/shared/services/company.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { FormStepper } from "src/app/shared/classes/FormStepper";
import { UserService } from "src/app/shared/services/user.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { subDays } from "date-fns";
import { ProviderService } from "src/app/shared/services/provider.service";
@Component({
  selector: "app-school-form",
  templateUrl: "./school-form.component.html",
  styleUrls: ["./school-form.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchoolFormComponent implements OnInit {
  routeId;
  selectedFile: any;
  fileImage: any;
  me;

  stepper: any;
  isPhysical = true;
  inviteByEmail = true;
  companies = [];
  providers = [];
  company: any = {
    type: "pf",
    address: {},
    deliveryAddress: {},
    managers: [{}],
    buyer: {},
    nutritionist: {},
    director: {},
  };
  oldCompany: any;
  delivery: any = {};

  cepTimeout;
  @ViewChild("step2", { static: false }) secondStep;
  @ViewChild("schoolForm") form: NgForm;

  constructor(
    private service: CompanyService,
    private userService: UserService,
    private cepService: SharedService,
    private providerServices: ProviderService,
    private location: Location,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.stepper = new FormStepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: false,
    });

    this.stepper.titles = document.querySelectorAll(".bs-stepper-desc");
    this.stepper.title = this.stepper.titles[this.stepper.step].innerText;

    let q = new Query();
    q.search["type"] = "pj";
    q.limit = 10000;
    this.service.list(q).subscribe((c: any) => (this.companies = c.data));
    this.providerServices.list(q).subscribe((p: any) => (this.providers = p.data));

    this.routeId = this.route.snapshot.params.id;
    if (this.routeId) this.get(this.routeId);

    this.userService.me().subscribe((user) => {
      this.me = user;
    });
  }

  validate() {
    // Volta até o passo do input
    for (const key in this.form.form.controls) {
      const formControl: any = this.form.form.controls[key];
      if (formControl.invalid) {
        formControl.markAsTouched();
        return this.stepper.scrollTo(formControl.nativeElement);
      }
    }
    this.save();
  }

  openReportModal() {
    this.modalService.show(SchoolReportModalComponent, {
      initialState: {
        companyId: this.company._id,
        endDate: new Date(),
        startDate: subDays(new Date(), 15)
      }
    });
  }

  save() {
    const { managers, ...rest } = this.company;
    const redirect = this.me?.admin ? "/client" : "/home";

    return this.service.save(rest).subscribe(
      (data: any) => {
        this.company._id = data._id;

        this.service.set(data);

        let observer;

        if (this.selectedFile && this.company.managers.length) {
          observer = combineLatest([
            this.doUpload(data._id),
            this.addManagers(),
          ]);
        } else if (this.selectedFile && !this.company.managers.length) {
          observer = this.doUpload(data._id);
        } else if (!this.selectedFile && this.company.managers.length) {
          observer = this.addManagers();
        }

        if (observer)
          observer.subscribe(() => this.router.navigate([redirect]));
        else this.router.navigate([redirect]);
      },
      (err) => {
        if (err.status === 409) {
          for (const key in err.error) {
            this.form.form.controls[key].setErrors({ duplicate: true });
            this.stepper.scrollTo(
              (this.form.form.controls[key] as any).nativeElement
            );
          }
        }
      }
    );
  }

  doUpload(id) {
    return this.service.upload(id, this.selectedFile);
  }

  addManagers() {
    let observables = this.company.managers
      .filter((manager) => !manager._id && manager.email)
      .map((manager) => this.service.addManager(this.company._id, manager));

    return observables.length > 1 ? forkJoin(observables) : observables[0];
  }

  removeManager(index, manager) {
    if (manager._id)
      return this.service
        .removeManager(this.company._id, manager._id)
        .subscribe(() => {
          this.company.managers.splice(index, 1);
        });
    else this.company.managers.splice(index, 1);
  }

  get(id) {
    this.service.get(id).subscribe((company: any) => {
      this.company = { ...this.company, ...company };

      this.isPhysical = this.company.type === "pf";
      this.repeat = !(
        this.company.deliveryAddress && this.company.deliveryAddress.street
      );

      this.oldCompany = Object.assign({}, company);
      if (this.company.logo) this.fileImage = this.company.logo;
    });
  }

  reader(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      this.fileImage = e.target.result;
    };
  }

  onFileSelect(event, i) {
    this.selectedFile = event.target.files[0];
    this.reader(event.target.files[0]);
  }

  emptyFileSelect() {
    this.selectedFile = null;
    this.fileImage = undefined;
  }

  repeat = false;
  repeatAddress(event) {
    this.repeat = event.target.checked;
    this.company.deliveryAddress = {};
  }

  getCep(e, updatedObj) {
    const cep = String(e.target.value).replace(/[_-]/g, "");
    if (cep.length === 0) {
      return;
    }

    this.cepService.getCep(cep).subscribe((res: any) => {
      if (!res.erro) {
        updatedObj.state = res.uf;
        updatedObj.neighbourhood = res.bairro;
        updatedObj.city = res.localidade;
        updatedObj.street = res.logradouro;
        updatedObj.complement = res.complemento;
      }
    });
  }
}
