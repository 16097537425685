<div class="row">
  <div class="col-12 text-center">
    <img class="logo mw-100 rounded mb-2" [src]="provider?.logo || '/assets/icons/avatar.svg'" alt="brand logo">
  </div>
  <div class="col-md-6">
    <div class="text-left item-wrapper border-bottom p-2 p-2">
      <p class="mb-1">Nome</p>
      <h2 class="mb-3">{{ provider.name }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Razão social</p>
      <h2 class="mb-3">{{ provider.companyName }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Telefone</p>
      <h2>{{ provider.phone | mask:'(00) 0000-0000' }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Site</p>
      <h2>{{ provider.url  }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">CEP</p>
      <h2>{{ provider.address?.cep }}</h2>
    </div>
  </div>
  <div class="col-md-4">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Cidade</p>
      <h2>{{ provider.address?.city }}</h2>
    </div>
  </div>
  <div class="col-md-2">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Estado</p>
      <h2>{{ provider.address?.state }}</h2>
    </div>
  </div>
  <div class="col-md-9">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Rua</p>
      <h2>{{ provider.address?.street }}</h2>
    </div>
  </div>
  <div class="col-md-3">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Número</p>
      <h2>{{ provider.address?.number }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Bairro</p>
      <h2>{{ provider.address?.neighbourhood }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="text-left item-wrapper border-bottom p-2">
      <p class="mb-1">Referência</p>
      <h2>{{ provider.address?.reference || 'Sem referência' }}</h2>
    </div>
  </div>
  <div class="col-12">
    <div class="col-md-12 text-center actions mt-4">
      <img src="/assets/icons/edit-lg.svg" class="mr-4" routerLink="edit">
      <img src="/assets/icons/cancel-lg.svg" (click)="openModal(provider._id)">
    </div>
  </div>
</div>