<div class="row">
  <div class="col-12 text-center">
    <img class="rounded mb-2 product-image" [src]="product.mainImage" alt="product-image">
  </div>

  <div class="col-md-6">
    <div class="item-wrapper border-bottom text-left">
      <p class="mb-1">Nome</p>
      <h2 class="mb-3">{{ product.name }}</h2>
    </div>
  </div>
  <div class="col-6">
    <div class="item-wrapper border-bottom text-left">
      <p class="mb-1">Categoria</p>
      <h2 class="mb-3">{{ product?.category?.name || '-' }}</h2>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div class="item-wrapper border-bottom text-left">
      <p class="mb-1">Marca</p>
      <h2 class="mb-3">{{ product.brand || '-' }}</h2>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div class="item-wrapper border-bottom text-left">
      <p class="mb-1">Modelo</p>
      <h2 class="mb-3">{{ product.model || '-' }}</h2>
    </div>
  </div>
  <div class="col-6 col-md-4">
    <div class="item-wrapper border-bottom text-left">
      <p class="mb-1">Peso</p>
      <h2 class="mb-3">{{ product.weight || '-' }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="item-wrapper border-bottom text-left">
      <p class="mb-1">Descrição</p>
      <h2 class="mb-3">{{ product.description || '-' }}</h2>
    </div>
  </div>
  <div class="col-md-6">
    <div class="item-wrapper border-bottom text-left">
      <p class="mb-1">Informações Técnicas</p>
      <h2 class="mb-3">{{ product.observation0 || '-' }}</h2>
    </div>
  </div>

  <div class="col-12">
    <div class="col-md-12 text-center actions mt-4">
      <img src="/assets/icons/edit-lg.svg" class="mr-4" routerLink="./edit">
      <img src="/assets/icons/cancel-lg.svg" (click)="openModal(product._id)">
    </div>
  </div>
</div>