import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { utils as XLSXUtils, writeFile } from 'xlsx';
import { WorkBook, WorkSheet } from 'xlsx/types';

import { environment } from "../../../environments/environment";

import { Query } from "src/app/shared/util/query";
import { LoadingService } from "./loading.service";

export interface IExportAsExcelProps {
  readonly data: any[];
  readonly fileName: string;
  readonly sheetName?: string;
  readonly header?: string[];
  readonly table?: HTMLElement;
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  API = environment.url;
  fileExtension = '.xlsx';

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) {}

  getAndExport(fileName, endpoint, filter: Query = new Query()) {
    this.loadingService.show()
    this.http.get(`${this.API}/${endpoint}${filter.get()}`).subscribe((data: any[]) => {
      this.export({data,fileName})
      this.loadingService.hide()
    });
  }

  public export({
    data,
    fileName,
    sheetName = 'Data',
    header = [],
    table
  }: IExportAsExcelProps): void {
    let wb: WorkBook;

    if (table) {
      wb = XLSXUtils.table_to_book(table);
    } else {
      const ws: WorkSheet = XLSXUtils.json_to_sheet(data, { header });
      wb = XLSXUtils.book_new();
      XLSXUtils.book_append_sheet(wb, ws, sheetName);
    }

    writeFile(wb, `${fileName}${this.fileExtension}`);
  }
}