<div class="row">
  <div class="col-12">
    Pedido realizado pela plataforma www.izzaweb.com.br, através do acesso {{order?.manager?.email}}, na data de {{order?.createdAt | date:'dd/MM/yyyy HH:mm'}}, de código: {{ order?._id }}
  </div>
  <div class="col-12 d-flex align-items-center justify-content-between">
    <div>
      <h1 class="mb-0">Pedido Nº {{ order?.number || '-'}}</h1>
      {{ order?.provider?.name }}
    </div>
    <img src="assets/images/logo-izza-icon.png" class="float-right" />
  </div>
</div>

<div class="canceled" *ngIf="order.status == 'f_canceled'">
  <img src="assets/images/cancelado.png" />
</div>

<div class="row mt-2 pb-3">
  <div class="col-12 d-flex">
    <h2 class="mb-0">Cliente</h2>
  </div>

  <div class="col-12 col-sm-6 my-2 border-right">
    <h5>{{ order?.client?.name }}</h5>
    <h6 *ngIf="order?.client?.type === 'pj'">{{ order?.client?.companyName }}</h6>
    <h6>
      <span *ngIf="order?.client?.type === 'pj'">CNPJ: {{ order?.client?.cnpj | mask:'00.000.000/0000-00'}}</span>
      <span *ngIf="order?.client?.type !== 'pj'">CPF: {{ order?.client?.cpf | mask:'000.000.000-00'}}</span>
    </h6>
    <h6 *ngIf="order?.client?.email">E-mail: {{ order?.client?.email }}</h6>
    <h6 *ngIf="order?.client?.phone">Telefone: {{ order?.client?.phone | mask:'(00) 000000000'  }}</h6>
    <h6 *ngIf="order?.client?.reference?.name">Escola Amiga: {{ order?.client?.reference?.name }}</h6>
  </div>

  <div class="col-12 my-2  {{ sameAddress ? 'col-sm-6':'col-sm-3' }}">
    <h6><strong>Endereço de cadastro {{ sameAddress ? 'e entrega':'' }}</strong></h6>
    <h6>
      {{ order?.client?.address?.street }}, {{ order?.client?.address?.number }}
      <span *ngIf="order?.client?.address?.complement"> - {{order?.client?.address?.complement}}</span>
    </h6>
    <h6 *ngIf="order?.client?.address?.neighbourhood">Bairro: {{ order?.client?.address?.neighbourhood }}</h6>
    <h6>{{ order?.client?.address?.city }}, {{ order?.client?.address?.state }}</h6>
    <h6>CEP: {{ order?.client?.address?.cep }}</h6>
    <h6 *ngIf="order?.client?.address?.reference">Ponto de Referência: {{ order?.client?.address?.reference }}</h6>
  </div>

  <div *ngIf="!sameAddress" class="col-12 col-sm-3 my-2 border-left">
    <h6><strong>Endereço de entrega</strong></h6>
    <h6>
      {{ order?.address?.street }}, {{ order?.address?.number }}
      <span *ngIf="order?.address?.complement"> - {{order?.address?.complement}}</span>
    </h6>
    <h6 *ngIf="order?.address?.neighbourhood">Bairro: {{ order?.address?.neighbourhood }}</h6>
    <h6>{{ order?.address?.city }}, {{ order?.address?.state }}</h6>
    <h6>CEP: {{ order?.address?.cep }}</h6>
    <h6 *ngIf="order?.address?.reference">Ponto de Referência: {{ order?.address?.reference }}</h6>
  </div>
</div>

<div class="row pb-3">
  <div class="col-12 d-flex align-items-center justify-content-between">
    <h2 class="mb-0">Pedido</h2>
  </div>
  <div class="col-12 mb-2 text-center" *ngIf="confirm && order.status == 'w_waiting'">
    Os itens do pedido serão exibidos após a confirmação do pedido.
  </div>
  <div class="col-12 p-0 mt-2" *ngIf="!confirm || order.status != 'w_waiting'">
    <div class="row m-0">
      <div class="col-2 text-center">Quantidade</div>
      <div class="col-6">Produto / Marca</div>
      <div class="col-1 text-right">Preço Kg</div>
      <div class="col-1 text-right">Preço Un</div>
      <div class="col-2 text-right">Total</div>
    </div>
    
    <ng-container *ngFor="let offer of order?.offers">
      <div class="m-0 row border-bottom py-2" *ngIf="offer.amount">
        <h6 class="col-2 text-center">
          {{ offer.amount }} {{ offer.selectedUnit?.label || offer.unit?.label || 'un' }}
        </h6>
        <h6 class="col-6 text-left">
          {{ offer.offer?.product.name }}
          <span *ngIf="offer.offer?.product.brand">/ {{ offer.offer?.product.brand }}</span>
        </h6>
        <h6 class="col-1 text-right">
          {{ getUnitaryPrice(offer, 'kg') }}
        </h6>
        <h6 class="col-1 text-right">
          {{ getUnitaryPrice(offer, 'un') }}
        </h6>
        <h6 class="col-2 text-right">
          {{ getPrice(offer) | currency:'BRL' }}
        </h6>
      </div>
    </ng-container>
  </div>
</div>

<div class="row mb-2" *ngIf="order.status != 'w_waiting'">
  <div class="col-12 text-right">
    Total: {{ getTotal(order) | currency:'BRL' }}
  </div>
  <div class="col-12 text-right">
    ESTE VALOR PODERÁ SOFRER VARIAÇÃO CORRESPONDENTE A PESAGEM FINAL DAS UNIDADES PEDIDAS
  </div>
</div>

<div class="row mt-0">
  <div class="col-12 col-md-6 mt-2">
    <div class="item-wrapper m-0 d-flex justify-content-between align-items-center">
      <h6>Quantidade</h6>
      <h6>{{ order?.offers?.length }} {{ order?.offers?.length > 1 ? 'Itens':'Item' }}</h6>
      <h6>{{ countItems() | currency:' ' }} Kg / Un</h6>
    </div>
  </div>
  <div class="col-12 col-md-6 mt-2">
    <div class="item-wrapper m-0 d-flex justify-content-between align-items-center">
      <h6>Total Faturado</h6>
      <h6>{{ order?.nfeTotal || order?.total | currency:'BRL'}}</h6>
    </div>
  </div>
  <div class="col-12 col-md-6 mt-2">
    <div class="item-wrapper m-0 d-flex justify-content-between align-items-center">
      <h6>Forma de Pagamento</h6>
      <h5>{{ payment() }}</h5>
    </div>
  </div>
  <div class="col-12 col-md-6 mt-2">
    <div class="item-wrapper m-0 d-flex justify-content-between align-items-center">
      <h6>Entrega</h6>
      <h5>{{order?.date | date:'dd/MM/yyyy'}}</h5>
    </div>
  </div>
</div>

<div class="row px-3 mt-3" *ngIf="order?.comment || order?.reason">
  <h2>Observações</h2>
  <div class="item-wrapper w-100">
    <h6 class="mb-2">{{order.comment}}</h6>
    <div *ngIf="order?.reason">
      <strong>IZZA</strong>
      <div>{{ order?.reason }}</div>
    </div>
  </div>
</div>

<div class="footer"></div>

<span class="btn button bg-light fixed sec pointer d-print-none" *ngIf="confirm && order.status == 'i_accepted'" (click)="openModal()">
  Faturar
</span>

<button class="btn button secondary text-white p-4 fixed d-print-none" *ngIf="confirm && order.status == 'w_waiting'" (click)="accept()">
  Confirmar
</button>

<button class="btn button secondary text-white p-4 fixed d-print-none" *ngIf="!confirm || order.status != 'w_waiting'" (click)="print()">
  Imprimir
</button> 