import { Component, OnInit } from '@angular/core';
import { ProviderService } from '../../../shared/services/provider.service';
import { Query } from 'src/app/shared/util/query';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit {
  query: Query = new Query();
  providers: any = [];
  loading = false;

  constructor(private service: ProviderService) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this.loading = true;
    this.service.list(this.query).subscribe((providers) => {
      this.providers = providers;
      this.loading = false;
    });
  }

  deleteProvider() {
    return (id) =>
      this.service.delete(id).subscribe(() => {
        this.providers.data = this.providers.data.filter(p => p._id !== id);
      });
  }
}
