import { OrderService } from "../../../shared/services/order.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { CategoryService } from "src/app/shared/services/category.service";
import { CompanyService } from "src/app/shared/services/company.service";
import { UserService } from "src/app/shared/services/user.service";

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TermsComponent } from 'src/app/shared/ui/terms/terms.component';

import { Query } from "src/app/shared/util/query";

@Component({
  selector: "app-client-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class ClientHomeComponent implements OnInit {
  categories: any = [];
  orders: any = {};
  selected;
  
  companies;
  company;

  user: any = {}

  constructor(
    private service: CategoryService,
    private orderService: OrderService,
    private companyService: CompanyService,
    private modalService: BsModalService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.orderService.getCart().subscribe((cart) => {
      this.selected = cart.category;
    });

    this.userService.me().subscribe(me => {
      this.user = me

      if(!me.terms) this.modalService.show(TermsComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-lg",
        initialState: {
          onYes: () => {
            this.user.terms = true;
            this.userService.setMe(this.user)
          }
        }
      })
    })

    let query = new Query()
    query.limit = 1000
    this.companyService.list(query).subscribe((companies: any) => {
      this.companies = companies.data

      if (!this.company.name && this.companies.length) {
        this.setCompany(this.companies[0])
      }

      this.list();
    });

    this.companyService.watchCompany().subscribe((company) => {
      this.company = company;

      this.list();
    });
  }

  isSelecting = false;
  setCompany(company = {}) {
    if(!this.isSelecting) return this.isSelecting = true

    this.companyService.set(company)
    this.company = company
    
    this.isSelecting = false
  }

  list() {
    return this.service.list().subscribe((categories) => {
      this.categories = categories;
    });
  }

  details(category, sub) {
    this.orderService.setCategory(category._id, sub);
    this.router.navigate(["home", category._id, sub]);
  }
}
