<div class="modal-body col-6 text-center">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
  <p>Você tem certeza?</p>

  <div class="d-flex align-items-center justify-content-center">
    <div class="action-btn mr-3" (click)="decline()">Não</div>
    <div class="action-btn bg-secondary" (click)="confirm()">Sim</div>
  </div>
</div>