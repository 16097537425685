<form class="w-100 my-5 text-left col-12" #formVar="ngForm">
  <div class="row">
    <div class="col-12">
      <app-message-card [error]="true" *ngIf="error">
        E-mail ou senha inválida
      </app-message-card>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label for="email" class="text-secondary" [class.error]="email.invalid && email.touched">
          E-mail
        </label>
        <input type="text" class="form-control" id="email" placeholder="email@email.com.br" name="email"
          [(ngModel)]="user.email" #email="ngModel" [class.error]="email.invalid && email.touched" required>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
          <div *ngIf="email.errors.required">
            Este campo é obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label class="text-secondary" for="password" [class.error]="password.invalid && password.touched">
          Senha
        </label>
        <div class="d-flex">
          <input type="password" class="form-control" id="password" placeholder="******" name="password"
            [(ngModel)]="user.password" #password="ngModel" [class.error]="password.invalid && password.touched" required pw>
        </div>
        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="error">
          <div *ngIf="password.errors.required">
            Este campo é obrigatório
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <button [disabled]="formVar.form.invalid" class="mw-100 btn button primary mt-3" (click)="signin(user)">Login</button>
      <div class="text-right mt-2 mb-4">
        <a class="form-link" routerLink="/user/forgot-password">Esqueci minha senha</a>
      </div>
      <button class="mw-100 btn button primary mt-4" (click)="signup()">Não tenho cadastro</button>
    </div>
  </div>
</form>