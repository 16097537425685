<div class="modal-body">
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <form>
    <h2>Pedido #{{ number || "TESTE" }}</h2>
    <div class="mb-3">
      <label>Total</label>
      <input
        type="text"
        name="nfeTotal"
        currencyMask
        [options]="{ prefix: 'R$', precision: 2, thousands: '.', decimal: ',' }"
        class="form-control"
        [(ngModel)]="nfeTotal"
        #input="ngModel"
      />
    </div>
    <div class="mb-3" *ngIf="mode == 'admin'">
      <label>Data de entrega (Planejada)</label>
      <input
        class="form-control text-right"
        name="date"
        [value]="_date"
        bsDatepicker
        (bsValueChange)="changeDate($event)"
        [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true, dateInputFormat: 'DD/MM/YYYY' }"
        [minDate]="minDate"
      />
    </div>
    <div class="mb-3">
      <label>Nota Fiscal</label>
      <input
        type="number"
        name="nfe"
        class="form-control text-right"
        [(ngModel)]="nfe"
        #input="ngModel"
      />
    </div>
    <div class="mb-3">
      <label *ngIf="mode == 'admin'">Data de Entrega (Realizada)</label>
      <label *ngIf="mode != 'admin'">Data de Entrega</label>
      <input
        class="form-control text-right"
        name="nfeDate"
        [value]="_nfeDate"
        bsDatepicker
        (bsValueChange)="changeNFeDate($event)"
        [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true, dateInputFormat: 'DD/MM/YYYY' }"
      />
    </div>
    <div class="mb-3">
      <label>Observação</label>
      <textarea
        type="text"
        class="form-control"
        name="reason"
        [(ngModel)]="reason"
        rows="4"
      ></textarea>
      <div class="error" *ngIf="_reasonReq && !reason">
        Preencha as observações do pedido
      </div>
    </div>
    <div class="mb-3" *ngIf="mode == 'admin'">
      <label>Status</label>
      <ng-select
        class="w-100"
        [clearable]="false"
        name="status"
        [(ngModel)]="status"
      >
        <!-- [disabled]="['f_completed', 'f_canceled'].includes(order.status)" -->
        <ng-option value="w_waiting" disabled="true">
          <span class="weight-medium text-medium">Pedido Realizado</span>
        </ng-option>
        <ng-option value="i_accepted">
          <span class="weight-medium text-medium">Pedido Confirmado</span>
        </ng-option>
        <ng-option value="i_inprogress">
          <span class="weight-medium text-medium">Pedido Faturado</span>
        </ng-option>
        <ng-option value="f_completed">
          <span class="weight-medium text-medium">Pedido Finalizado</span>
        </ng-option>
        <ng-option value="f_canceled">
          <span class="weight-medium text-medium">Cancelado</span>
        </ng-option>
      </ng-select>
    </div>

    <div class="mb-3" *ngIf="mode == 'admin' && payment === 'credit'">
      <label>Status do pagamento</label>
      <ng-select
        class="w-100"
        [clearable]="false"
        name="paymentStatus"
        [(ngModel)]="paymentStatus"
      >
        <!-- [disabled]="['f_completed', 'f_canceled'].includes(order.status)" -->
        <ng-option value="pending">
          <span class="weight-medium text-medium">Aguardando pagamento</span>
        </ng-option>
        <ng-option value="paid">
          <span class="weight-medium text-medium">Pagamento realizado</span>
        </ng-option>
        <ng-option value="overdue">
          <span class="weight-medium text-medium">Pagamento atrasado</span>
        </ng-option>
      </ng-select>
    </div>
  </form>
  <span class="mt-2 btn button secondary text-white" (click)="save()"
    >Salvar</span
  >
</div>
