import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";

import { ToastrService } from "ngx-toastr";

import { OrderService } from "src/app/shared/services/order.service";
import { OfferService } from "src/app/shared/services/offer.service";
import { CompanyService } from "src/app/shared/services/company.service";
import { Query } from "src/app/shared/util/query";

import getProductPrice from "src/app/shared/util/getProductPrice";

@Component({
  selector: "app-order-bag",
  templateUrl: "./order-bag.component.html",
  styleUrls: ["./order-bag.component.scss"],
})
export class OrderBagComponent implements OnInit {
  isCollapsed = false;
  me: any;
  providers;
  minDate: Date;

  oldOrder: any = {}
  company: any = {}
  order: any = {}
  product: any = {}
  cheapest: any = {}
  total: any = 0;
  addr;

  allProvidersOffers: any;
  selectedOffer: any;
  bestOffer: any;

  bestOrder: any = {};

  getPrice = getProductPrice;

  constructor(
    private service: OrderService,
    private offerService: OfferService,
    private companyService: CompanyService,
    private localeService: BsLocaleService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.localeService.use("pt-br");
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);

    this.getCompany();
  }

  getCompany() {
    this.companyService.getCompany().subscribe((company) => {
      this.order.client = company._id;
      this.company = company;

      this.addr = company.setAddress || {}
      this.addr.city_name = `${this.addr?.city}, ${this.addr?.state}`;

      this.getProducts();
    });
  }

  getProducts() {
    this.service.watchCart().subscribe((order) => {
      this.order = { ...order, client: this.order.client };

      this.order.products = this.order.products.filter((p) => p.amount > 0);

      this.getOffers();
    });
  }

  offerStatus(offer) {
    if (offer.sort == 1) return `Melhor oferta`;

    if (offer.sort == 3) return `Itens em falta`;

    if (offer.sort == 4) return `Pedido mínimo R$ ${offer?.city?.min}`;
  }

  isValidOffer() {
    let offer = this.selectedOffer;

    return offer?.isMultiOrder ? true : offer?.total >= offer?.city?.min;
  }

  updateAmount(product?: any, value: any = 0) {
    if (product) {
      product.amount = parseInt(product.amount) + parseInt(value);
    }

    this.service.setProduct(product);
    this.calculateProvidersTotals();
    this.sortOffers();
  }

  getOffers(refresh = false) {
    let calc = () => {
      this.calculateProvidersTotals();
      this.sortOffers();
      this.extractProvidersFrom(this.allProvidersOffers);
    };

    if (this.allProvidersOffers && !refresh) return calc();

    const query = new Query();
    query.search.products = this.order.products;
    query.search.populate = true;
    query.search.groupBy = "provider";
    query.search.city = this.addr.city_name;
    query.search.neighbourhood = this.addr.neighbourhood;

    this.offerService.list(query).subscribe((providersOffers: any) => {
      this.allProvidersOffers = providersOffers;
      calc();
    });
  }

  _bestOrder;
  calculateProvidersTotals() {
    let index = 0;

    for (const providerOffers of this.allProvidersOffers) {
      const providerOfferswithTotal =
        this.getProviderOfferWithTotal(providerOffers);
      this.allProvidersOffers[index] = providerOfferswithTotal;
      index++;
    }

    this.bestOrder = {}
    clearTimeout(this._bestOrder)
    this._bestOrder = setTimeout(() => {
      let total = this.calculateBestOrder()

      if(total < this.calculateBestOrder(true)) this.calculateBestOrder()

    }, 2000)
  }

  getProviderOfferWithTotal(providerOffers) {
    const offers = providerOffers.offers;

    const total = offers.reduce((sum, offer) => {
      sum += this.multiplyPriceByAmount(offer);
      return sum;
    }, 0);

    if (providerOffers.provider._id === this.selectedOffer?.provider?._id) {
      this.selectedOffer.total = total;
    }

    return { ...providerOffers, total, items: offers.length };
  }

  multiplyPriceByAmount(offer: any) {
    const product = this.order.products.find(
      (p) => p.product === offer.product
    );
    if (!product) return 0;

    return this.getPrice(product, false, offer.price);
  }

  isCheapest(p) {
    return this.cheapest[p._id] == p.price;
  }

  extractProvidersFrom(providersOffers) {
    this.providers = providersOffers.map((o) => ({ name: o.provider.name, logo: o.provider.logo, total: o.total }) );
  }

  sortOffers() {
    let offers;

    offers = this.allProvidersOffers.sort((a, b) => a.total - b.total);
    offers[0].sort = 1;

    this.allProvidersOffers.forEach((offer) => {
      if(offer.sort != 1) offer.sort = 2;

      offer.city = this.service.selectCity(offer.provider.cities, this.addr)

      if (!offer.city || offer.total < offer.city?.min) {
        offer.sort = 4;
      } else if (offer.items != this.order.products.length) {
        offer.sort = 3;
      }
    });

    this.allProvidersOffers = offers.sort((a, b) => a.sort - b.sort);
  }

  getDayRanges(days) {
    if (!days) return;

    let _days = {
      0: "Domingo",
      1: "Segunda",
      2: "Terça",
      3: "Quarta",
      4: "Quinta",
      5: "Sexta",
      6: "Sábado",
    };

    var ranges = [],
      rstart,
      rend;
    for (var i = 0; i < days.length; i++) {
      rstart = days[i];
      rend = rstart;
      while (days[i + 1] - days[i] == 1) {
        rend = days[i + 1];
        i++;
      }
      ranges.push(
        rstart == rend
          ? _days[rstart] + ""
          : _days[rstart] + " à " + _days[rend]
      );
    }
    return "Entrega: " + ranges.join(", ");
  }

  selectOffer(providerOffer) {
    if(providerOffer.isMultiOrder && providerOffer.providers.length <= 1) return
    
    this.selectedOffer = providerOffer;
    this.order.candidate = providerOffer.provider;

    if(providerOffer.isMultiOrder)
      this.selectedOffer.difference = this.allProvidersOffers[0].total - providerOffer?.total

    this.updateOrderPrices(providerOffer);
  }

  updateOrderPrices(providerOffer) {
    this.order.products = this.order.products.map((p) => {
      const offerProduct = providerOffer.offers.find(
        (o) => o.product === p.product
      );
      const { price } = offerProduct || 0;

      return { ...p, price, disabled: !offerProduct };
    });
  }

  calculateBestOrder(reverse = false) {
    // Calcula o preço medio das ofertas do fornecedor (Enviado como parametro na função)
    let calcAvgPrice = (provider) => {
      let _sum = 0
      for (const k of Object.keys(this.product)) {
        let product = this.product[k]

        let _p = product.find(p => p.provider == provider)
        
        if(!_p) _p = product[0]

        _sum += _p.price
      }

      return (_sum / Object.keys(this.product).length)
    }
    
    // Cria um map ORDENADO POR MENOR PREÇO de cada produto
    // {
    //   "cod_produto_1": [{ // cafe
    //     id: "produto_1", // café 3 corações
    //     provider: "prov_1",
    //     price: 1203
    //   }, {
    //     id: "produto_1",  // café melita
    //     provider: "prov_2",
    //     price: 1284
    //   }, {
    //     id: "produto_1",  // café melita
    //     provider: "prov_1",
    //     price: 1300
    //   }]
    // }
    let mapOffers = (offers, provider, cheapest = false) => {
      offers.map((o) => {
        let product = this.product[o.product] || [];
        product.push({
          price: o.price,
          provider
        })
  
        product.sort((a,b) => a.price - b.price)
        this.product[o.product] = product
        if(cheapest) this.cheapest[o.product] = product[0].price
      })
    }

    // Separar as ofertas para cada um dos fornecedores
    let splitOffers = (providers) => {
      const alreadyAddedProducts = {};

      this.bestOrder.providers = providers
        .map((provider) => {
          return {
            avg: calcAvgPrice(provider.provider._id),
            ...provider,
            // Filtra todas as ofertas com base no mapeamento dos melhores preços feito anteriormente
            offers: provider.offers.filter((o) => {
              return provider.provider._id == this.product[o.product][0].provider
            }),
          };
        })
    }

    this.allProvidersOffers.map(p => { mapOffers(p.offers, p.provider._id, true) })

    let invalidIds = {}
    let interactions = 0
    let invaliProviders = this.allProvidersOffers.filter(p => p.sort == 4)
    do {
      invaliProviders.map(p => { invalidIds[p.provider._id] = true })

      // Remove todos os fornecedores que são marcados como invalidos e recalcula o mapeamento das ofertas
      // Na nova versão, o mapeamento de ofertas será feito com base nos fornecedores selecionados
      this.product = {}
      let providers = this.allProvidersOffers
      .filter(p => !invalidIds[p.provider._id])
      .map(p => { 
        mapOffers(p.offers, p.provider._id)

        return p
      })

      // Realiza a divisão das ofertas dentro dos fornecedores ainda elegiveis
      splitOffers(providers);

      // Calcula o total em cada um dos fornecedores 
      this.bestOrder.providers = this.bestOrder.providers
      .map((o) => {
        return {
          ...o,
          total: o.offers.reduce((acc, p) => acc + this.multiplyPriceByAmount(p), 0),
        }
      })
      .filter(p => p.offers.length > 0);

      // Ordena os fornecedores pelo melhor preço médio
      this.bestOrder.providers.sort((a,b) => b.avg - a.avg)
    
      // Caso algum fornecedor não atinja o pedido minimo, ele é marcado como invalido
      // Como a nova versão não terá a extinsão por menor, será calculado todos as combinações de fornecedores possíveis.
      // function generateCombinations(array) {
      //     let results = [];
      //     function combine(startIndex, combination) {
      //         results.push([...combination]);
      //         for (let i = startIndex; i < array.length; i++) {
      //             combination.push(array[i]);
      //             combine(i + 1, combination);
      //             combination.pop();
      //         }
      //     }
      //
      //     combine(0, []);
      //
      //     return results;
      // }
      // const combinations = generateCombinations(['a', 'b', 'c']);
      invaliProviders = []
      invaliProviders = this.bestOrder.providers.filter((p) => p.total < parseInt(p?.city?.min));

      if(reverse && invaliProviders[0]?.sort == 1) invaliProviders = [this.bestOrder.providers[0]]

      invaliProviders = invaliProviders.slice(0, 1)
    }while(interactions++ < 100 && invaliProviders.length > 0)

    this.bestOrder.total = this.bestOrder.providers.reduce((acc, p) => acc + p.total, 0);
    this.bestOrder.offers = this.bestOrder.providers.reduce((acc, provider) => [...acc, ...provider.offers], [])
    this.bestOrder.providers.sort((a, b) => b.total - a.total);
    this.bestOrder.isMultiOrder = true

    return this.bestOrder.total
  }

  back() {
    this.order = Object.assign({}, this.oldOrder);
    this.order.candidate = this.order.provider;
    delete this.order.provider;
  }

  continue() {
    if(!this.isValidOffer()) return

    this.order.selectedOffer = this.selectedOffer;

    this.service.setCart(this.order);
    this.service.saveCart();
    this.router.navigate(["/order", "confirm"]);
  }

  save() {
    const { products, ...rest } = this.order;

    return this.service.save(rest).subscribe((order) => {
      this.service.clearCart();
      this.router.navigate(["/order"]);
    });
  }
}