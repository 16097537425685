import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { UserComponent } from "./modules/user/user.component";
import { SigninComponent } from "./modules/user/signin/signin.component";
import { ForgotPasswordComponent } from "./modules/user/forgot-password/forgot-password.component";
import { NewPasswordComponent } from "./modules/user/new-password/new-password.component";
import { ProfileComponent } from "./compoments/profile/profile.component";

import { OrderComponent } from "./compoments/order/order.component";
import { OrderDetailsComponent } from "./compoments/order/order-details/order-details.component";
import { OrderPrintComponent } from "./compoments/order/order-print/order-print.component";

import { HomeAdminComponent } from "./modules/admin/home/home.component";
import { WelcomeComponent } from "./modules/client/welcome/welcome.component";
import { SchoolComponent } from "./modules/admin/school/school.component";
import { SchoolDetailsComponent } from "./modules/admin/school/school-details/school-details.component";
import { SchoolFormComponent } from "./modules/admin/school/school-form/school-form.component";
import { SchoolReportComponent } from "./modules/admin/school/school-report/school-report.component";
import { ProviderComponent } from "./modules/admin/provider/provider.component";
import { ProviderDetailsComponent } from "./modules/admin/provider/provider-details/provider-details.component";
import { ProviderFormComponent } from "./modules/admin/provider/provider-form/provider-form.component";
import { ProductComponent } from "./modules/admin/product/product.component";
import { ProductFormComponent } from "./modules/admin/product/product-form/product-form.component";
import { ProductDetailsComponent } from "./modules/admin/product/product-details/product-details.component";
import { UserManagementComponent } from "./compoments/user-management/user-management.component";

import { ClientHomeComponent } from "./modules/client/home/home.component";
import { ProductListComponent } from "./modules/client/product/product.component";
import { DashboardComponent } from "./compoments/dashboard/dashboard.component";
import { OrderBagComponent } from "./compoments/order/order-bag/order-bag.component";
import { AuthGuard } from "./shared/auth/auth.guard";
import { SignupComponent } from "./modules/user/signup/signup.component";
import { OrderConfirmationComponent } from "./compoments/order/order-confirmation/order-confirmation.component";
import { OrderNewAddressComponent } from "./compoments/order/order-new-address/order-new-address.component";

const routes: Routes = [
  {
    path: "public/order/:id",
    component: OrderPrintComponent,
  },
  {
    path: "client/:id/report",
    component: SchoolReportComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: 'home', component: HomeAdminComponent },

      { path: "order", component: OrderComponent },
      { path: "order/provider", component: OrderBagComponent },
      { path: "order/confirm", component: OrderConfirmationComponent },
      { path: "order/address", component: OrderNewAddressComponent },
      { path: "order/:id", component: OrderDetailsComponent },

      { path: "client", component: SchoolComponent },
      { path: "client/new", component: SchoolFormComponent },
      { path: "client/:id", component: SchoolDetailsComponent },
      { path: "client/:id/edit", component: SchoolFormComponent },

      { path: "provider", component: ProviderComponent },
      { path: "provider/new", component: ProviderFormComponent },
      { path: "provider/:id", component: ProviderDetailsComponent },
      { path: "provider/:id/edit", component: ProviderFormComponent },

      { path: "home", component: ClientHomeComponent },
      { path: "home/:order", component: ProductListComponent },
      { path: "home/:id/:sub", component: ProductListComponent },

      { path: "product", component: ProductComponent },
      { path: "product/new", component: ProductFormComponent },
      { path: "product/:id", component: ProductDetailsComponent },
      { path: "product/:id/edit", component: ProductFormComponent },

      { path: "user-management", component: UserManagementComponent },
      { path: "profile", component: ProfileComponent },

      { path: "welcome", component: WelcomeComponent },
      { path: ":id", component: OrderComponent },

      { path: "", pathMatch: "full", redirectTo: "/home" },
    ],
  },

  {
    path: "user",
    component: UserComponent,
    children: [
      { path: "", pathMatch: "full", redirectTo: "signin" },
      { path: "signin", component: SigninComponent },
      { path: "signup", component: SignupComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "reset/:token", component: NewPasswordComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
