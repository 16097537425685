import { ReplaySubject } from 'rxjs';

export class ImageInput {

  fileObservable = new ReplaySubject();

  constructor({ input }: { 
    input: HTMLInputElement,
  }) {

    input.addEventListener('change', e => {
      this.readFile(e);
    });
  }

  readFile(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (ev: any) => {
      this.fileObservable.next({ file, base64: ev.target.result });
    };
  }

  fileChange() { return this.fileObservable; }
}
