<div class="modal-body col-12 text-justify">
  <h2>TERMOS E CONDIÇÕES</h2>

  <p>Importante, leia com atenção os termos e condições. Ao se cadastrar no www.izzaweb.com.br você ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS da Plataforma Web. Note que a recusa destes Termos do Website impedirá que você faça pedidos de produtos e serviços da nossa Plataforma Web.</p>
  <p>1. SERVIÇOS OFERECIDOS</p>
  <p>1.1	Este TERMO se aplica para regular o uso do serviço oferecido pelo sistema de compras de produtos e contratação de serviços na plataforma izzaweb.com.br aos USUÁRIOS, qual seja pessoa física ou jurídica, possibilitar a escolha, pelos USUÁRIOS, de FORNECEDORES DE PRODUTOS e PRESTADORES DE SERVIÇOS cadastrados e, via on-line, efetivar solicitações de compra de produtos e serviços, disponíveis no menu de NOVO PEDIDO.</p>
  <p>1.2	O serviço da plataforma izzaweb.com.br consiste, portanto, em aproximar, através da plataforma web, os USUÁRIOS e os FORNECEDORES de produtos e serviços cadastrados, possibilitando que os USUÁRIOS encaminhem, aos FORNECEDORES, pedidos de entrega de produtos e cotação de serviços cadastrados, bem como, podendo ainda a plataforma dar a opção dos USUÁRIOS, pagar on-line e/ou pagamento de “Fatura Izza” o preço dos produtos constante no pedido do USUÁRIO encaminhado aos FORNECEDORES.</p>
  <p>1.3 	Desde logo fica esclarecido ao USUÁRIO - o qual se declara ciente - que o serviço oferecido pelo izzaweb.com.br se relaciona apenas à intermediação para comercialização de produtos oferecidos pelos FORNECEDORES cadastrados, não englobando a embalagem, disponibilização e entrega física (via motoboy ou outros meios) dos produtos, sendo esses itens de responsabilidade integral do FORNECEDOR escolhido, a quem deverão ser direcionados quaisquer reclames acerca de problemas decorrentes da qualidade e integridade dos produtos recebidos ou não, diretamente ao FORNECEDOR através da ferramenta de contato disponibilizada pela Plataforma em seu endereço eletrônico izzaweb.com.br ou outro meio disponibilizado.</p>
  <p>2. CADASTRO</p>
  <p>2.1 	O USUÁRIO, para utilizar os serviços acima descritos, deverá ter capacidade jurídica para atos civis e deverá, necessariamente, prestar as informações exigidas no CADASTRO, assumindo integralmente a responsabilidade (inclusive cível e criminal) pela exatidão e veracidade das informações fornecidas no CADASTRO, que poderá ser verificado, a qualquer momento, pelo izzaweb.com.br .</p>
  <p>2.1.1 	Em caso de informações incorretas, inverídicas ou não confirmadas, bem como na hipótese da negativa em corrigi-las ou enviar documentação que comprove a correção, o IZZA se reserva o direito de não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo o USUÁRIO de utilizar os serviços on-line até que, a critério da izzaweb.com.br, a situação de anomalia esteja regularizada. A izzaweb.com.br se reserva o direito de impedir, a seu critério, novos CADASTROS, ou cancelar os já efetuados, em caso de ser detectada anomalia que, em sua análise, seja revestida de gravidade ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todos os USUÁRIOS. Também agirá a izzaweb.com.br de tal forma caso verifique descumprimento, pelo USUÁRIO, de qualquer obrigação prevista no presente TERMO.</p>
  <p>2.2 	Efetuado, com sucesso, o CADASTRO, o USUÁRIO terá acesso aos serviços por meio de login e senha, dados esses que se compromete a não divulgar a terceiros, ficando sob sua exclusiva responsabilidade qualquer solicitação de serviço que seja feita com o uso de login e senha de sua titularidade.</p>
  <p>3. OBRIGAÇÕES DO USUÁRIO</p>
  <p>3.1 	Efetuado com sucesso o CADASTRO do USUÁRIO, este se obriga a não divulgar a terceiros login e senha de acesso, nem permitir o uso de tais informações por terceiros, responsabilizando-se pelas consequências do uso de login e senha de sua titularidade.</p>
  <p>3.2 	É obrigação do USUÁRIO fornecer informações cadastrais totalmente verídicas e exatas, responsabilizando-se exclusiva e integralmente (em todas as searas jurídicas) por todo o conteúdo por si informado no item CADASTRO, mantendo atualizado e confirmado o endereço para entrega dos produtos encomendados e/ou realização dos serviços solicitados.</p>
  <p>3.3 	O USUÁRIO se obriga, também, a pagar integralmente o preço dos produtos e serviços por si solicitados ou encomendados ao FORNECEDOR e efetivamente a si entregues ou serviços concluídos, seja pela modalidade on-line, “Fatura Izza” ou, seja por qualquer outra forma, por meio deste site, através de faturamento, boleto, Pix, Ted, Doc, transferência bancária, espécie ou outro meio disponibilizado, conforme acordado no ato do pedido ou contratação do serviço.</p>
  <p>3.4 	O USUÁRIO autoriza a inclusão de seus dados junto ao banco de dados de empresas de análise e proteção ao crédito,  em caso de inadimplência igual ou superior a 05(cinco) dias, a critério da Plataforma IZZA, bem como o bloqueio para novas compras junto a plataforma, sem prejuízo as demais cláusulas deste termo.</p>
  <p>3.5 	O USUÁRIO concorda com o uso das informações de avaliações e feedbacks dos serviços dos FORNECEDORES parceiros, conforme descrito nos TERMOS DE PRIVACIDADE da izzaweb.com.br.</p>
  <p>4. OBRIGAÇÕES DA IZZAWEB.COM.BR</p>
  <p>4.1 	Disponibilizar no site izzaweb.com.br espaço virtual que permita ao USUÁRIO devidamente cadastrado efetivar pedidos de compra de produtos e serviços cadastrados e comercializados pelos FORNECEDORES, também, disponibilizar ao USUÁRIO meios de pagamento do preço dos produtos on-line.</p>
  <p>4.2	Proteger, por meio de armazenamento em servidores ou quaisquer outros meios magnéticos de alta segurança, a confidencialidade de todas as informações e cadastros relativos aos USUÁRIOS, assim como valores relativos às operações financeiras advindas da operacionalização dos serviços previstos no presente TERMO. Contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.</p>
  <p>5. MODIFICAÇÕES DESTE TERMO</p>
  <p>5.1 	O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços disponibilizados.</p>
  <p>5.2 	As novas condições entrarão em vigência assim que sua veiculada no site, sendo possível ao USUÁRIO manifestar oposição a quaisquer dos termos modificados, desde que o faça por escrito, através do site izzaweb.com.br , o que gerará o cancelamento de seu CADASTRO.</p>
  <p>6. CANAL DE COMUNICAÇÃO</p>
  <p>6.1 Para estabelecer contato entre izzaweb.com.br e o USUÁRIO fica disponibilizado o endereço eletrônico deste link, sendo certo que o USUÁRIO se obriga, igualmente, a manter em seu cadastro endereço eletrônico atual por intermédio do qual se farão as comunicações a ele dirigidas pela izzaweb.com.br , desde logo emprestando-se validade jurídica e efetividade a esse meio eletrônico de troca de informações recíprocas.</p>
  <p>7. ACEITAÇÃO DO TERMO DE USO</p>
  <p>7.1 	O USUÁRIO declara ter lido, entendido e que aceita todas as regras, condições e obrigações estabelecidas no presente TERMO.</p>
  <p>8. FORO DE ELEIÇÃO</p>
  <p>8.1 	As partes elegem como competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do presente TERMO o foro da Comarca de Porto Alegre / RS</p>
 
  <h2>POLITICA DE PRIVACIDADE</h2>
  <p>Essa politica de Privacidade descreve como obtemos, armazenamos, utilizamos e compartilhamos as suas informações.</p>
  <p>Os seus dados são seus. Respeitamos a sua privacidade e tratamos os seus dados com responsabilidade e zelo. </p>
  <p>A proteção dos seus dados e da sua privacidade são muito importantes para a www.izzaweb.com.br . Recomendamos que você conheça as nossas práticas como um todo, mas procuramos aqui esclarecer os pontos que julgamos importantes para que você entenda como seus dados são tratados na plataforma:</p>
  <p>#	Os dados que você cadastra na plataforma www.izzaweb.com.br  são tratados de forma segura e limitamos o tratamento para suas devidas finalidades.</p>
  <p>#	Seus dados completos de cartão não ficam armazenados em nossas bases, mas sim no dispositivo utilizado na compra, quando autorizado, no momento da primeira utilização.</p>
  <p>#	Manteremos canais ativos de comunicação na plataforma, mas ainda poderemos contatá-lo para esclarecer e/ou adicionar informações sobre o seu pedido.</p>
  <p>#	Você poderá tirar suas dúvidas e solicitar informações a qualquer momento através da aba Ajuda em nossa plataforma.</p>
  <p>1. A quem essa Declaração se aplica?</p>
  <p>Esta Declaração de Privacidade, também chamada de “Declaração”, é aplicável àqueles que acessarem ou se cadastrarem em nossa Plataforma (conforme definição abaixo) e àqueles que de qualquer outra forma utilizarem os produtos e serviços disponíveis em www.izzaweb.com.br</p>
  <p>Na Plataforma www.izzaweb.com.br, quando houver menção aos termos “IZZA”, “nós” ou “nossos”, estamos nos referindo ao IZZA; da mesma forma, toda vez que houver menção aos termos “você”, “seu”, “sua”, estamos nos referindo a Você USUÁRIO.</p>
  <p>2. Quem controla o tratamento dos dados pessoais?</p>
  <p>O controlador é a pessoa física ou jurídica que decide sobre o tratamento de dados pessoais.</p>
  <p>Quando você acessar ou se cadastrar em nossa Plataforma, a IZZA exerce o papel de controlador do tratamento de seus dados pessoais, atuando conforme a legislação aplicável e conforme descrito na presente Declaração.</p>
  <p>3. Quais dados são coletados?</p>
  <p>3.1 Dados que você nos fornece</p>
  <p>Quando você criar uma conta para ser um usuário registrado no IZZA, poderemos obter uma série de informações sobre você e/ou sua empresa, tais como:</p>
  <p>A) Dados do cadastro</p>
  <p>Pessoa Física - Esses dados incluem seu nome, CPF, e-mail, data de nascimento, endereço de entrega, número de telefone e preferências de contato. Ainda, para criar o seu cadastro na plataforma www.izzaweb.com.br, você pode usar a sua conta em redes sociais. Por exemplo, ao utilizar o Facebook para se inscrever no nosso Serviço, você estará permitindo que a Plataforma IZZA acesse as informações pessoais em sua conta do Facebook, tais como seu nome e e-mail (caso os tenha cadastrado no Facebook). As informações que iremos obter, nesse caso, são as mesmas assim e dependem das suas configurações de privacidade junto ao serviço de rede social.</p>
  <p>Pessoa Jurídica - esses dados incluem nome fantasia, razão social, CNPJ, número de alunos matriculados (esta informação não é obrigatória, servirá tão somente para fins estatísticos e ajudar na diminuição do custo por aluno quando Escola), endereço de cadastro e endereço de entrega, email e telefone principais. No cadastro será necessário indicar no mínimo 1 “Gestor” que será o responsável para acesso a plataforma, podendo ser acrescentados tantos outros julgar necessário, indicação do diretor(a), gerente, financeiro e nutricionista.</p>
  <p>B) Dados de pagamento</p>
  <p>Ao fazer o seu pedido pelo www.izzaweb.com.br , conforme avaliação de crédito, a depender do crédito concedido, você poderá fazer o pagamento diretamente por nossa plataforma através de “Fatura Izza” emitida nos fechamentos quinzenais de todas compras com NF emitidas entre as datas pré estabelecidas com emissão de boleto único com vencimento em até 5 dias após a emissão do mesmo.</p>
  <p>Caso por critério único e exclusivo da Izza, não houver liberação de crédito suficiente para finalizar a compra, poderá ser disponibilizado pela Izza o pagamento por cartão de crédito e/ou boleto bancário e/ou chave pix para pagamento à vista, sendo feita a liberação da entrega tão logo se confirme a efetivação do pagamento.</p>
  <p>Nestes casos, ao fazer o pagamento direto na nossa plataforma, você poderá nos fornecer os seus dados de pagamento, tais como aqueles de cartão de crédito e de meios de pagamento parceiros.</p>
  <p>Atenção: suas informações de pagamento online são armazenadas somente de forma anonimizada pelo parceiro de pagamentos, de modo que não temos acesso aos seus dados financeiros completos.</p>
  <p>C) Dados de localização</p>
  <p>Para realizarmos a entrega do seu pedido, nós precisamos que você também nos informe a localização da entrega se esta for diferente da de cadastro.</p>
  <p>Para fins da lei n° 12.965 de 2014 (Marco Civil da Internet), ou qualquer lei que venha substituí-la, a localização fornecida será considerada como dado cadastral.</p>
  <p>3.2 Dados gerados durante a utilização dos serviços</p>
  <p>A) Dados dos seus dispositivos</p>
  <p>Nós podemos coletar informações automaticamente sobre os dispositivos a partir dos quais você acessa o www.izzaweb.com.br como: endereços IP, tipo de navegador e idioma, provedor de Serviços de Internet (ISP), páginas de consulta e saída, sistema operacional, informações sobre data e horário, dados sobre a sequência de cliques, fabricante do dispositivo, operadora, modelo, versão do aparelho, versão do aplicativo, versão do sistema operacional, identificador de publicidade do aparelho (IDFA), informações de acessibilidade do aparelho e redes Wi-Fi.</p>
  <p>B) Dados transacionais e dados sobre sua utilização</p>
  <p>Coletamos dados sobre suas interações em nossa plataforma, incluindo data e horário de acessos, buscas e visualizações na Plataforma. </p>
  <p>C) Dados de comunicação</p>
  <p>Intermediamos a comunicação entre usuários, fornecedores através da nossa Plataforma. Permitimos, por exemplo, que usuários e fornecedores parceiros, enviem mensagens através do chat. Para prestar este serviço, a IZZA recebe alguns dados relativos às chamadas, textos ou outras comunicações, incluindo a data e hora das comunicações e o conteúdo das comunicações.</p>
  <p>4. Como utilizamos esses dados?</p>
  <p>4.1 Prover nossos serviços</p>
  <p>Podemos utilizar os dados coletados para prover, manter, melhorar e personalizar nossos produtos e serviços destinados a você, existentes ou a serem criados.</p>
  <p>Esse tratamento inclui que poderemos usar seus dados pessoais para fins internos, tais como auditoria, análise de dados e pesquisas para aprimorar os produtos, serviços e comunicações com os clientes, bem como geração de análises estatísticas com respeito ao uso dos nossos serviços, incluindo tendências de consumo.</p>
  <p>4.2 Avaliação de fornecedores</p>
  <p>Ao enviar comentários e feedback com relação à Plataforma e os serviços oferecidos pelos fornecedores parceiros, você nos autoriza a publicar e utilizar tais comentários e feedbacks na Plataforma, bem como a analisar, processar e tratar esse feedback de forma isolada ou agregada. Para tanto, apenas identificamos você através de seu nome de cadastro.</p>
  <p>4.3 Comunicação não comercial</p>
  <p>Periodicamente poderemos usar seus dados pessoais para enviar avisos e notificações importantes, como comunicados sobre compras, alterações em prazos, condições e políticas. Como estas informações são importantes para a sua interação com a Plataforma IZZA, você não poderá optar por não receber esse tipo de comunicação, já que são inerentes ao uso do serviço.</p>
  <p>4.4 Suporte</p>
  <p>Também teremos acesso às suas informações quando você solicitar suporte sobre problemas com pedidos ou entregas, seja por meio da aba “Ajuda” no Website.</p>
  <p>Neste caso, poderemos utilizar informações como seus dados cadastrais para: nos certificarmos de que você é o usuário que solicitou o pedido em questão; direcionar suas dúvidas para o atendente de suporte; investigar e direcionar seu problema e; monitorar e melhorar nossos procedimentos de suporte.</p>
  <p>4.5 Gerenciamento de pagamentos efetuados online</p>
  <p>Somente processamos seus dados para a finalidade especificada, qual seja, a de permitir que uma transação, quando efetuada online, com cobrança para você. A Plataforma IZZA nunca processará suas informações financeiras e/ou bancárias de forma a desrespeitar as normas de compliance aplicáveis.</p>
  <p>4.6 Marketing</p>
  <p>Nós poderemos usar seus dados pessoais para enviar publicidade, bem como outros materiais promocionais voltados ao marketing de nossos serviços e de novidades para você, o que inclui marketing direcionado em redes sociais e notificações push.</p>
  <p>Nesse caso, você pode optar por não receber mais notificações de marketing a qualquer momento, enviando email através dos canais disponíveis na plataforma.</p>
  <p>4.7 Requisições legais e regulatórias</p>
  <p>Seus dados poderão ser utilizados para endereçarmos reivindicações jurídicas e regulatórias relacionadas à utilização dos nossos serviços.</p>
  <p>5. Como utilizamos cookies e outras tecnologias?</p>
  <p>A plataforma IZZA se utiliza de tecnologias como cookies, pixel tags, armazenamento local ou outros identificadores, tanto de dispositivos móveis ou não, ou tecnologias semelhantes (“cookies e outras tecnologias”), para uma variedade de funções. Essas tecnologias nos ajudam a autenticar a sua conta, promover e aperfeiçoar os serviços da plataforma, personalizar a sua experiência e avaliar a eficácia da nossa comunicação e publicidade.</p>
  <p>5.1 O que são essas tecnologias?</p>
  <p>Cookies são pequenos arquivos armazenados no seu navegador, celular ou outro dispositivo.</p>
  <p>Pixel tags (ou GIFs limpos, web beacons ou pixels) são pequenos blocos de código em uma página da web que permitem que elas realizem ações como ler e armazenar cookies e transmitir informações para a plataforma. A conexão resultante pode incluir informações como o endereço de IP de um dispositivo, a hora em que uma pessoa visualizou o pixel, um identificador associado ao navegador ou dispositivo e o tipo de navegador em uso.</p>
  <p>Identificadores de dispositivo mobile são códigos que possibilitam a identificação do seu dispositivo mobile, seja de maneira persistente ou transitória, tais como o ID de Publicidade ou o ID de seu sistema operacional.</p>
  <p>5.2 Promover uma experiência personalizada</p>
  <p>A Plataforma IZZA usa cookies e outras tecnologias para memorizar informações pessoais quando você usa o nosso Website. A nossa meta nesses casos é fazer com que a sua experiência com a plataforma seja mais conveniente e personalizada.</p>
  <p>6. Como os dados são armazenados?</p>
  <p>6.1 Onde os dados são armazenados?</p>
  <p>Os dados que coletamos de você são armazenados em serviços de nuvem confiáveis de parceiros que podem estar localizados no Brasil ou em outros países que ofereçam Serviço de armazenamento de nuvem confiáveis e usualmente utilizados por empresas de tecnologia, tais como Estados Unidos da América (EUA) e em países da América Latina e da Europa.</p>
  <p>Na contratação desses serviços, a Plataforma IZZA sempre busca empresas que empregam alto nível de segurança no armazenamento de suas informações, estabelecendo contratos que não violam as definições de privacidade previstas nesta Declaração</p>
  <p>6.2 Por quanto tempo os dados são armazenados?</p>
  <p>A Plataforma IZZA armazena as suas informações durante o período necessário para as finalidades apresentadas nos Termos e Condições de Uso da Plataforma IZZA e nesta Declaração de Privacidade, respeitando o período de retenção de dados determinado pela legislação aplicável.</p>
  <p>Caso você solicite a exclusão de sua conta, as suas informações pessoais fornecidas ao IZZA durante a sua utilização dos nossos serviços serão excluídos, salvo para as finalidades permitidas pela legislação de proteção de dados.</p>
  <p>Em alguns casos, poderemos reter suas informações mesmo que você exclua sua conta, tais como nas hipóteses de guarda obrigatória de registros previstas em leis aplicáveis, se houver uma questão não resolvida relacionada à sua conta (como, por exemplo, uma reclamação ou disputa não resolvida), bem como para o exercício regular de nossos direitos, ou ainda caso seja necessário para nossos interesses legítimos, como prevenção de fraudes e aprimoramento da segurança dos nossos usuários.</p>
  <p>7. Como os dados são compartilhados?</p>
  <p>7.1 Provedores de serviços</p>
  <p>Por razões de processamento de dados em nome da plataforma, poderemos compartilhar dados para terceiros prestadores de serviços. Nesse caso, os dados serão tratados de forma a proteger a sua privacidade, tendo essas empresas o dever contratual de proteção compatível com a legislação aplicável e com os termos deste Declaração de Privacidade da Plataforma IZZA.</p>
  <p>Nós podemos utilizar os serviços dessas empresas para facilitar nosso serviço, prover o serviço da plataforma em nosso nome e executar atividades relacionadas ao serviço, incluindo: manutenção dos serviços; gerenciamento de banco de dados, sistemas de computadores e provedores de armazenamento em nuvem; central de atendimento ao cliente; agências de publicidade.</p>
  <p>Em relação ao pagamento de suas compras em nossa Plataforma, caso opte por pagar diretamente online, nós poderemos compartilhar seus dados de pagamento com empresas processadores de pagamento com os quais trabalhamos. Esse compartilhamento é feito com o fim exclusivo de viabilizar a compra efetivada por você.</p>
  <p>Essas empresas ou indivíduos têm acesso à sua informação pessoal, restrito ao necessário, apenas para executar atividades solicitadas pela plataforma e são obrigados a não usar ou divulgar tais informações para nenhuma outra finalidade.</p>
  <p>7.2 Fornecedores parceiros da Plataforma IZZA</p>
  <p>Ao fazer um pedido em um fornecedor parceiro por nossa Plataforma, nós podemos compartilhar algumas informações suas com esse fornecedor parceiro, bem como fornecer dados a respeito do pedido em específico. Os dados compartilhados podem incluir nome/razão social, endereço de entrega e CPF/CNPJ.</p>
  <p>Caso você inicie um chat com o estabelecimento durante a entrega, compartilharemos os mesmos dados citados acima além do conteúdo da mensagem escrita por você.</p>
  <p>7.3 Em alteração de controle societário da Plataforma IZZA</p>
  <p>No caso de operações societárias, como reestruturação, fusão ou venda de ativos da Plataforma IZZA, do grupo econômico ou de parte dele, seus dados poderão ser transferidos, desde que respeitados os termos da presente Declaração.</p>
  <p>7.4	 Publicidade e Serviços de análise</p>
  <p>Para entregarmos publicidade e promoções adequadas para você, bem como aperfeiçoar nossos serviços, nós também podemos compartilhar os seus dados anonimizados com empresas especializadas em marketing e análise de dados digitais que ofereçam nível de proteção de dados compatível com esta Declaração de Privacidade.</p>
  <p>7.5	 Autoridades públicas</p>
  <p>A Plataforma IZZA também poderá compartilhar suas informações com autoridades policiais ou judiciais, autoridades públicas competentes ou outros terceiros, dentro e fora do país em que você reside, caso seja requerido pela legislação aplicável, por decisão judicial e por requisição de autoridades, ou se necessário para responder a processos judiciais ou para participar em eventuais litígios ou disputas de qualquer natureza.</p>
  <p>Nestas situações, a Plataforma IZZA cooperará com as autoridades competentes na medida do estipulado por lei.</p>
  <p>Além disso, a Plataforma IZZA reserva a si a prerrogativa de compartilhar informações sobre seus usuários a terceiros quando houver motivos suficientes para considerar que a atividade de um usuário é suspeita, ilegal ou prejudicial a plataforma ou a terceiros.</p>
  <p>Essa prerrogativa será utilizada pela Plataforma IZZA quando considerar apropriado ou necessário para manter a integridade e a segurança do seu serviço, para o cumprimento dos seus Termos e Condições de Uso, para o exercício regular de seus direitos e com o intuito de cooperar com a execução e o cumprimento da lei, independentemente de existir ou não uma ordem judicial ou administrativa para tanto.</p>
  <p>8. Como protegemos seus dados?</p>
  <p>A Plataforma IZZA adota medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.</p>
  <p>Utilizamos os princípios estipulados por lei, respeitando a sua privacidade e protegendo seus dados em nossos processos internos como um todo.</p>
  <p>Só tratamos os seus dados mediante alto grau de segurança, implementando as melhores práticas em uso na indústria para a proteção de dados, tais como técnicas de criptografia, monitoramento e testes de segurança periódicos.</p>
  <p>9. Como iremos notificá-lo em caso de mudanças à essa Declaração?</p>
  <p>A Plataforma IZZA poderá atualizar essa Declaração de Privacidade periodicamente, sendo que a versão em vigor será sempre a mais recente.</p>
  <p>Se fizermos alguma alteração na Declaração em termos materiais, colocaremos um aviso no nosso Website ou te enviar um e-mail, juntamente com a Declaração Privacidade atualizada.</p>
  <p>Para verificar a data da versão em vigor, verifique a “Data de atualização” no início desse documento.</p>
  <p>10. Como exercer seus direitos enquanto titular de dados pessoais?</p>
  <p>A fim de exercer seus direitos enquanto titular de dados pessoais, a Plataforma IZZA disponibiliza meios específicos para as requisições através da aba “Ajuda” em nosso Website.</p>
  <p>Se você tiver algum questionamento ou dúvida com relação a esta Declaração de Privacidade da Plataforma IZZA ou qualquer prática aqui descrita, você também poderá entrar em contato conosco através da nossa página de Ajuda no Website</p>
  
  <h2>DICAS DE SEGURANÇA</h2>
  <p>Para USUÁRIOS</p>
  <p>Sempre dê preferência a dispositivos e redes de confiança:
    Cuidado com aparelhos e redes de internet públicas ou compartilhadas, pois aumenta a exposição das suas informações;
    Mantenha seu dispositivo atualizado: Quanto mais recente for a sua atualização, mais seguro o seu aparelho está. A falta de atualização abre brechas de segurança que podem ser utilizadas por pessoas mal intencionadas;
    Verifique a url: ao fazer seu pedido na Plataforma IZZA, cuidado com páginas falsas com URLs semelhantes.. Confira se a URL se inicia com “izzaweb.com.br” e se é criptografada (identificada pela presença do ‘S’ no ‘HTTP’, o https://).
    Cheque os produtos no recebimento: Cheque se os produtos pedidos estão em conformidade com o que foi solicitado, bem como as condições de entrega e qualidade requerida, caso verifique irregularidade total ou parcial, devolva o que não está regular e notifique a plataforma para acompanhar a solução.
    Atenção com e-mail e SMS falso - Verifique se o remetente do email é @izzaweb.com.br antes de responder algum contato.
    Nos avise: se seu pedido não chegou ou se chegou com alguma irregularidade ou alteração para ajudar a entender como resolver.
  </p>

  <p>Porto Alegre, 01 de novembro de 2022.</p>

  <div>
    <label class="custom-checkbox-container">
      <input type="checkbox" id="accept" name="accept" [(ngModel)]="accept">
      <label for="active" class="text-primary">Declaro que aceito os termos de uso e politicas de privacidade da plataforma IZZA.</label>
      <span class="checkmark"></span>
    </label>
  </div>
  <div >
    <div class="btn text-white secondary bg-secondary mt-2" [class.disabled]="!accept" (click)="confirm()">Prosseguir</div>
  </div>
</div>