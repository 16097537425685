import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  user: any = {};
  callback:any = false;
  loading = false;

  constructor(private service: UserService,
              private router: Router) { }

  ngOnInit() {
  }

  recoveryPassword(user) {
    this.loading = true;

    this.service.recoveryPassword(user.email).subscribe((data) => {
      this.router.navigate(['user', 'signin']);
    });
  }
}