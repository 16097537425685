import { Query } from "src/app/shared/util/query";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoadingService } from "./loading.service";

import { environment } from "../../../environments/environment";
import { of, BehaviorSubject } from "rxjs";
import { tap, catchError, filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  API = environment.url;
  TOKEN = localStorage.getItem(environment.token);
  company = JSON.parse(localStorage.getItem("company")) || {};
  observer = new BehaviorSubject(this.company);

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) {}

  list(q: Query = new Query()) {
    return this.http.get(`${this.API}/company${q.get()}`).pipe(
      tap((companies: any) => {
        const c = companies?.data?.filter((c) => c._id == this.company._id);

        this.company.orderNotes = c.orderNotes;
        this.set(this.company);
      })
    );
  }

  get(id) {
    return this.http.get(`${this.API}/company/${id}`);
  }

  getReport(id, q: Query = new Query()) {
    return this.http.get(`${this.API}/company/${id}/report${q.get()}`);
  }

  set(company = {}) {
    localStorage.setItem("company", JSON.stringify(company));
    this.company = company;
    this.observer.next(company);
  }

  getCompany() {
    if (this.company) return of(this.company);
    else return of(false);
  }

  watchCompany() {
    return this.observer;
  }

  save(company) {
    if (company._id)
      return this.http.put(`${this.API}/company/${company._id}`, company);
    return this.http.post(`${this.API}/company`, company);
  }

  delete(id) {
    return this.http.delete(`${this.API}/company/${id}`);
  }

  upload(id, file) {
    const fd = new FormData();
    fd.append("image", file);
    return this.http.post(`${this.API}/company/${id}/upload/`, fd);
  }

  addManager(companyId, manager) {
    return this.http.put(`${this.API}/company/${companyId}/manager`, manager);
  }

  removeManager(companyId, managerId) {
    return this.http.delete(
      `${this.API}/company/${companyId}/manager/${managerId}`
    );
  }

  createReceipt(id, payload) {
    return this.http.post(
      `${this.API}/company/${id}/conta-azul`,
      payload
    );
  }
}
