<search-box [title]="'Dashboard'"></search-box>
<div class="row mb-3">
  <div class="col-sm item-wrapper bg-light">
    <p class="weight-medium">Número de pedidos</p>
    <p class="highlight color-gradient">65</p>
  </div>
  <div class="col-sm item-wrapper bg-light">
    <p class="weight-medium">Escolas cadastradas</p>
    <p class="highlight color-gradient">5</p>
  </div>
  <div class="col-sm item-wrapper bg-light">
    <p class="weight-medium">Fornecedores cadastrados</p>
    <p class="highlight color-gradient">12</p>
  </div>
  <div class="col-sm item-wrapper bg-light">
    <p class="weight-medium">Produtos cadastrados</p>
    <p class="highlight color-gradient">77</p>
  </div>
</div>
<div class="row mb-3">
  <div class="col-md-12 d-flex inline-flex justify-content-between align-items-center">
    <h1>Faturamento</h1>
    <div>
      <img src="/assets/icons/arrow-down.svg" class="pointer">
    </div>
  </div>
  <div class="col item-wrapper chart">
    <p class="weight-medium">R$1280.39</p>
  </div>
</div>