import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  me: any;
  navbarOpen = false;
  dropdownOpen = false;
  company: any;
  companies: any = [];
  schoolsCollapsed = false;
  cart;

  constructor(private service: UserService,
              private orderService: OrderService,
              private companyService: CompanyService,
              private router: Router) { }

  ngOnInit() {
    this.orderService.watchCart().subscribe( cart => this.cart = cart );
    this.companyService.watchCompany().subscribe(company => this.company = company);
    this.service.me().subscribe((me) => this.me = me);
    this.getCompanies();
  }

  toggleNavbar() {
    if (window.innerWidth <= 990) this.navbarOpen = !this.navbarOpen;
  }
  toggleSchools() {
    if (window.innerWidth <= 990) this.schoolsCollapsed = !this.schoolsCollapsed;
  }

  getCompanies() {
    this.companyService.list().subscribe( (companies: any) => {
      if (companies?.data?.length === 1) {
        this.companyService.set(companies.data[0]);
      }
      this.companies = companies;
    });
  }

  select(school) {
    this.companyService.set(school);
  }

  logout() {
    this.service.showMenu.emit(false);
    this.service.logout();
  }
}
