import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CompanyService } from '../services/company.service';

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {
  private companyId = undefined;

  constructor(service: CompanyService) {
    service.watchCompany().subscribe( company => {
      this.companyId = company._id;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.companyId) {
      request = request.clone({
        setHeaders: {
          'X-Client-ID': `${this.companyId}`
        }
      });
    }

    return next.handle(request);
  }
}
