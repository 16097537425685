export default [
  {
    label: "Transferência / Pix",
    key: "transfer",
  },
  {
    label: "Cartão de crédito / Débito",
    key: "credit_card",
  },
  {
    label: "Dinheiro",
    key: "money",
  },
  {
    label: "Boleto",
    key: "payment_slip",
  },
  {
    label: "Pagamento online",
    key: "link",
  },
];
