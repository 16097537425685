<div class="container">
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <img src="assets/images/logo-izza-01.png" width="180px"/>
      </div>
      <h4>FATURA UNIFICADA</h4>
      <div class="text-right">
        <h2>{{ company?.name }}</h2>
        <div *ngIf="company?.type === 'pj'">{{ company?.companyName }}</div>
        <div>
          <span *ngIf="company?.type === 'pj'">CNPJ: {{ company?.cnpj | mask: "00.000.000/0000-00" }}</span>
          <span *ngIf="company?.type !== 'pj'">CPF: {{ company?.cpf | mask: "000.000.000-00" }}</span>
        </div>
        <div *ngIf="company?.email">E-mail: {{ company?.email }}</div>
        <div *ngIf="company?.phone">
          Telefone: {{ company?.phone | mask: "(00) 000000000" }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-7">
      <div class="d-flex justify-content-between line">
        <span>PEDIDOS NO PERÍODO</span>
        <span>{{ startDate | date: "dd/MM/yyyy" }} à {{ endDate | date: "dd/MM/yyyy" }}</span>
      </div>

      <table class="table table-bordered table-sm">
        <thead>
          <th class="text-center">Número</th>
          <th class="text-center">Fornecedor</th>
          <th class="text-center">NF / Pedido</th>
          <th class="text-center">Entrega</th>
          <th class="text-right">Valor</th>
        </thead>

        <tr *ngFor="let order of orders">
          <td class="text-center">{{ order.number }}</td>
          <td>{{ order.provider_name }}</td>
          <td class="text-center">{{ order.nfe }}</td>
          <td class="text-center">{{ order.date | date: "dd/MM/yyyy" }}</td>
          <td class="text-right">{{ order.nfeTotal || order.total | currency: "R$ " }}</td>
        </tr>

        <tr class="totals">
          <td colspan="3" class="text-right">
            <h4 class="m-0">Total</h4>
          </td>
          <td class="text-center">
            <h4 class="m-0">{{ totals?.count }}</h4>
          </td>
          <td class="text-right">
            <h4 class="m-0">{{ totals?.sum | currency: "R$ " }}</h4>
          </td>
        </tr>
      </table>
    </div>
    <div class="row col-5 align-content-start pr-0">
      <div class="col-12 pr-0 economizza">
        <div class="line bg-secondary title mb-0">
          <span class="ml-auto">econom</span><span class="text-green">izza</span>
        </div>
        <div class="line content mt-0"
          [class.bg-highlight]="totals?.economizza > 0"
          [class.bg-light]="!totals?.economizza"
        >
          <div *ngIf="!totals?.economizza">
            Não economizou
          </div>
          <div *ngIf="totals?.economizza > 0">
            <div>Usou e economizou</div>
            <div class="value">
              {{ totals?.economizza | currency: "R$ " }}
            </div>
            <div>
              {{ (totals?.economizza / (totals?.sum + totals?.economizza) * 100).toFixed(0) }}%
              de economia</div>
            </div>
          </div>
      </div>

      <div class="col-12 pr-0">
        <div class="line text-right">
          <span>CONSOLIDADO POR SEGMENTO</span>
        </div>
      
        <table class="table table-bordered table-sm">
          <thead>
            <th>Segmento</th>
            <th class="text-center">Pedidos</th>
            <th class="text-right">Total</th>
          </thead>
      
          <tr *ngFor="let segment of segments">
            <td>
              {{ segment._id.category }} - {{ segment._id.segment }}
            </td>
            <td class="text-center">{{ segment.orders }}</td>
            <td class="text-right">{{ segment?.total | currency: "R$ " }}</td>
          </tr>
        </table>
      </div>
      <div class="col-12 pr-0">
        <div class="line text-right">
          <span>CONSOLIDADO POR FORNECEDOR</span>
        </div>
  
        <table class="table table-bordered table-sm">
          <thead>
            <th>Fornecedor</th>
            <th>CNPJ</th>
            <th class="text-center">Pedidos</th>
            <th class="text-right">Total</th>
          </thead>
  
          <ng-container *ngFor="let provider of providers">
            <tr>
              <td>{{ provider.name }}</td>
              <td>{{ provider.cnpj | mask: "00.000.000/0000-00" }}</td>
              <td class="text-center">{{ provider?.orders }}</td>
              <td class="text-right">{{ provider?.total | currency: "R$ " }}</td>
            </tr>
            <tr>
              <td colspan="4">{{ provider.company }}</td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
  </div>

  <div class="row mt-3 pointer" (click)="setConfig()">
    <div class="col-12" >
      <div class="line m-0">
        OBSERVAÇÕES
      </div>
      <div class="line text-primary bg-gray m-0">
        <h6>{{generalComments}}</h6>
        <h6>{{clientComments}}</h6>
      </div>
    </div>
  </div>

  <div class="row mt-3 pointer" (click)="setConfig()">
    <div class="col-6">
      <div class="line m-0">
        DATA DE VENCIMENTO
      </div>
      <div class="line text-primary bg-gray m-0">
        <h6>{{ expiration | date: "dd/MM/yyyy" }}</h6>
      </div>
    </div>
    <div class="col-6">
      <div class="line m-0">
        FORMA DE PAGAMENTO
      </div>
      <div class="line text-primary bg-gray m-0">
        <h6>{{ paymentMethod.label }}</h6>
      </div>
    </div>
  </div>

  <div class="instructions mt-3">
    Os pedidos constantes nesta fatura, foram solicitados através de login individual efetuado na Plataforma de Marketplace www.izzaweb.com.br, através de aceite dos "Termos e Condições" de uso e entregues no estabelecimento.<br />
    Qualquer divergência nos valores cobrados, entrar em contato antes do vencimento para que possamos apurar e corrigir possíveis diferenças.<br />
    O não pagamento até 3 (três) dias após o vencimento implicará no bloqueio de acesso e assim permanecendo 5(cinco) dias após o vencimento, será efetuada a inclusão junto ao banco de dados de empresas de análise e proteção ao crédito.
  </div>
</div>


<button class="btn button secondary text-white p-4 fixed d-print-none" (click)="print()">
  Imprimir
</button> 

<button [disabled]="loadingReceipt" class="btn button secondary text-white p-4 fixed d-print-none" (click)="createReceipt()">
  <ng-container *ngIf="!loadingReceipt">Enviar ao Conta Azul</ng-container>
  <span *ngIf="loadingReceipt" class="spinner"></span>
</button> 