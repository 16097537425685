import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Query } from 'src/app/shared/util/query';
import { UserService } from 'src/app/shared/services/user.service';
import { ExportService } from 'src/app/shared/services/export.service';
import { Router } from '@angular/router';

import { ConfirmWindowComponent } from 'src/app/shared/ui/confirm-window/confirm-window.component';

import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit {
  schools: any = [];
  query: Query = new Query();
  loadings = { schools: false };
  modalRef;
  me;

  constructor(private service: CompanyService,
              private userService: UserService,
              private exportService: ExportService,
              private modalService: BsModalService,
              private router: Router
  ) { }

  ngOnInit() {
    this.query.limit = 20;
    this.list();

    this.userService.me().subscribe( me => this.me = me );
  }

  buy(company, e) {
    e.preventDefault()
    this.service.set(company)

    this.router.navigateByUrl('/home')
  }

  list() {
    this.loadings.schools = true;
    this.service.list(this.query).subscribe((schools: any) => {
      this.schools = schools;
      this.loadings.schools = false;
    });
  }

  export() {
    this.exportService.getAndExport('Clientes', 'report/company')
  }

  deleteSchool(id) {
    event.preventDefault();
    event.stopPropagation();

    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        onYes: () => 
          this.service.delete(id).subscribe(() => {
            this.schools.data = this.schools.data.filter(p => p._id !== id);
          })
      }
    });
    
  }
}
