<search-box
  [title]="'Clientes'"
  (textChange)="query.search.filter = $event; list()"
  link="./new"
  [search]="true">
</search-box>

<div class="row mb-3" *ngIf="me?.admin">
  <div class="col-12 col-md-11 mx-0">
  </div>
  <div *ngIf="me?.admin" class="col-12 col-md-1 mx-0 mt-2 pointer text-right" (click)="export()">
    <a>Exportar</a>
  </div>
</div>
  

<div class="row mb-3">
  <table class="table">
      <tr>
        <th class="weight-medium">Nome</th>
        <th class="weight-medium">Cidade</th>
        <th class="weight-medium">Último Acesso</th>
        <th class="weight-medium"></th>
      </tr>
      <tr *ngFor="let school of schools.data" class="pointer" [routerLink]="[school._id, 'edit']">
        <td class="weight-medium">{{ school.name }}</td>
        <td>{{ school?.address?.city }}, {{ school?.address?.state }}</td>
        <td>{{ school.lastAccess | date:'dd/MM/yyyy HH:mm' }}</td>
        <td class="text-right">
          <small class="bg-green rounded px-2 py-1 text-white text-nowrap mx-2" (click)="buy(school, $event)">+ Pedido</small>
        
          <img src="/assets/icons/cancel.svg" (click)="deleteSchool(school._id)">
        </td>
      </tr>
    </table>
</div>

<div class="d-flex align-items-center text-center">
  <pagination
    class="ml-auto"
    [totalItems]="schools?.total"
    [itemsPerPage]="query.limit"
    (pageChanged)="query.page = $event.page; list()"
    [boundaryLinks]="true"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
    [rotate]="true"
    [maxSize]="5">
  </pagination>
</div>