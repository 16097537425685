<div class="row p-3">
  <div class="col-12 d-flex align-items-center justify-content-between">
    <h2 class="mb-0">Sacola</h2>

    <div
      class="d-flex pointer"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <p class="m-0 mr-2">
        {{ order?.offers?.length }} Ite{{
          order?.offers?.length > 1 ? "ns" : "m"
        }}
      </p>
      <div
        *ngIf="isCollapsed"
        [inlineSVG]="'/assets/icons/arrow-down.svg'"
      ></div>
      <div
        *ngIf="!isCollapsed"
        [inlineSVG]="'/assets/icons/arrow-up.svg'"
      ></div>
    </div>
  </div>
  <div class="col-12">
    <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
      <ng-container *ngFor="let offer of order?.offers">
        <div
          class="row item-wrapper m-0 px-0 py-3 border-bottom"
          *ngIf="offer.amount > 0"
        >
          <div class="col-md-6 d-flex align-items-center mb-2 px-md-0">
            <img
              class="img-lg"
              [src]="
                offer.offer?.product?.mainImage || '/assets/icons/avatar.svg'
              "
            />
            <h6 class="ml-2 mb-0 mt-1 text-left">
              <div>{{ offer.offer?.product.name }}</div>
              <small>{{ offer.offer?.product.brand }}</small>
            </h6>
          </div>
          <div class="col-md-6 p-0">
            <div
              class="d-flex justify-content-between align-items-center px-2 px-md-0"
            >
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <small class="mr-2 weight-medium">Preço unitário</small>
                  <small class="text-left text-green weight-medium">
                    {{ getPrice(offer, true) | currency: "BRL" }}
                  </small>
                </div>
                <div class="d-flex align-items-center">
                  <small class="mr-2 weight-medium">Quantidade</small>
                  <small class="text-left text-green weight-medium">
                    {{ offer.amount }}
                    {{ offer.selectedUnit?.label || offer.unit?.label || "un" }}
                  </small>
                </div>
              </div>

              <div class="d-flex align-items-center mb-auto mb-md-0">
                <span class="text-left highlight">
                  {{ getPrice(offer) | currency: "BRL" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="mt-2 text-right">
      Total: {{ getTotal(order) | currency: "BRL" }}
    </div>
  </div>
</div>

<div class="row mt-0 p-3">
  <div class="col-12 col-md-6">
    <div
      [routerLink]="['/provider', order?.provider?._id, 'edit']"
      class="item-wrapper pointer bg-secondary p-3 mb-2 m-0 d-flex justify-content-between align-items-center"
    >
      <span class="weight-medium text-white">Fornecedor: </span>
      <div class="d-flex align-items-center">
        <img
          *ngIf="order?.provider?.logo"
          [src]="order?.provider?.logo"
          class="img-lg rounded-circle mr-2"
        />
        <h5 class="m-0 text-white">{{ order?.provider?.name }}</h5>
      </div>
    </div>
    <div
      class="item-wrapper pointer bg-secondary p-3 m-0 mb-2 d-flex justify-content-between align-items-center"
    >
      <span class="weight-medium text-white">Total faturado: </span>
      <div class="d-flex align-items-center">
        <div
          class="icon-primary mr-2 pointer"
          (click)="openModal()"
          [inlineSVG]="'/assets/icons/edit.svg'"
          *ngIf="me?.admin"
        ></div>
        <span class="text-highlight highlight">{{
          order?.nfeTotal || order?.total | currency: "BRL"
        }}</span>
      </div>
    </div>
    <div
      class="item-wrapper pointer bg-secondary p-3 m-0 mb-2 d-flex justify-content-between align-items-center"
    >
      <span class="weight-medium text-white">Status: </span>
      <div class="d-flex align-items-center">
        <div
          class="icon-primary mr-2 pointer"
          (click)="openModal()"
          [inlineSVG]="'/assets/icons/edit.svg'"
          *ngIf="me?.admin"
        ></div>
        <h5 class="m-0 text-white">{{ status[order.status] }}</h5>
      </div>
    </div>
    <div
      class="item-wrapper pointer bg-secondary p-3 m-0 mb-2 d-flex justify-content-between align-items-center"
      *ngIf="order?.payment === 'credit'"
    >
      <span class="weight-medium text-white">Status do pagamento: </span>
      <div class="d-flex align-items-center">
        <div
          class="icon-primary mr-2 pointer"
          (click)="openModal()"
          [inlineSVG]="'/assets/icons/edit.svg'"
          *ngIf="me?.admin"
        ></div>
        <h5 class="m-0 text-white">{{ paymentStatus[order.paymentStatus] }}</h5>
      </div>
    </div>
    <div
      *ngIf="me.admin"
      [routerLink]="['/client', order?.client?._id, 'edit']"
      class="item-wrapper pointer bg-secondary p-3 m-0 mb-2 d-flex justify-content-between align-items-center"
    >
      <span class="weight-medium text-white">Cliente: </span>
      <div class="d-flex align-items-center">
        <img
          *ngIf="order?.client?.logo"
          [src]="order?.client?.logo"
          class="img-lg rounded-circle mr-2"
        />
        <h5 class="m-0 text-white">{{ order?.client?.name }}</h5>
      </div>
    </div>
    <div *ngIf="order?.reason">
      <strong>Observação</strong>
      <div>{{ order?.reason }}</div>
    </div>
  </div>

  <div class="col-12 col-md-6 mb-2 p-0">
    <div class="col-12 mb-3">
      <div
        class="item-wrapper date-wrapper m-0 mb-2 p-3 bg-light d-flex align-items-center justify-content-between"
      >
        <p class="m-0 weight-medium">Data de entrega:</p>
        <div class="d-flex align-items-center">
          <span class="ml-1">{{ order?.date | date: "dd/MM/yyyy" }}</span>
          <div
            class="icon-primary mr-auto ml-2 pointer"
            (click)="openModal()"
            [inlineSVG]="'/assets/icons/edit.svg'"
            *ngIf="me?.admin"
          ></div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div
        class="item-wrapper date-wrapper m-0 mb-2 p-3 bg-light d-flex align-items-center justify-content-between"
      >
        <p class="m-0 weight-medium">Nota Fiscal:</p>
        <div class="d-flex align-items-center">
          <span class="ml-1">
            <span *ngIf="order?.nfe"
              >{{ order?.nfe }} -
              {{ order?.nfeDate | date: "dd/MM/yyyy" }}</span
            >
            <span *ngIf="!order?.nfe">Não Informado</span>
          </span>
          <div
            class="icon-primary mr-auto ml-2 pointer"
            (click)="openModal()"
            [inlineSVG]="'/assets/icons/edit.svg'"
            *ngIf="me?.admin"
          ></div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div
        class="item-wrapper date-wrapper m-0 mb-2 p-3 bg-light d-flex align-items-center justify-content-between"
      >
        <p class="m-0 weight-medium">Forma de pagamento:</p>
        <span class="ml-1">{{ payment() }}</span>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div
        class="item-wrapper date-wrapper m-0 mb-2 p-3 bg-light d-flex align-items-center justify-content-between"
      >
        <p class="m-0 weight-medium">Pedido:</p>
        <span class="ml-1"
          >{{ order.number }} -
          {{ order?.createdAt | date: "dd/MM/yyyy" }}</span
        >
      </div>
    </div>
    <div class="col-12 mb-3">
      <div
        class="item-wrapper date-wrapper m-0 mb-2 p-3 bg-light d-flex align-items-center justify-content-between"
      >
        <div
          *ngIf="me?.admin"
          class="d-flex align-items-center pointer"
          (click)="notify()"
        >
          Notificar fornecedor
          <span class="spinner" *ngIf="loading">&nbsp;</span>
        </div>
        <div class="d-flex align-items-center pointer" (click)="open(order)">
          Imprimir
          <span class="ml-1 mt-1" inlineSVG="/assets/icons/print.svg"></span>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3" (click)="repeat()">
      <div
        class="pointer bg-secondary item-wrapper date-wrapper m-0 mb-2 p-3 align-items-center text-white"
      >
        <p class="m-0 weight-medium">
          <img src="/assets/images/logo.png" height="20" />
          &nbsp;Repetir Compra
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row px-3 mt-3" *ngIf="order.comment">
  <div class="col-12 mb-5 mb-md-0 prewrap">
    <h2>Comentários</h2>
    {{ order.comment }}
  </div>
</div>
