<div id="stepper1" class="bs-stepper">
  <header class="text-center">
    <div class="container mb-2">
      <div class="steps-wrapper row no-margin-sm">
        <div class="col-12">
          <h4>{{ routeId ? "Editar" : "Cadastrar" }}</h4>
        </div>
        <div class="col-12 col-md-6 offset-md-3 mb-4 mt-3">
          <p class="weight-medium mb-4">{{ stepper.title }}</p>
          <div class="bs-stepper-header" role="tablist">
            <div class="step" data-target="#school">
              <button
                class="step-trigger"
                type="button"
                role="tab"
                (click)="stepper.to(0)"
              >
                <span class="bs-stepper-circle"></span>
                <span class="bs-stepper-label">Sobre</span>
                <span class="bs-stepper-desc">Perfil do cliente</span>
              </button>
            </div>
            <div class="line"></div>
            <!-- [class.bg-primary]="stepper.step > 0" -->
            <div class="step" data-target="#delivery">
              <button
                class="step-trigger"
                type="button"
                role="tab"
                (click)="stepper.to(1)"
              >
                <span class="bs-stepper-circle"></span
                ><!-- [class.bg-primary]="stepper.step >= 1"-->
                <span class="bs-stepper-label">Endereço</span>
                <span class="bs-stepper-desc">Defina o local de entrega</span>
              </button>
            </div>

            <div class="line" [ngClass]="{ hide: !me?.admin }"></div>
            <div
              class="step"
              data-target="#accountable"
              [ngClass]="{ hide: !me?.admin }"
            >
              <button
                class="step-trigger"
                type="button"
                role="tab"
                (click)="stepper.to(2)"
              >
                <span class="bs-stepper-circle"></span>
                <span class="bs-stepper-label">Responsáveis</span>
                <span class="bs-stepper-desc">Informe os responsáveis</span>
              </button>
            </div>

            <!-- <div class="line" [ngClass]="{ hide: !me?.admin }"></div>
            <div
              class="step"
              data-target="#settings"
              [ngClass]="{ hide: !me?.admin }"
            >
              <button
                class="step-trigger"
                type="button"
                role="tab"
                (click)="stepper.to(3)"
              >
                <span class="bs-stepper-circle"></span>
                <span class="bs-stepper-label">Fornecedores</span>
                <span class="bs-stepper-desc">Bloquear fornecedores</span>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="container">
    <div
      class="step-back mb-4 pl-md-3 pointer"
      *ngIf="stepper.step > 0"
      (click)="stepper.previous()"
    >
      <div
        [inlineSVG]="'/assets/icons/arrow-left.svg'"
        class="d-flex icon-secondary mr-1"
      ></div>
      Voltar
    </div>

    <div class="bs-stepper-content">
      <form #schoolForm="ngForm">
        <div id="school" class="content" data-step="0">
          <div class="row mb-3 mt-3 mt-md-0">
            <div class="col-12 col-md-6 mb-3" *ngIf="me?.admin">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-3">
                  <input
                    type="radio"
                    [(ngModel)]="company.type"
                    name="type"
                    value="pf"
                    id="physical"
                    (change)="isPhysical = $event.target.checked"
                  />
                  <label for="physical">Pessoa física</label>
                </div>
                <div class="d-flex align-items-center">
                  <input
                    type="radio"
                    [(ngModel)]="company.type"
                    name="type"
                    value="pj"
                    id="juridical"
                    (change)="isPhysical = !$event.target.checked"
                  />
                  <label for="juridical">Pessoa jurídica</label>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-3 mb-3" *ngIf="me?.admin">
              <label class="custom-checkbox-container">
                <input
                  type="checkbox"
                  id="bestOrder"
                  name="bestOrder"
                  [(ngModel)]="company.bestOrder"
                />
                <label for="bestOrder" class="text-primary">Melhor compra</label>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-6 col-md-3 mb-3" *ngIf="me?.admin">
              <label class="custom-checkbox-container">
                <input
                  type="checkbox"
                  id="prospect"
                  name="prospect"
                  [(ngModel)]="company.prospect"
                  (change)="company.remove_old_orders = true"
                />
                <label for="prospect" class="text-primary">Em prospecção</label>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-md-6 mb-3">
              <label
                for="name"
                [class.error]="name.invalid && (name.dirty || name.touched)"
              >
                Nome *
              </label>
              <input
                [class.error]="name.invalid && (name.dirty || name.touched)"
                type="text"
                class="form-control"
                id="name"
                name="name"
                [(ngModel)]="company.name"
                #name="ngModel"
                required
                autocomplete="off"
              />
              <div
                *ngIf="name.invalid && (name.dirty || name.touched)"
                class="error"
              >
                <div *ngIf="name.errors.required">Este campo é obrigatório</div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-3" *ngIf="!isPhysical">
              <label for="category"> Tipo </label>
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-3">
                  <input
                    type="radio"
                    [(ngModel)]="company.category"
                    name="category"
                    value="escola"
                    id="escola"
                  />
                  <label for="escola">Escola</label>
                </div>
                <div class="d-flex align-items-center mr-3">
                  <input
                    type="radio"
                    [(ngModel)]="company.category"
                    name="category"
                    value="geriatria"
                    id="geriatria"
                  />
                  <label for="geriatria">Geriatria</label>
                </div>
                <div class="d-flex align-items-center">
                  <input
                    type="radio"
                    [(ngModel)]="company.category"
                    name="category"
                    value="outros"
                    id="outros"
                  />
                  <label for="outros">Outros</label>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3" *ngIf="!isPhysical">
              <label
                for="cnpj"
                [class.error]="cnpj.invalid && (cnpj.dirty || cnpj.touched)"
              >
                CNPJ *
              </label>
              <input
                type="text"
                [class.error]="cnpj.invalid && (cnpj.dirty || cnpj.touched)"
                class="form-control"
                id="cnpj"
                name="cnpj"
                [(ngModel)]="company.cnpj"
                #cnpj="ngModel"
                required
                mask="00.000.000/0000-00"
              />
              <div
                *ngIf="cnpj.invalid && (cnpj.dirty || cnpj.touched)"
                class="error"
              >
                <div *ngIf="cnpj.errors.required">Este campo é obrigatório</div>
                <div *ngIf="cnpj.errors.mask">O CPNJ deve ter 14 dígitos</div>
                <div *ngIf="cnpj.errors.duplicate">CNPJ já cadastrado</div>
              </div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="isPhysical">
              <label
                for="cpf"
                [class.error]="cpf.invalid && (cpf.dirty || cpf.touched)"
              >
                CPF *
              </label>
              <input
                type="text"
                [class.error]="cpf.invalid && (cpf.dirty || cpf.touched)"
                class="form-control"
                id="cpf"
                name="cpf"
                [(ngModel)]="company.cpf"
                #cpf="ngModel"
                required
                mask="000.000.000-00"
              />
              <div
                *ngIf="cpf.invalid && (cpf.dirty || cpf.touched)"
                class="error"
              >
                <div *ngIf="cpf.errors.required">Este campo é obrigatório</div>
                <div *ngIf="cpf.errors.mask">O CPF deve ter 11 dígitos</div>
                <div *ngIf="cpf.errors.duplicate">CPF já cadastrado</div>
              </div>
            </div>

            <div class="col-md-6 mb-3" *ngIf="!isPhysical">
              <label
                for="companyName"
                [class.error]="
                  companyName.invalid &&
                  (companyName.dirty || companyName.touched)
                "
              >
                Razão social *
              </label>
              <input
                type="text"
                class="form-control"
                id="companyName"
                name="companyName"
                [(ngModel)]="company.companyName"
                #companyName="ngModel"
                [class.error]="
                  companyName.invalid &&
                  (companyName.dirty || companyName.touched)
                "
                required
              />
              <div
                *ngIf="
                  companyName.invalid &&
                  (companyName.dirty || companyName.touched)
                "
                class="error"
              >
                <div *ngIf="companyName.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <label for="logo">
                {{ isPhysical ? "Foto" : "Logo" }}
              </label>
              <div class="row">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="selecione uma imagem..."
                    id="logo"
                    name="logo"
                    (click)="fileUpload.click()"
                  />
                </div>
                <div class="col-3 file-preview p-0">
                  <input
                    type="file"
                    name="file"
                    (change)="onFileSelect($event, 0)"
                    #fileUpload
                    required
                  />
                  <img src="{{ fileImage }}" *ngIf="fileImage" />
                  <div
                    class="remove p-0"
                    *ngIf="fileImage"
                    (click)="emptyFileSelect()"
                  >
                    <span>x</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3" *ngIf="isPhysical && me?.admin">
              <label for="state"> Indicação </label>
              <ng-select
                [items]="companies"
                bindValue="_id"
                bindLabel="name"
                name="reference"
                [(ngModel)]="company.reference"
              ></ng-select>
            </div>

            <div
              class="col-md-6 col-offset-6 mb-3"
              *ngIf="!isPhysical && company?.category === 'escola'"
            >
              <label for="students"> Número de alunos </label>
              <input
                type="text"
                class="form-control"
                id="students"
                name="students"
                [(ngModel)]="company.students"
                #students="ngModel"
                [required]="!isPhysical && company?.category === 'escola'"
                mask="000000"
                [validation]="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label for="phone" [class.error]="phone.invalid && phone.touched">
                  Telefone *
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  name="phone"
                  [(ngModel)]="company.phone"
                  #phone="ngModel"
                  required
                  mask="(00) 0000-00009"
                  [class.error]="phone.invalid && phone.dirty"
                  [class.error]="phone.invalid && phone.touched"
                />
                <div
                  *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                  class="error"
                >
                  <div *ngIf="phone.errors.required">
                    Este campo é obrigatório
                  </div>
                  <div *ngIf="phone.errors.mask">
                    Este campo deve conter 10 dígitos
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="whatsapp">Whats App</label>
                <input
                  type="text"
                  class="form-control"
                  id="whatsapp"
                  name="whatsapp"
                  [(ngModel)]="company.whatsapp"
                  mask="(00) 00000-0000"
                />
              </div>
              <div class="mb-3">
                <label for="email" [class.error]="email.invalid && email.touched">
                  E-mail *
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  [(ngModel)]="company.email"
                  #email="ngModel"
                  required
                  [class.error]="email.invalid && email.touched"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}"
                />
                <div
                  *ngIf="email.invalid && (email.dirty || email.touched)"
                  class="error"
                >
                  <div *ngIf="email.errors.required">
                    Este campo é obrigatório
                  </div>
                  <div *ngIf="email.errors.pattern">E-mail inválido</div>
                  <div *ngIf="email.errors.duplicate">E-mail já cadastrado</div>
                </div>
              </div>
              <div>
                <label for="agent">Representante </label>
                <input
                  type="text"
                  class="form-control"
                  id="agent"
                  name="agent"
                  [(ngModel)]="company.agent"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label>Notas Internas</label>
                <textarea name="about" class="form-control" rows="4" [(ngModel)]="company.about"></textarea>
              </div>
              <div class="mb-3">
                <label>Observação Pedido</label>
                <textarea name="orderNotes" class="form-control" rows="4" [(ngModel)]="company.orderNotes"></textarea>
              </div>
              <div>
                <label class="custom-checkbox-container">
                  <input
                    type="checkbox"
                    id="emitNF"
                    name="emitNF"
                    [(ngModel)]="company.emitNF"
                  />
                  <label for="emitNF" class="text-primary">Emitir NF</label>
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <ng-container *ngIf="company?.type === 'pj' && me?.admin">
              <div class="col-md-12 mt-3">
                <h2>Crédito</h2>
              </div>
              <div class="col-md-6 mb-3">
                <label for="email" [class.error]="email.invalid && email.touched">
                  Limite
                </label>
                <input type="text" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" name="credit" [(ngModel)]="company.limit"
                />
                <small class="text-medium weight-medium">
                  Em aberto: {{ company.creditBill | currency: "BRL" }}
                </small>

                <div class="mt-2 pointer" (click)="openReportModal();">Relatório de faturamento</div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="email" [class.error]="email.invalid && email.touched">
                  Forma de pagamento
                </label>
                <ng-select
                  class="w-100"
                  [clearable]="false"
                  name="paymentMethod"
                  [(ngModel)]="company.paymentMethod"
                >
                  <ng-option value="Fatura Izza Quinzena">
                    <span class="weight-medium text-medium">Fatura Izza Quinzena</span>
                  </ng-option>
                  <ng-option value="Fatura Izza Quinzena 15/30">
                    <span class="weight-medium text-medium">Fatura Izza Quinzena 15/30</span>
                  </ng-option>
                  <ng-option value="Fatura Izza Mensal">
                    <span class="weight-medium text-medium">Fatura Izza Mensal</span>
                  </ng-option>
                  <ng-option value="Fatura Izza Mensal Q1">
                    <span class="weight-medium text-medium">Fatura Izza Mensal Q1</span>
                  </ng-option>
                  <ng-option value="Pagamento a vista fornecedor">
                    <span class="weight-medium text-medium">Pagamento a vista fornecedor</span>
                  </ng-option>
                  <ng-option value="Pagamento a vista Izza">
                    <span class="weight-medium text-medium">Pagamento a vista Izza</span>
                  </ng-option>
                  <ng-option value="Boleto 7 dias fornecedor">
                    <span class="weight-medium text-medium">Boleto 7 dias fornecedor</span>
                  </ng-option>
                  <ng-option value="Boleto 14 dias fornecedor">
                    <span class="weight-medium text-medium">Boleto 14 dias fornecedor</span>
                  </ng-option>
                </ng-select>
              </div>
            </ng-container>
          </div>

          <div class="row" *ngIf="isPhysical && !me?.admin">
            <div class="col-12 my-3">
              <label for="state"> É Pessoa Jurídica? </label>
              <p>
                Efetue o seu cadastro como empresa e tenha condições especiais
                para compras através da plataforma Izza. Entre em contato
                conosco através do telefone / whatsapp
                <a href="https://wa.me/5551999999016" target="_blank"
                  >(51) 999.999.016</a
                >
              </p>
            </div>
          </div>
        </div>

        <div id="delivery" class="content" data-step="1">
          <div class="row">
            <div class="col-12">
              <h2>Endereço</h2>
            </div>
            <div class="col-md-6 mb-3">
              <label
                for="cep"
                [class.error]="cep.invalid && (cep.dirty || cep.touched)"
              >
                CEP *
              </label>
              <input
                type="text"
                class="form-control"
                id="cep"
                name="cep"
                [(ngModel)]="company.address.cep"
                #cep="ngModel"
                required
                mask="00000-000"
                [class.error]="cep.invalid && (cep.dirty || cep.touched)"
                (change)="getCep($event, company.address)"
              />
              <div
                *ngIf="cep.invalid && (cep.dirty || cep.touched)"
                class="error"
              >
                <div *ngIf="cep.errors.required">Este campo é obrigatório</div>
                <div *ngIf="cep.errors.mask">O CEP deve conter 8 dígitos</div>
              </div>
            </div>
            <div class="col-md-8 mb-3">
              <label
                for="street"
                [class.error]="
                  street.invalid && (street.dirty || street.touched)
                "
              >
                Rua / Logradouro *
              </label>
              <input
                type="text"
                class="form-control"
                id="street"
                name="street"
                [(ngModel)]="company.address.street"
                #street="ngModel"
                [class.error]="
                  street.invalid && (street.dirty || street.touched)
                "
                required
              />
              <div
                *ngIf="street.invalid && (street.dirty || street.touched)"
                class="error"
              >
                <div *ngIf="street.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label
                for="number"
                [class.error]="
                  number.invalid && (number.dirty || number.touched)
                "
              >
                Número*
              </label>
              <input
                type="number"
                class="form-control"
                id="number"
                name="number"
                [(ngModel)]="company.address.number"
                #number="ngModel"
                [class.error]="
                  number.invalid && (number.dirty || number.touched)
                "
                required
              />
              <div
                *ngIf="number.invalid && (number.dirty || number.touched)"
                class="error"
              >
                <div *ngIf="number.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label
                for="neighbourhood"
                [class.error]="
                  neighbourhood.invalid &&
                  (neighbourhood.dirty || neighbourhood.touched)
                "
              >
                Bairro *
              </label>
              <input
                type="text"
                class="form-control"
                id="neighbourhood"
                name="neighbourhood"
                [(ngModel)]="company.address.neighbourhood"
                #neighbourhood="ngModel"
                [class.error]="
                  neighbourhood.invalid &&
                  (neighbourhood.dirty || neighbourhood.touched)
                "
                required
              />
              <div
                *ngIf="
                  neighbourhood.invalid &&
                  (neighbourhood.dirty || neighbourhood.touched)
                "
                class="error"
              >
                <div *ngIf="neighbourhood.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="complement">Complemento</label>
              <input
                type="text"
                class="form-control"
                id="complement"
                name="complement"
                [(ngModel)]="company.address.complement"
              />
            </div>
            <div class="col-md-4 mb-4">
              <label for="reference">Ponto de referencia</label>
              <input
                type="text"
                class="form-control"
                id="reference"
                name="reference"
                [(ngModel)]="company.address.reference"
              />
            </div>

            <div class="col-md-8 mb-3">
              <label
                for="city"
                [class.error]="city.invalid && (city.dirty || city.touched)"
              >
                Cidade *
              </label>
              <input
                type="text"
                class="form-control"
                id="city"
                name="city"
                [(ngModel)]="company.address.city"
                #city="ngModel"
                [class.error]="city.invalid && (city.dirty || city.touched)"
                required
                disabled
              />
              <div
                *ngIf="city.invalid && (city.dirty || city.touched)"
                class="error"
              >
                <div *ngIf="city.errors.required">Este campo é obrigatório</div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label
                for="state"
                [class.error]="state.invalid && (state.dirty || state.touched)"
              >
                Estado *
              </label>
              <ng-select
                id="state"
                name="state"
                [(ngModel)]="company.address.state"
                #state="ngModel"
                [class.error]="state.invalid && (state.dirty || state.touched)"
                required
                disabled
              >
                <ng-option value="AC">Acre</ng-option>
                <ng-option value="AL">Alagoas</ng-option>
                <ng-option value="AP">Amapá</ng-option>
                <ng-option value="AM">Amazonas</ng-option>
                <ng-option value="BA">Bahia</ng-option>
                <ng-option value="CE">Ceará</ng-option>
                <ng-option value="DF">Distrito Federal</ng-option>
                <ng-option value="ES">Espírito Santo</ng-option>
                <ng-option value="GO">Goiás</ng-option>
                <ng-option value="MA">Maranhão</ng-option>
                <ng-option value="MT">Mato Grosso</ng-option>
                <ng-option value="MS">Mato Grosso do Sul</ng-option>
                <ng-option value="MG">Minas Gerais</ng-option>
                <ng-option value="PA">Pará</ng-option>
                <ng-option value="PB">Paraíba</ng-option>
                <ng-option value="PR">Paraná</ng-option>
                <ng-option value="PE">Pernambuco</ng-option>
                <ng-option value="PI">Piauí</ng-option>
                <ng-option value="RJ">Rio de Janeiro</ng-option>
                <ng-option value="RN">Rio Grande do Norte</ng-option>
                <ng-option value="RS">Rio Grande do Sul</ng-option>
                <ng-option value="RO">Rondônia</ng-option>
                <ng-option value="RR">Roraima</ng-option>
                <ng-option value="SC">Santa Catarina</ng-option>
                <ng-option value="SP">São Paulo</ng-option>
                <ng-option value="SE">Sergipe</ng-option>
                <ng-option value="TO">Tocantins</ng-option>
              </ng-select>
              <div
                *ngIf="state.invalid && (state.dirty || state.touched)"
                class="error"
              >
                <div *ngIf="state.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h2>Endereço de entrega</h2>
            </div>
            <div class="col-12 my-3">
              <label class="custom-checkbox-container">
                <input
                  type="checkbox"
                  id="repeatCheck"
                  name="repeat"
                  (change)="repeatAddress($event)"
                  [(ngModel)]="repeat"
                />
                <label for="repeatCheck" class="text-primary"
                  >Repetir endereço</label
                >
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="row mb-3" *ngIf="!repeat">
            <div class="col-md-6 mb-3">
              <label for="dCep" [class.error]="dCep.invalid && dCep.touched">
                CEP *
              </label>
              <input
                type="text"
                class="form-control"
                id="dCep"
                name="dCep"
                [(ngModel)]="company.deliveryAddress.cep"
                #dCep="ngModel"
                required
                mask="00000-000"
                (change)="getCep($event, company.deliveryAddress)"
                [class.error]="dCep.invalid && dCep.touched"
              />
              <div
                *ngIf="dCep.invalid && (dCep.dirty || dCep.touched)"
                class="error"
              >
                <div *ngIf="dCep.errors.required">Este campo é obrigatório</div>
                <div *ngIf="dCep.errors.mask">O CEP deve ter 8 dígitos</div>
              </div>
            </div>
          </div>
          <div class="row mb-3" *ngIf="!repeat">
            <div class="col-md-8 mb-3">
              <label
                for="dStreet"
                [class.error]="dStreet.invalid && dStreet.touched"
              >
                Rua / Logradouro *
              </label>
              <input
                type="text"
                class="form-control"
                id="dStreet"
                name="dStreet"
                [(ngModel)]="company.deliveryAddress.street"
                #dStreet="ngModel"
                required
                [class.error]="dStreet.invalid && dStreet.touched"
              />
              <div
                *ngIf="dStreet.invalid && (dStreet.dirty || dStreet.touched)"
                class="error"
              >
                <div *ngIf="dStreet.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label
                for="dNumber"
                [class.error]="dNumber.invalid && dNumber.touched"
              >
                Número *
              </label>
              <input
                type="number"
                class="form-control"
                id="dNumber"
                name="dNumber"
                [(ngModel)]="company.deliveryAddress.number"
                #dNumber="ngModel"
                required
                [class.error]="dNumber.invalid && dNumber.touched"
              />
              <div
                *ngIf="dNumber.invalid && (dNumber.dirty || dNumber.touched)"
                class="error"
              >
                <div *ngIf="dNumber.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label
                for="dNeighbourhood"
                [class.error]="dNeighbourhood.invalid && dNeighbourhood.touched"
              >
                Bairro *
              </label>
              <input
                type="text"
                class="form-control"
                id="dNeighbourhood"
                name="dNeighbourhood"
                [(ngModel)]="company.deliveryAddress.neighbourhood"
                #dNeighbourhood="ngModel"
                required
                [class.error]="dNeighbourhood.invalid && dNeighbourhood.touched"
              />
              <div
                *ngIf="
                  dNeighbourhood.invalid &&
                  (dNeighbourhood.dirty || dNeighbourhood.touched)
                "
                class="error"
              >
                <div *ngIf="dNeighbourhood.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="complement">Complemento</label>
              <input
                type="text"
                class="form-control"
                id="dComplement"
                name="dComplement"
                [(ngModel)]="company.deliveryAddress.complement"
              />
            </div>
            <div class="col-md-4 mb-3">
              <label for="reference">Ponto de referencia</label>
              <input
                type="text"
                class="form-control"
                id="dReference"
                name="dReference"
                [(ngModel)]="company.deliveryAddress.reference"
              />
            </div>
            <div class="col-md-8 mb-3">
              <label for="dCity" [class.error]="dCity.invalid && dCity.touched">
                Cidade *
              </label>
              <input
                type="text"
                class="form-control"
                id="dCity"
                name="dCity"
                [(ngModel)]="company.deliveryAddress.city"
                #dCity="ngModel"
                required
                disabled
              />
              <div
                *ngIf="dCity.invalid && (dCity.dirty || dCity.touched)"
                class="error"
              >
                <div *ngIf="dCity.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label
                for="dState"
                [class.error]="dState.invalid && dState.touched"
              >
                Estado *
              </label>

              <ng-select
                id="dState"
                name="dState"
                [(ngModel)]="company.deliveryAddress.state"
                #dState="ngModel"
                required
                disabled
              >
                <ng-option value="AC">Acre</ng-option>
                <ng-option value="AL">Alagoas</ng-option>
                <ng-option value="AP">Amapá</ng-option>
                <ng-option value="AM">Amazonas</ng-option>
                <ng-option value="BA">Bahia</ng-option>
                <ng-option value="CE">Ceará</ng-option>
                <ng-option value="DF">Distrito Federal</ng-option>
                <ng-option value="ES">Espírito Santo</ng-option>
                <ng-option value="GO">Goiás</ng-option>
                <ng-option value="MA">Maranhão</ng-option>
                <ng-option value="MT">Mato Grosso</ng-option>
                <ng-option value="MS">Mato Grosso do Sul</ng-option>
                <ng-option value="MG">Minas Gerais</ng-option>
                <ng-option value="PA">Pará</ng-option>
                <ng-option value="PB">Paraíba</ng-option>
                <ng-option value="PR">Paraná</ng-option>
                <ng-option value="PE">Pernambuco</ng-option>
                <ng-option value="PI">Piauí</ng-option>
                <ng-option value="RJ">Rio de Janeiro</ng-option>
                <ng-option value="RN">Rio Grande do Norte</ng-option>
                <ng-option value="RS">Rio Grande do Sul</ng-option>
                <ng-option value="RO">Rondônia</ng-option>
                <ng-option value="RR">Roraima</ng-option>
                <ng-option value="SC">Santa Catarina</ng-option>
                <ng-option value="SP">São Paulo</ng-option>
                <ng-option value="SE">Sergipe</ng-option>
                <ng-option value="TO">Tocantins</ng-option>
              </ng-select>

              <div
                *ngIf="dState.invalid && (dState.dirty || dState.touched)"
                class="error"
              >
                <div *ngIf="dState.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <!--<div class="col-12 mt-3">
            <h2>Restrição de horário de entrega</h2>
          </div>
          <div class="col-md-3 mb-3">
            <label for="start">Das:</label>
            <input type="text" class="form-control" id="start" name="start" [(ngModel)]="delivery.start" #start="ngModel" required>
            <div *ngIf="start.invalid && (start.dirty || start.touched)" class="error">
              <div *ngIf="start.errors.required">
                  Este campo é obrigatório
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="end">Até</label>
            <input type="text" class="form-control" id="end" name="end" [(ngModel)]="delivery.end" #end="ngModel" required>
            <div *ngIf="end.invalid && (end.dirty || end.touched)" class="error">
              <div *ngIf="end.errors.required">
                  Este campo é obrigatório
              </div>
            </div>
          </div>-->
          </div>
        </div>

        <div id="accountable" class="content" data-step="2">
          <div class="row mb-3">
            <ng-container *ngIf="me?.admin">
              <div class="col-12 d-flex align-items-center">
                <h2 class="mb-0">Gestores(as)*</h2>
                <button
                  class="bg-secondary hover ml-3 rounded-circle img-md border-0 pointer text-white"
                  (click)="company.managers.push({}); inviteByEmail = true"
                >
                  +
                </button>
              </div>
              <form
                #managerForm="ngForm"
                class="w-100 d-flex flex-wrap"
                *ngFor="
                  let manager of company.managers;
                  index as i;
                  first as first
                "
              >
                <h2 class="mt-3 col-12" *ngIf="!manager._id">
                  Adicionar Gestor
                </h2>
                <div
                  class="mb-3"
                  [ngClass]="{
                    'col-md-4': manager._id,
                    'col-md-6': !manager._id
                  }"
                >
                  <label
                    for="mEmail{{ i }}"
                    [class.error]="mEmail.invalid && mEmail.touched"
                  >
                    E-mail
                  </label>
                  <input
                    autocomplete="off"
                    type="email"
                    class="form-control"
                    id="mEmail{{ i }}"
                    name="mEmail{{ i }}"
                    [(ngModel)]="manager.email"
                    #mEmail="ngModel"
                    required
                    [class.error]="mEmail.invalid && mEmail.touched"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}"
                    [disabled]="manager._id"
                  />
                  <div
                    *ngIf="mEmail.invalid && (mEmail.dirty || mEmail.touched)"
                    class="error"
                  >
                    <div *ngIf="mEmail.errors.required">
                      Este campo é obrigatório
                    </div>
                    <div *ngIf="mEmail.errors.pattern">E-mail inválido</div>
                    <div *ngIf="mEmail.errors.duplicate">
                      E-mail já cadastrado
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3" *ngIf="!manager._id">
                  <label for="password{{ i }}"> Senha </label>
                  <div
                    *ngIf="!manager.resetPassword"
                    (click)="manager.resetPassword = true"
                    class="pointer"
                  >
                    Definir Senha
                  </div>
                  <small *ngIf="!manager.resetPassword"
                    >Caso não seja definida, o usuário será convidado via
                    e-mail.</small
                  >
                  <input
                    autocomplete="off"
                    *ngIf="manager.resetPassword"
                    type="password"
                    class="form-control"
                    id="mpassword{{ i }}"
                    name="password{{ i }}"
                    [(ngModel)]="manager.password"
                  />
                </div>
                <div
                  class="mb-3"
                  [ngClass]="{
                    'col-md-4': manager._id,
                    'col-md-6': !manager._id
                  }"
                >
                  <label
                    for="mName{{ i }}"
                    [class.error]="mName.invalid && mName.touched"
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="mName{{ i }}"
                    name="mName{{ i }}"
                    [(ngModel)]="manager.name"
                    #mName="ngModel"
                    [disabled]="manager._id"
                  />
                </div>
                <div
                  class="mb-5 mb-md-3"
                  [ngClass]="{
                    'col-md-4': manager._id,
                    'col-md-6': !manager._id
                  }"
                >
                  <label
                    for="mPhone{{ i }}"
                    [class.error]="
                      mPhone.invalid && (mPhone.dirty || mPhone.touched)
                    "
                  >
                    Telefone
                  </label>
                  <div class="d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control"
                      [class.error]="
                        mPhone.invalid && (mPhone.dirty || mPhone.touched)
                      "
                      id="mPhone{{ i }}"
                      name="mPhone{{ i }}"
                      [(ngModel)]="manager.phone"
                      mask="(00) 0000-0000 || (00) 0 0000-0000"
                      #mPhone="ngModel"
                      [disabled]="manager._id"
                    />
                    <button
                      class="bg-danger d-flex align-items-center justify-content-center hover rounded-circle p-2 text-white border-0 ml-2 img-md pointer"
                      (click)="removeManager(i, manager)"
                    >
                      &minus;
                    </button>
                  </div>
                  <div
                    *ngIf="mPhone.invalid && (mPhone.dirty || mPhone.touched)"
                    class="error"
                  >
                    <div *ngIf="mPhone.errors.mask">Telefone inválido</div>
                  </div>
                </div>
              </form>
            </ng-container>

            <div class="col-12" *ngIf="!isPhysical">
              <h2>Diretor(a)</h2>
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label for="directorName">Nome</label>
              <input
                type="text"
                class="form-control"
                id="directorName"
                name="directorName"
                [(ngModel)]="company.director.name"
                placeholder="Nome do(a) diretor(a)"
              />
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label
                for="dEmail"
                [class.error]="
                  dEmail.invalid && (dEmail.dirty || dEmail.touched)
                "
              >
                E-mail
              </label>
              <input
                type="email"
                class="form-control"
                [class.error]="
                  dEmail.invalid && (dEmail.dirty || dEmail.touched)
                "
                id="dEmail"
                name="dEmail"
                [(ngModel)]="company.director.email"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}"
                placeholder="Email do(a) diretor(a)"
                #dEmail="ngModel"
              />
              <div
                *ngIf="dEmail.invalid && (dEmail.dirty || dEmail.touched)"
                class="error"
              >
                <div *ngIf="dEmail.errors.pattern">E-mail inválido</div>
              </div>
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label
                for="dPhone"
                [class.error]="
                  dPhone.invalid && (dPhone.dirty || dPhone.touched)
                "
              >
                Número
              </label>
              <input
                type="text"
                class="form-control"
                [class.error]="
                  dPhone.invalid && (dPhone.dirty || dPhone.touched)
                "
                id="dPhone"
                name="dPhone"
                [(ngModel)]="company.director.phone"
                mask="(00) 0000-0000 || (00) 0 0000-0000"
                #dPhone="ngModel"
                placeholder="Telefone"
              />
              <div
                *ngIf="dPhone.invalid && (dPhone.dirty || dPhone.touched)"
                class="error"
              >
                <div *ngIf="dPhone.errors.mask">Telefone inválido</div>
              </div>
            </div>
            <div class="col-12" *ngIf="!isPhysical">
              <h2>Nutricionista</h2>
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label for="nutritionistName">Nome</label>
              <input
                type="text"
                class="form-control"
                id="nutritionistName"
                name="nutritionistName"
                [(ngModel)]="company.nutritionist.name"
                placeholder="Nome do(a) nutricionista"
              />
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label
                for="nEmail"
                [class.error]="
                  nEmail.invalid && (nEmail.dirty || nEmail.touched)
                "
              >
                E-mail
              </label>
              <input
                type="email"
                class="form-control"
                [class.error]="
                  nEmail.invalid && (nEmail.dirty || nEmail.touched)
                "
                id="nEmail"
                name="nEmail"
                [(ngModel)]="company.nutritionist.email"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}"
                placeholder="Email do(a) nutricionista"
                #nEmail="ngModel"
              />
              <div
                *ngIf="nEmail.invalid && (nEmail.dirty || nEmail.touched)"
                class="error"
              >
                <div *ngIf="nEmail.errors.pattern">E-mail inválido</div>
              </div>
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label
                for="nPhone"
                [class.error]="
                  nPhone.invalid && (nPhone.dirty || nPhone.touched)
                "
              >
                Número
              </label>
              <input
                type="text"
                class="form-control"
                [class.error]="
                  nPhone.invalid && (nPhone.dirty || nPhone.touched)
                "
                id="nPhone"
                name="nPhone"
                [(ngModel)]="company.nutritionist.phone"
                mask="(00) 0000-0000 || (00) 0 0000-0000"
                #nPhone="ngModel"
                placeholder="Telefone"
              />
              <div
                *ngIf="nPhone.invalid && (nPhone.dirty || nPhone.touched)"
                class="error"
              >
                <div *ngIf="nPhone.errors.mask">Telefone inválido</div>
              </div>
            </div>
            <div class="col-12" *ngIf="!isPhysical">
              <h2>Comprador(a)</h2>
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label for="buyerName"> Nome </label>
              <input
                type="text"
                class="form-control"
                id="buyerName"
                name="buyerName"
                [(ngModel)]="company.buyer.name"
                placeholder="Nome do(a) comprador(a)"
              />
            </div>
            <div class="col-md-4 mb-3" *ngIf="!isPhysical">
              <label
                for="buyerEmail"
                [class.error]="
                  bEmail.invalid && (bEmail.dirty || bEmail.touched)
                "
                >E-mail</label
              >
              <input
                type="email"
                class="form-control"
                [class.error]="
                  bEmail.invalid && (bEmail.dirty || bEmail.touched)
                "
                id="buyerEmail"
                name="buyerEmail"
                [(ngModel)]="company.buyer.email"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}"
                placeholder="Email do(a) comprador(a)"
                #bEmail="ngModel"
              />
              <div
                *ngIf="bEmail.invalid && (bEmail.dirty || bEmail.touched)"
                class="error"
              >
                <div *ngIf="bEmail.errors.pattern">E-mail inválido</div>
              </div>
            </div>
            <div class="col-md-4 mb-5" *ngIf="!isPhysical">
              <label
                for="cPhone"
                [class.error]="
                  cPhone.invalid && (cPhone.dirty || cPhone.touched)
                "
              >
                Número
              </label>
              <input
                type="text"
                class="form-control"
                [class.error]="
                  cPhone.invalid && (cPhone.dirty || cPhone.touched)
                "
                id="cPhone"
                name="cPhone"
                [(ngModel)]="company.buyer.phone"
                mask="(00) 0000-0000 || (00) 0 0000-0000"
                #cPhone="ngModel"
                placeholder="Telefone"
              />
              <div
                *ngIf="cPhone.invalid && (cPhone.dirty || cPhone.touched)"
                class="error"
              >
                <div *ngIf="cPhone.errors.mask">Telefone inválido</div>
              </div>
            </div>
          </div>
        </div>

        <div id="settings" class="content" data-step="3">
          <div class="mb-3 row">
            <div class="col-12">
              <label>Fornecedores bloqueados</label>
            </div>
            <!-- <div class="col-sm-12 col-md-6">
              <ng-select [items]="providers" bindValue="_id" bindLabel="name" name="provider" placeholder="Selecione para bloquear fornecedor" (click)="test"></ng-select>
            </div> -->
          <!-- <div id="offers" class="content">
            <div class="row" [ngClass]="{ 'first-row': i == 0 }" *ngFor="let offer of offers; let i = index">
              <div class="col-8 col-md-9 pr-0 mb-1">
                <label>Fornecedor</label>
              </div>
            </div>
            <div class="col-12 p-0 my-3">
              <button class="btn button-rounded bg-gradient" (click)="addProviderInput()">+</button>
              <span class="ml-2 weight-medium">Adicionar fornecedor</span> 
            </div>
          </div> -->
          </div>
        </div>

        <div class="col-12 col-lg-6 offset-lg-3">
          <action-button
            [label]="
              !company._id && stepper.step < (isPhysical ? 1 : 2)
                ? 'Próximo'
                : 'Salvar'
            "
            [fixed]="true"
            (click)="
              !company._id && stepper.step < (isPhysical ? 1 : 2)
                ? stepper.next()
                : validate()
            "
          ></action-button>
        </div>
      </form>
    </div>
  </div>
</div>
