import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmWindowComponent } from '../confirm-window/confirm-window.component';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  @Input() hasDelete: boolean;
  @Input() order: any; 
  @Input() onDelete;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService,
              private service: OrderService) { }

  status = { 
    'w_waiting': { class: 'bg-gray text-medium', icon: '/assets/icons/analysis.svg', label: 'Realizado' },
    'i_accepted': { class: 'bg-orange text-white', icon: '/assets/icons/delivery.svg', label: 'Confirmado' },
    'i_inprogress': { class: 'bg-blue text-white', icon: '/assets/icons/delivery.svg', label: 'Faturado' },
    'f_canceled': { class: 'bg-danger text-white', icon: '/assets/icons/way.svg', label: 'Cancelado' },
    'f_completed': { class: 'bg-green text-white', icon: '/assets/icons/complete.svg', label: 'Finalizado' }
  };

  ngOnInit() { }

  cancel() {
    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        onYes: () => this.onDelete(this.order._id)
      }
    });
  }
}
