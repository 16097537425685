import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/shared/services/company.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ConfirmWindowComponent } from 'src/app/shared/ui/confirm-window/confirm-window.component';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-school-details',
  templateUrl: './school-details.component.html',
  styleUrls: ['./school-details.component.scss']
})
export class SchoolDetailsComponent implements OnInit {

  routeId;
  company: any = {};
  modalRef: BsModalRef;
  title: 'teste';
  isPhysical: boolean;

  constructor(private route: ActivatedRoute,
              private service: CompanyService,
              private modalService: BsModalService,
              private userService: UserService) { }

  ngOnInit() {
    this.routeId = this.route.snapshot.params.id;

    this.userService.me().subscribe((me) => {
      if (me.manager) this.service.watchCompany().subscribe((company) => {
        this.company = company;
      });
    });

    if (this.routeId) { this.get(); }
  }

  get() {
    this.service.get(this.routeId).subscribe((company: any) => {
      this.company = company;
      this.isPhysical = company.type === 'física';
    });
  }

  openModal(id) {
    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        title: 'school',
        data: id,
      }
    });
  }
}
