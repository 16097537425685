<div class="item-wrapper order p-3 bg-light" [routerLink]="[order._id]">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center pointer order-provider" >
      <img src="{{ order.provider?.logo || '/assets/icons/avatar.svg' }}" class="img-lg rounded-circle">
      <h5 class="ml-2 m-0">{{ order.provider?.name }}</h5>
    </div>
    <!-- <div *ngIf="hasDelete" class="d-flex align-items-center">
      <img src="/assets/icons/cancel.png" class="ml-2 pointer" (click)="cancel()">
    </div> -->
  </div>
  <div class="d-flex align-items-center justify-content-between mt-2">
    <small class="weight-medium text-uppercase text-nowrap rounded px-2 py-1 {{ status[order.status].class }}">
      {{ status[order.status].label }}
    </small>
    <div class="d-flex align-items-center">
      <span class="text-sub text-left">
        {{ order.date | date:'dd/MM/yyyy' }}
      </span>
    </div>
    <div class="d-flex align-items-center">
      <span class="text-highlight highlight">{{ order.total | currency:'BRL'}}</span>
    </div>
  </div>
</div>