import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'action-button',
  host: {
    // '[class.fixed]': 'fixed'
  },
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {

  @Input() label = '';
  @Input() disabled: boolean;
  @Input() rounded: boolean;
  @Input() fixed: boolean;

  constructor() { }

  ngOnInit() {
  }

}
