<div class="container-fluid bg-secondary fullpage">
  <div class="row background">
      <div class="col-md-7 p-0"></div>
      <div class="col-md-5 d-flex flex-column justify-content-center fullpage">
          <div class="form text-center w-100">
              <img src="assets/images/logo-izza.png" alt="logo izza" class="logo">
              <router-outlet></router-outlet>
          </div>
      </div>
  </div>
</div>