import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  API = environment.url;

  constructor(private http: HttpClient) { }

  getCep(cep) {
    return this.http.get(`${this.API}/cep/${cep}`);
  }

  getCities() {
    return this.http.get(`${this.API}/cities`);
  }
}
