<search-box [title]="me?.name"></search-box>

<div class="row mb-3">
  <div class="col-md-12">
    <h5 class="text-medium mb-3">
      Selecione uma escola
    </h5>
  </div>

  <div class="col-6 col-md-3" *ngFor="let company of companies.data">
    <div
      [class.selected]="selected._id === company._id"
      class="school border rounded-2"
      (click)="set(company)"
    >
      <img [src]="company.logo" class="logo rounded-circle mb-2 pointer" (error)="updateImage($event)">
      <p class="pointer m-0 weight-medium text-medium">
        {{ company.name }}
      </p>
    </div>
  </div>
</div>