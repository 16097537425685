<div class="modal-body">
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <form #reportForm="ngForm">
    <h2>Fatura única</h2>
    <div class="row">
      <div class="col-12 mb-3">
        <label
          [class.error]="
            expirationInput.invalid &&
            (expirationInput.dirty || expirationInput.touched)
          "
        >
          Data de vencimento
        </label>
        <input
          class="form-control text-right"
          name="expiration"
          bsDatepicker
          [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true, dateInputFormat: 'DD/MM/YYYY' }"
          [(ngModel)]="expiration"
          #expirationInput="ngModel"
          [class.error]="
            expirationInput.invalid &&
            (expirationInput.dirty || expirationInput.touched)
          "
          required
        />
        <div
          *ngIf="
            expirationInput.invalid &&
            (expirationInput.dirty || expirationInput.touched)
          "
          class="error"
        >
          <div *ngIf="expirationInput.errors.required">Selecione uma data</div>
        </div>
      </div>

      <!-- <div class="col-12 mb-3">
        <label>Bônus</label>
        <input
          type="text"
          class="form-control text-right"
          name="bonus"
          mask="00000000"
          [(ngModel)]="bonus"
          #bonusInput="ngModel"
          [validation]="false"
        />
      </div> -->

      <div class="col-12 mb-3">
        <label
          [class.error]="
            methodInput.invalid && (methodInput.dirty || methodInput.touched)
          "
          >Forma de pagamento</label
        >
        <ng-select
          class="w-100"
          [clearable]="false"
          name="method"
          [(ngModel)]="method"
          #methodInput="ngModel"
          required="true"
          [class.error]="
            methodInput.invalid && (methodInput.dirty || methodInput.touched)
          "
        >
          <ng-container *ngFor="let method of paymentMethods">
            <ng-option [value]="method.key">
              <span class="weight-medium text-medium">{{ method.label }}</span>
            </ng-option>
          </ng-container>
        </ng-select>
        <div
          *ngIf="
            methodInput.invalid && (methodInput.dirty || methodInput.touched)
          "
          class="error"
        >
          <div *ngIf="methodInput.errors.required">Selecione um item</div>
        </div>
      </div>

      <div class="col-12 mb-3">
        <label>Observações</label>
        <textarea
          type="text"
          class="form-control"
          name="generalComments"
          [(ngModel)]="generalComments"
          rows="4"
        ></textarea>
      </div>

      <div class="col-12 mb-3">
        <label>Observações do Cliente</label>
        <textarea
          type="text"
          class="form-control"
          name="clientComments"
          [(ngModel)]="clientComments"
          rows="4"
        ></textarea>
      </div>
    </div>
  </form>
  <button
    class="mt-2 btn button secondary text-white"
    (click)="reportForm.form.valid ? set() : reportForm.form.markAllAsTouched()"
  >
    Atualizar
  </button>
</div>
