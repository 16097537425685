<form class="my-5 text-left" #formVar="ngForm">
  <div class="form-group">
    <label class="text-light" for="email">Seu nome</label>
    <input type="text" class="form-control" id="email" placeholder="Seu nome e sobrenome" name="name"
      [(ngModel)]="user.name" #name="ngModel" required>
    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
      <div *ngIf="name.errors.required">
        Este campo é obrigatório
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="text-light" for="email">E-mail</label>
    <input type="text" class="form-control" id="email" placeholder="email@email.com.br" name="email"
      [(ngModel)]="user.email" #email="ngModel" required [disabled]="!user.isNew">
    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
      <div *ngIf="email.errors.required">
        Este campo é obrigatório
      </div>
    </div>
    <div class="error" *ngIf="error.email">E-mail já cadastrado, efetue seu login.</div>
  </div>
  <div class="form-group">
    <label class="text-light" for="password">Senha</label>
    <div class="d-flex">
      <input type="password" class="form-control" id="password" placeholder="******" name="password"
        [(ngModel)]="user.password" #password="ngModel" required pw>
    </div>
    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="error">
      <div *ngIf="password.errors.required">
        Este campo é obrigatório
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="text-light" for="password">Repita a senha</label>
    <div class="d-flex">
      <input type="password" class="form-control" id="password" placeholder="******" name="repeat"
        [(ngModel)]="user.repeat" #repeat="ngModel" required pw>
    </div>
    <div *ngIf="repeat.invalid && (repeat.dirty || repeat.touched)" class="error">
      <div *ngIf="repeat.errors.required">
        Este campo é obrigatório
      </div>
    </div>
    <div class="error" *ngIf="error.password">As senhas devem ser iguais</div>
  </div>
  <button [disabled]="formVar.invalid" class="btn button mw-100 primary mt-3" (click)="signup()">
    Cadastrar
  </button>
  <div class="mt-2">
    <a class="form-link" routerLink="/user/signin">Voltar para o login</a>
  </div>
</form>