<div id="stepper1" class="bs-stepper">
  <header class="text-center">
    <div class="container mb-2">
      <div class="steps-wrapper row no-margin-sm">
        <div class="col-12">
          <h4>{{routeId ? 'Editar' : 'Cadastrar'}} fornecedor</h4>
        </div>
        <div class="col-12 col-md-8 offset-md-2 mb-4 mt-3">
          <p class="weight-medium mb-4">{{ stepper?.title }}</p>
          <div class="bs-stepper-header" role="tablist">
            <div class="step" data-target="#info" >
              <button class="step-trigger" type="button" role="tab" (click)="stepper?.to(0);">
                <span class="bs-stepper-circle"></span>
                <span class="bs-stepper-label">Sobre</span>
                <span class="bs-stepper-desc">Informações gerais</span>
              </button>
            </div>
            <div class="line"></div> <!-- [class.bg-primary]="stepper?.step > 0" -->
            <div class="step" data-target="#address">
              <button class="step-trigger" type="button" role="tab" (click)="stepper?.to(1)">
                <span class="bs-stepper-circle" ></span><!-- [class.bg-primary]="stepper?.step >= 1"-->
                <span class="bs-stepper-label">Endereço</span>
                <span class="bs-stepper-desc">Endereço do fornecedor</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#availability">
              <button class="step-trigger" type="button" role="tab" (click)="stepper?.to(2)">
                <span class="bs-stepper-circle" ></span>
                <span class="bs-stepper-label">Disponibilidade</span>
                <span class="bs-stepper-desc">Configurações de entrega do fornecedor</span>
              </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#contact">
              <button class="step-trigger" type="button" role="tab" (click)="stepper?.to(3)">
                <span class="bs-stepper-circle" ></span>
                <span class="bs-stepper-label">Contatos</span>
                <span class="bs-stepper-desc">Informações de contato</span>
              </button>
            </div>
            <div class="line" [ngClass]="{'d-none': !routeId}"></div>
            <div class="step" [ngClass]="{'d-none': !routeId}" data-target="#products">
              <button class="step-trigger" type="button" role="tab" (click)="stepper?.to(4)">
                <span class="bs-stepper-circle" ></span>
                <span class="bs-stepper-label">Produtos</span>
                <span class="bs-stepper-desc">Produtos do fornecedor</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  
  <div class="container">
    <div class="bs-stepper-content">
      <div class="row">
        <div class="col-12 my-3 px-2 d-flex align-items-center">
          <back-button *ngIf="stepper?.step > 0" (click)="stepper?.previous()">
          </back-button>

          <div class="ml-auto" *ngIf="stepper?.step === 2" >
            <add-btn (click)="addCity()"></add-btn>
          </div>
          
          <div class="ml-auto" *ngIf="stepper?.step === 4" >
            <span class="mr-4 pointer" (click)="importFile.click()">Importar</span>
            <input type="file" name="file" (change)="import($event)" #importFile class="hide" />
            <span class="mr-2 pointer" (click)="export()">Exportar</span>
            <add-btn (click)="openForm()"></add-btn>
          </div>
        </div>
      </div>
      <form #providerForm="ngForm">
        <div id="info" class="content" data-step="0">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label [class.error]="name.invalid && (name.dirty || name.touched)" for="name">Nome *</label>
              <input [class.error]="name.invalid && (name.dirty || name.touched)" type="text" class="form-control" id="name" name="name" [(ngModel)]="provider.name" #name="ngModel" required>
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                <div *ngIf="name.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <label for="logo">Logo</label>
              <div class="d-flex">
                <div class="border p-2 rounded">
                  <input type="file" id="logoInput" (change)="readFile($event)" accept="image/png, image/jpeg">
                  <label for="logoInput" class="h-100 w-100 rounded pointer font-weight-normal m-0">
                    <span class="text-medium">
                      {{ logoFile?.name || 'Selecione uma imagem...' }}
                    </span>
                  </label>
                </div>

                <div class="col-md-2" *ngIf="logoImage">
                  <img [src]="logoImage" class="file-preview img-xl">
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <label [class.error]="companyName.invalid && (companyName.dirty || companyName.touched)" for="companyName">Razão social *</label>
              <input [class.error]="companyName.invalid && (companyName.dirty || companyName.touched)" type="text" class="form-control" id="companyName" name="companyName" [(ngModel)]="provider.companyName" #companyName="ngModel" required>
              <div *ngIf="companyName.invalid && (companyName.dirty || companyName.touched)" class="error">
                <div *ngIf="companyName.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <label [class.error]="cnpj.invalid && (cnpj.dirty || cnpj.touched)" for="cnpj">CNPJ *</label>
              <input [class.error]="cnpj.invalid && (cnpj.dirty || cnpj.touched)" type="text" class="form-control" id="cnpj" name="cnpj" [(ngModel)]="provider.cnpj" #cnpj="ngModel" required mask="00.000.000/0000-00"
              minlength="14" [validation]="false">
              <div *ngIf="cnpj.invalid && (cnpj.dirty || cnpj.touched)" class="error">
                <div *ngIf="cnpj.errors.required">
                  Este campo é obrigatório
                </div>
                <div *ngIf="cnpj.errors.minlength">
                  O CPNJ deve ter 14 dígitos
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <label for="phone" [class.error]="phone.invalid && (phone.dirty || phone.touched)">
                Telefone
              </label>
              <input type="text" class="form-control" [class.error]="phone.invalid && (phone.dirty || phone.touched)" id="phone" name="phone" [(ngModel)]="provider.phone" mask="(00) 0000-0000 || (00) 0 0000-0000" #phone="ngModel" placeholder="Telefone">
              <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="error">
                <div *ngIf="phone.errors.mask">
                  Telefone inválido
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label [class.error]="url.invalid && (url.dirty || url.touched)" for="url">
                Site
              </label>
              <input [class.error]="url.invalid && (url.dirty || url.touched)" type="url" class="form-control" id="url" name="url" [(ngModel)]="provider.url" #url="ngModel" placeholder="Ex: exemplo.com.br" >
            </div>
            <div class="col-12 col-sm-6 mb-3">
              <label>Regras PJ</label>
              <textarea name="rules" class="form-control" rows="4" [(ngModel)]="provider.rules"></textarea>
            </div>

            <div class="col-12 col-sm-6 mb-3">
              <label>Regras PF</label>
              <textarea name="rulesPF" class="form-control" rows="4" [(ngModel)]="provider.rulesPF"></textarea>
            </div>

            <div class="col-12 mb-2">
              <label class="custom-checkbox-container">
                <input type="checkbox" id="active" name="disabled" [(ngModel)]="provider.active">
                <label for="active" class="text-primary">Disponível para compras.</label>
                <span class="checkmark"></span>
              </label>
              <small>Ao desmarcar a opção acima, este fornecedor não será mais listado nas opções de compras de todos os clientes.</small>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <h2>Pedidos</h2>
            </div>

            <div class="col-12 col-md-6">
              <label for="weight">
                E-mail
              </label>
              
              <input type="text" class="form-control" id="weight" name="weight" placeholder="E-mail" (change)="addEmail($event.target.value); $event.target.value = ''" autocomplete="off">
              <div class="mb-2">
                <small class="text-medium weight-medium" >
                  E-mails a serem usados para o envio de pedidos
                </small>
              </div>

              <div class="d-flex align-items-center flex-wrap mt-2">
                <div class="d-flex align-items-center py-1 px-2 bg-secondary rounded mr-1 mb-1" *ngFor="let email of provider?.emails; let i = index">
                  <small class="text-white weight-medium">{{ email }}</small>
                  <small class="text-white weight-medium ml-2 pointer" (click)="removeItem(provider?.emails, i)">&#10005;</small>
                </div>
              </div>
            </div>

            <div class="mb-3 col-6 col-md-3">
              <label for="com_pf" [class.error]="com_pf.invalid && (com_pf.dirty || com_pf.touched)">
                Comissão PF
              </label>
              <input type="text" class="form-control text-right" [class.error]="com_pf.invalid && (com_pf.dirty || com_pf.touched)" id="comission" name="com_pf" [ngModel]="provider.com_pf" (ngModelChange)="provider.com_pf = $event" suffix="%" mask="percent.2"  decimalMarker="," [validation]="false" maxlength="3" #com_pf="ngModel" required>
              <div *ngIf="com_pf.invalid && (com_pf.dirty || com_pf.touched)" class="error">
                <div *ngIf="com_pf.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>

            <div class="mb-3 col-6 col-md-3">
              <label for="com_pj" [class.error]="com_pj.invalid && (com_pj.dirty || com_pj.touched)">
                Comissão PJ
              </label>
              <input type="text" class="form-control text-right" [class.error]="com_pj.invalid && (com_pj.dirty || com_pj.touched)" id="com_pj" name="com_pj" [ngModel]="provider.com_pj" (ngModelChange)="provider.com_pj = $event" suffix="%" mask="percent.2"  decimalMarker="," [validation]="false" maxlength="3" #com_pj="ngModel" required>
              <div *ngIf="com_pj.invalid && (com_pj.dirty || com_pj.touched)" class="error">
                <div *ngIf="com_pj.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="address" class="content" data-step="1">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="d-flex align-items-center mb-2">
                <label [class.error]="cep.invalid && (cep.dirty || cep.touched)" for="cep" class="mb-0">
                  CEP *
                </label>
                <div class="spinner ml-2" *ngIf="loadings.cep"></div>
                <span class="text-medium ml-2" *ngIf="!loadings.cep && errors.cep">
                  CEP não encontrado
                </span>
              </div>
              <input [class.error]="cep.invalid && (cep.dirty || cep.touched)" type="text" class="form-control" id="cep" name="cep" [(ngModel)]="provider.address && provider.address.cep" #cep="ngModel" required mask="00000-000" (change)="getCep($event, cep.valid)">
              <div *ngIf="cep.invalid && (cep.dirty || cep.touched)" class="error">
                <div *ngIf="cep.errors.required">
                  Este campo é obrigatório
                </div>
                <div *ngIf="cep.errors.mask">
                  O CEP deve ter 8 dígitos
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 mb-3">
              <label [class.error]="street.invalid && (street.dirty || street.touched)" for="street">
                Rua / Logradouro *
              </label>
              <input [class.error]="street.invalid && (street.dirty || street.touched)" type="text" class="form-control" id="street" name="street" [(ngModel)]="provider.address && provider.address.street" #street="ngModel" required>
              <div *ngIf="street.invalid && (street.dirty || street.touched)" class="error">
                <div *ngIf="street.errors.required">
                    Este campo é obrigatório
                </div>
              </div>
            </div>

            <div class="col-4 col-md-2 mb-3">
              <label [class.error]="number.invalid && (number.dirty || number.touched)" for="number">
                Número *
              </label>
              <input [class.error]="number.invalid && (number.dirty || number.touched)" type="number" class="form-control" id="number" name="number" [(ngModel)]="provider.address && provider.address.number" #number="ngModel" required>
              <div *ngIf="number.invalid && (number.dirty || number.touched)" class="error">
                <div *ngIf="number.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label [class.error]="neighbourhood.invalid && (neighbourhood.dirty || neighbourhood.touched)" for="neighbourhood">
                Bairro *
              </label>
              <input [class.error]="neighbourhood.invalid && (neighbourhood.dirty || neighbourhood.touched)" type="text" class="form-control" id="neighbourhood" name="neighbourhood" [(ngModel)]="provider.address && provider.address.neighbourhood" #neighbourhood="ngModel" required>
              <div *ngIf="neighbourhood.invalid && (neighbourhood.dirty || neighbourhood.touched)" class="error">
                <div *ngIf="neighbourhood.errors.required">
                    Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-8 col-md-4 mb-3">
              <label for="complement">Complemento</label>
              <input type="text" class="form-control" id="complement" name="complement" [(ngModel)]="provider.address && provider.address.complement">
            </div>
            <div class="col-md-4 mb-3">
              <label for="reference">Ponto de referencia</label>
              <input type="text" class="form-control" id="reference" name="address_ref" [(ngModel)]="provider.address && provider.address.reference">
            </div>

            <div class="col-md-8 mb-3">
              <label [class.error]="city.invalid && (city.dirty || city.touched)" for="city">Cidade *</label>
              <input [class.error]="city.invalid && (city.dirty || city.touched)" type="text" class="form-control" id="city" name="city" [(ngModel)]="provider.address && provider.address.city" #city="ngModel" required disabled>
              <div *ngIf="city.invalid && (city.dirty || city.touched)" class="error">
                <div *ngIf="city.errors.required">
                    Este campo é obrigatório
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label [class.error]="state.invalid && (state.dirty || state.touched)" for="state">
                Estado *
              </label>
              <ng-select [class.error]="state.invalid && (state.dirty || state.touched)" name="state" [(ngModel)]="provider.address && provider.address.state" #state="ngModel" required disabled>
                <ng-option value="AC">Acre</ng-option>
                <ng-option value="AL">Alagoas</ng-option>
                <ng-option value="AP">Amapá</ng-option>
                <ng-option value="AM">Amazonas</ng-option>
                <ng-option value="BA">Bahia</ng-option>
                <ng-option value="CE">Ceará</ng-option>
                <ng-option value="DF">Distrito Federal</ng-option>
                <ng-option value="ES">Espírito Santo</ng-option>
                <ng-option value="GO">Goiás</ng-option>
                <ng-option value="MA">Maranhão</ng-option>
                <ng-option value="MT">Mato Grosso</ng-option>
                <ng-option value="MS">Mato Grosso do Sul</ng-option>
                <ng-option value="MG">Minas Gerais</ng-option>
                <ng-option value="PA">Pará</ng-option>
                <ng-option value="PB">Paraíba</ng-option>
                <ng-option value="PR">Paraná</ng-option>
                <ng-option value="PE">Pernambuco</ng-option>
                <ng-option value="PI">Piauí</ng-option>
                <ng-option value="RJ">Rio de Janeiro</ng-option>
                <ng-option value="RN">Rio Grande do Norte</ng-option>
                <ng-option value="RS">Rio Grande do Sul</ng-option>
                <ng-option value="RO">Rondônia</ng-option>
                <ng-option value="RR">Roraima</ng-option>
                <ng-option value="SC">Santa Catarina</ng-option>
                <ng-option value="SP">São Paulo</ng-option>
                <ng-option value="SE">Sergipe</ng-option>
                <ng-option value="TO">Tocantins</ng-option>
              </ng-select>
              <div *ngIf="state.invalid && (state.dirty || state.touched)" class="error">
                <div *ngIf="state.errors.required">
                  Este campo é obrigatório
                </div>
              </div>
            </div>  
          </div>
        </div>

        <div id="availability" class="content" data-step="2">

          <div class="row">
            <table class="table">
              <tr>
                <th class="weight-medium">Cidade</th>
                <th class="weight-medium">Dias de Entrega</th>
                <th class="weight-medium text-right">Prazo</th>
                <th class="weight-medium text-right">Pedido Mínimo</th>
                <th class="weight-medium"></th>
              </tr>
              <tr class="table-row" *ngFor="let item of provider?.cities; let i = index">
                <td class="w-100">
                  <span *ngIf="!item.c_edit" (click)="item.c_edit = true;" class="weight-medium">{{ item?.name?.split(',')[0] }}</span>
                  <ng-select
                    name="city_name" 
                    placeholder="Selecione a cidade"
                    [items]="_cities"
                    [(ngModel)]="item.city"
                    (blur)="item.name = item.city?.value;"
                    *ngIf="item.c_edit">
                  </ng-select>
                  <div *ngIf="!item.c_edit" (click)="item.c_edit = true;">{{ item?.neighborhood }}</div>
                  <ng-select
                    name="neighborhoods"
                    bindValue="value"
                    placeholder="Selecione os bairros"
                    [items]="item.city?.neighborhood"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [hideSelected]="true"                
                    [(ngModel)]="item.neighborhood"
                    (blur)="item.c_edit = false;"
                    *ngIf="item.c_edit">
                  </ng-select>
                </td>
                <td class="weight-medium w-100">
                  <span class="day" (click)="toggleDays(item, 0)" [class.selected]="item.days?.indexOf(0) > -1">D</span>
                  <span class="day" (click)="toggleDays(item, 1)" [class.selected]="item.days?.indexOf(1) > -1">S</span>
                  <span class="day" (click)="toggleDays(item, 2)" [class.selected]="item.days?.indexOf(2) > -1">T</span>
                  <span class="day" (click)="toggleDays(item, 3)" [class.selected]="item.days?.indexOf(3) > -1">Q</span>
                  <span class="day" (click)="toggleDays(item, 4)" [class.selected]="item.days?.indexOf(4) > -1">Q</span>
                  <span class="day" (click)="toggleDays(item, 5)" [class.selected]="item.days?.indexOf(5) > -1">S</span>
                  <span class="day" (click)="toggleDays(item, 6)" [class.selected]="item.days?.indexOf(6) > -1">S</span>
                </td>
                <td class="weight-medium text-right pointer" style="min-width: 120px;" (click)="item.t_edit = true;">
                  <span *ngIf="!item.t_edit">{{ getTerm(item) }}</span>
                  <select *ngIf="item.t_edit" class="form-control" name="term" [(ngModel)]="item.term" (blur)="item.t_edit = false">
                    <option value="13">Mesmo dia</option>
                    <option value="31">1 dia util</option>
                    <option value="55">2 dias úteis</option>
                    <option value="79">3 dias úteis</option>
                    <option value="103">4 dias úteis</option>
                    <option value="127">5 dias úteis</option>
                  </select>
                </td>
                <td class="weight-medium text-right pointer" style="min-width: 150px;" (click)="item.p_edit = true;">
                  <span *ngIf="!item.p_edit">{{ item?.min | currency:'BRL' }}</span>
                  <input class="form-control" *ngIf="item.p_edit" name="min" [(ngModel)]="item.min" placeholder="Mínimo" currencyMask [options]="{ prefix: 'R$', precision: 2, thousands: '.', decimal: ',' }" (blur)="item.p_edit = false">
                </td>
                <td class="weight-medium pointer">
                  <div [inlineSVG]="'/assets/icons/delete.svg'" class="icon-danger" (click)="removeItem(provider?.cities, i)"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div id="contact" class="content" data-step="3">
          <div class="row">
            <ng-container *ngFor="let contact of (provider.contacts | keyvalue); index as i">
              <div class="col-12 mt-2">
                <h2>{{contacts[contact.key]}}</h2>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label for="cName{{i}}" [class.error]="cName.invalid && (cName.dirty || cName.touched)">
                  Nome
                </label>
                <input type="text" class="form-control" [class.error]="cName.invalid && (cName.dirty || cName.touched)" id="cName{{i}}" name="cName{{i}}" [(ngModel)]="contact.value.name" [required]="cPhone.value || cMail.value" #cName="ngModel" placeholder="Nome do gestor">
                <div *ngIf="cName.invalid && (cName.dirty || cName.touched)" class="error">
                  <div *ngIf="cName.errors.required">
                    Preencha o nome do gestor
                  </div>
                </div>
              </div>
              
              <div class="col-12 col-md-4 mb-3">
                <label for="cPhone{{i}}" [class.error]="cPhone.invalid && (cPhone.dirty || cPhone.touched)">
                  Telefone
                </label>
                <input type="text" class="form-control" [class.error]="cPhone.invalid && (cPhone.dirty || cPhone.touched)" id="cPhone{{i}}" name="cPhone{{i}}" [(ngModel)]="contact.value.phone" mask="(00) 0000-0000 || (00) 0 0000-0000" #cPhone="ngModel" placeholder="Telefone">
                <div *ngIf="cPhone.invalid && (cPhone.dirty || cPhone.touched)" class="error">
                  <div *ngIf="cPhone.errors.mask">
                    Telefone inválido
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 mb-3">
                <label for="cMail{{i}}" [class.error]="cMail.invalid && (cMail.dirty || cMail.touched)">
                  E-mail
                </label>
                <input type="text" class="form-control" [class.error]="cMail.invalid && (cMail.dirty || cMail.touched)" id="cMail{{i}}" name="cMail{{i}}" [(ngModel)]="contact.value.email" #cMail="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}" placeholder="E-mail">
                <div *ngIf="cMail.invalid && (cMail.dirty || cMail.touched)" class="error">
                  <div *ngIf="cMail.errors.pattern">
                    E-mail inválido
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div id="products" class="content mb-3" data-step="4">
          <form #newOfferForm="ngForm" (ngSubmit)="createNewOffer()">
            <div class="row item-wrapper bg-light p-3 m-0" *ngIf="newOffer?.open">
              <div class="col-12 col-md-6 pl-0 align-items-center text-left">
                <label>Nome do produto</label>
                <input name="product" class="form-control" autocomplete="off" [(ngModel)]="newOffer.selectedProduct" [typeahead]="products" [typeaheadOptionField]="'name'" (typeaheadOnSelect)="setNewOfferProduct($event)" placeholder="Busque o nome do produto">
              </div>
              <div class="col-8 col-md-6 p-0 d-flex align-items-center text-left">
                <div class="mr-2">
                  <label>Preço PF</label>
                  <input name="pricePF" class="form-control" [(ngModel)]="newOffer.pricePF" currencyMask [options]="{ prefix: 'R$', precision: 2, thousands: '.', decimal: ',' }">
                </div>

                <div class="mr-2">
                  <label>Preço PJ</label>
                  <input name="price" class="form-control" [(ngModel)]="newOffer.price" currencyMask [options]="{ prefix: 'R$', precision: 2, thousands: '.', decimal: ',' }">
                </div>

                <button type="submit" class="btn d-flex center button-rounded md icon-md bg-primary mt-4 mr-2">
                  <div [inlineSVG]="'/assets/icons/check.svg'" class="icon-white d-flex"></div>
                </button>

                <button class="btn d-flex center button-rounded md mt-4 icon-md bg-danger" (click)="newOffer.open = false">
                  <div [inlineSVG]="'/assets/icons/clear.svg'" class="icon-white d-flex"></div>
                </button>
              </div>
          </div>
        </form>
          <div class="row" *ngFor="let offer of offers?.data; index as i">
            <div class="col-12">
              <div class="row item-wrapper px-0 py-3 m-0 border-bottom">
                <div class="col-md-6 d-flex align-items-center p-0">
                  <img [src]="offer?.product?.mainImage ? offer?.product?.mainImage : (offer?.product?.images?.length > 0) ? offer?.product?.images[0] : '/assets/icons/avatar.svg'" class="product-photo img-lg">
                  <h6 class="ml-2 mb-0 text-left text-medium">
                    <div>{{ offer?.product?.name }}</div>
                    <small>{{ offer.product?.brand }}</small>
                  </h6>
                </div>
                <div class="col-md-6 p-0 d-flex align-items-center" *ngIf="offer.isEditing">
                  <small>PF</small>
                  <input name="pricePF" class="form-control mx-2" [(ngModel)]="offer.newPricePF" currencyMask [options]="{ prefix: 'R$', precision: 2, thousands: '.', decimal: ',' }" />
                  <small>PJ</small>
                  <input name="price" class="form-control mx-2" [(ngModel)]="offer.newPrice" currencyMask [options]="{ prefix: 'R$', precision: 2, thousands: '.', decimal: ',' }" />
                  
                  <button type="submit" class="btn d-flex center button-rounded md icon-md bg-primary mr-2" (click)="setOfferPrice(offer)">
                    <div [inlineSVG]="'/assets/icons/check.svg'" class="icon-white d-flex"></div>
                  </button>
  
                  <button class="btn d-flex center button-rounded md icon-md bg-danger" (click)="newOffer.open = false" (click)="offer.isEditing = false;">
                    <div [inlineSVG]="'/assets/icons/clear.svg'" class="icon-white d-flex"></div>
                  </button>
                </div>
                <div class="col-md-6 p-0 d-flex justify-content-end align-items-center" *ngIf="!offer.isEditing">
                  <div *ngIf="offer?.pricePF">
                    <small>PF</small>
                    <span class="text-highlight highlight mx-2">
                      {{offer?.pricePF | currency:'BRL'}}
                    </span>
                  </div>
                  <div>
                    <small>PJ</small>
                    <span class="text-highlight highlight mx-2">
                      {{offer?.price | currency:'BRL'}}
                    </span>
                  </div>
                  <div class="price-edit-icon mr-2 ml-4" (click)="editOfferPrice(offer)">
                    <div [inlineSVG]="'/assets/icons/edit.svg'"></div>
                  </div>
                  <div class="pointer" (click)="confirm($event, offer._id)">
                    <div [inlineSVG]="'/assets/icons/delete.svg'" class="icon-danger"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

        <div class="text-center col-12 col-lg-6 offset-lg-3 px-0 mt-4 p-0" [ngClass]="{'d-none': stepper.step === 3}">
          <action-button [label]="(!provider._id && stepper.step < 2) ? 'Próximo' : 'Salvar'" [fixed]="true" (click)="(!provider._id && stepper.step < 2) ? stepper.next() : save()"></action-button>
        </div>
      </form>
    </div>
  </div>
</div>