import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/shared/services/order.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-new-address',
  templateUrl: './order-new-address.component.html',
  styleUrls: ['./order-new-address.component.scss']
})
export class OrderNewAddressComponent implements OnInit {

  order;

  constructor(private service: OrderService,
              private cepService: SharedService,
              private router: Router) { }

  ngOnInit(): void {
    this.service.getCart().subscribe((order) => {
      this.order = order;
    });
  }

  save() {
    this.order.address._id = null;
    this.service.setCart(this.order);
    this.service.saveCart();
    this.router.navigate(['/order', 'confirm']);
  }

  getCep(e, valid) {
    if (!valid) { return; }
    const cep = e.target.value;

    this.cepService.getCep(cep).subscribe( (res: any) => {
      if (!res.erro) {
        this.order.address.state  = res.uf;
        this.order.address.neighbourhood = res.bairro;
        this.order.address.city   = res.localidade;
        this.order.address.street = res.logradouro;
        this.order.address.complement = res.complemento;
      }
    });
  }
}
