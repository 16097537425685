import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-unit-selection',
  templateUrl: './unit-selection.component.html',
  styleUrls: ['./unit-selection.component.scss']
})
export class UnitSelectionComponent implements OnInit {

  @Input() product: any;
  @Output() unitSelected = new EventEmitter();

  constructor() { }

  options = []

  ngOnInit(): void {
    let unity = { label: "un" }

    let unit = this.product.unit
    let options = this.product.unit?.options || 'all'

    if(unit) {
      if(unit.provider == "un" || unit.sellUnit) {
        this.options.push(unity)
      }

      if(unit.provider != "un") {
        let u = Object.assign({}, unit, { label: unit.provider })
        this.options.push(u)
      }
    }else this.options.push(unity)


    this.options = this.options.filter((un) => (options == 'all' || options == un.label))

    if(!this.product.selectedUnit) {
      this.product.selectedUnit = this.options.length > 1 ? unit : this.options[0];
    }
  }

  selectUnit(unit) {
    this.product.selectedUnit = unit;
    this.unitSelected.emit(unit);
  }
}
