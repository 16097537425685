<div class="alert alert-warning" *ngIf="company?.prospect">
  <b>ATENÇÃO: </b>
  Seu cadastro esta em modo de testes, seus pedidos não serão encaminhados aos
  nossos fornecedores
</div>

<!-- Essa tela é dividida em dois ng-containers: uma para um pedido único, e outra para um pedido com 2 fornecedores -->

<div class="row p-3">
  <back-button [routerLink]="['/order', 'provider']" class="mb-2"></back-button>
  <!-- <div class="col-12 d-flex align-items-center justify-content-between">
    <div class="d-flex pointer" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
      <p class="mr-2 mb-0">{{ order.products?.length }} Itens</p>
      <span *ngIf="isCollapsed" inlineSVG="/assets/icons/arrow-down.svg"></span>
      <span *ngIf="!isCollapsed" inlineSVG="/assets/icons/arrow-up.svg"></span>
    </div>
  </div> -->

  <div class="col-12 p-0">
    <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
      <ng-container *ngFor="let product of order?.offers">
        <div
          class="row item-wrapper m-0 px-0 py-3 border-bottom"
          *ngIf="!product.disabled"
        >
          <div class="col-md-6 d-flex align-items-center">
            <img
              class="img-lg"
              [src]="product.mainImage || '/assets/icons/avatar.svg'"
            />
            <h6 class="ml-2 mb-0 mt-1 text-left">
              <div>{{ product.name || product.product?.name }}</div>
              <small>{{ product.brand || product.product?.brand }}</small>
            </h6>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <div>
              {{ product.amount }}
              {{ product.selectedUnit?.label || product.unit?.label || "un" }}
            </div>
            <div>
              {{ getPrice(product, true) | currency: "BRL" }}
            </div>
            <div class="text-green weight-medium">
              {{ getPrice(product) | currency: "BRL" }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="text-right w-100 mt-3">
    <div>
      ESTE VALOR PODERÁ SOFRER VARIAÇÃO CORRESPONDENTE A PESAGEM FINAL DAS
      UNIDADES PEDIDAS
    </div>
    <div>
      Total:
      <span class="tag ml-2">{{ order?.total | currency: "BRL" }}</span>
    </div>
  </div>

  <div class="col-12 my-3 p-0">
    <div
      class="item-wrapper pointer bg-secondary p-3 mx-0 my-2 d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <img
          *ngIf="order?.provider?.logo"
          [src]="order?.provider?.logo"
          class="img-lg rounded-circle mr-2"
        />
        <h5 class="m-0 text-white">
          {{ order?.provider?.name }}
        </h5>
      </div>
    </div>
  </div>

  <div class="col-12 p-0">
    <h5 class="m-0 mb-2">Endereço de entrega</h5>

    <div class="d-flex flex-column flex-md-row">
      <div class="item-wrapper border bg-light rounded d-flex p-3 m-0 mb-2 mr-md-2">
        <div [inlineSVG]="'assets/icons/delivery.svg'" class="bg-secondary rounded-circle icon-white img-lg icon-sm d-flex justify-content-center align-items-center"></div>
        <div class="d-flex flex-column flex-grow-1 align-items-end pl-4">
          <!--<small class="weight-medium text-medium">Endereço de entrega</small> -->
          <span class="address weight-medium font-md">
            {{ order?.address?.street }},
            {{ order?.address?.number }} <br />
            <span *ngIf="order?.address?.complement">{{ order?.address?.complement }} - </span>
            {{ order?.address?.neighbourhood }}<br />
            {{ order?.address?.city }}, {{ order?.address?.state }}<br />
            CEP: {{ order?.address?.cep | mask: "00000-000" }}<br />
            {{ order?.address?.reference }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 p-0 mt-3" *ngIf="company?.type == 'pf'">
    <h5 class="m-0 mb-2">Forma de pagamento</h5>

    <div class="col-12 p-0" *ngIf="company?.type == 'pf'">
      <div class="d-flex align-items-center flex-wrap row">
        <div class="col-12 col-sm-6">
          <h6>Na Entrega</h6>
          <div class="d-flex align-items-center mr-2">
            <input
              type="radio"
              [(ngModel)]="order.payment"
              name="payment"
              value="money"
              id="money"
            />
            <label for="money">Dinheiro</label>
          </div>

          <div class="d-flex align-items-center mr-2">
            <input
              type="radio"
              [(ngModel)]="order.payment"
              name="payment"
              value="credit_card"
              id="credit_card"
            />
            <label for="credit_card">Cartão de crédito / Débito</label>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <h6>Antecipado</h6>
          <div class="d-flex align-items-center mr-2">
            <input
              type="radio"
              [(ngModel)]="order.payment"
              name="payment"
              value="transfer"
              id="transfer"
            />
            <label for="transfer">Transferência / PIX</label>
          </div>

          <div class="d-flex align-items-center mr-2">
            <input
              type="radio"
              [(ngModel)]="order.payment"
              name="payment"
              value="link"
              id="link"
            />
            <label for="link">Boleto ou Cartão - Link</label>
          </div>
        </div>
      </div>
    </div>
    <div class="error" *ngIf="error.payment">
      Selecione a forma de pagamento
    </div>
  </div>

  <div class="col-xs-12 col-md-6 pr-md-3 p-0 mt-3">
    <div class="form-group">
      <h2>Selecione a data de entrega</h2>
      <button
        bsDatepicker
        (bsValueChange)="order.date = $event"
        [bsValue]="order.date"
        [bsConfig]="{ showWeekNumbers: false, selectFromOtherMonth: true, dateInputFormat: 'DD/MM/YYYY' }"
        [minDate]="minDate"
        [daysDisabled]="daysDisabled"
        class="datepicker-btn"
        placement="top"
      >
        {{
          order?.date
            ? (order.date | date: "dd/MM/yyyy")
            : "Clique aqui para selecionar uma data de entrega"
        }}
      </button>
      <div class="error" *ngIf="error.date">Selecione a data de entrega</div>
      <small>Data preferível para entrega</small>
    </div>

    <div class="col-12 p-0 mt-3 prewrap">
      <p *ngIf="company?.type == 'pj'">
        {{ order?.provider?.rules }}
      </p>
      <p *ngIf="company?.type == 'pf'">
        {{ order?.provider?.rulesPF }}
      </p>
    </div>
  </div>

  <div class="col-xs-12 col-md-6 p-0 mt-3">
    <div class="form-group">
      <h2>Observações</h2>
      <textarea
        type="text"
        class="form-control"
        [(ngModel)]="order.comment"
        rows="4"
      ></textarea>
    </div>
    <div class="form-group" *ngIf="company?.type == 'pf'">
      <h2>Escola amiga</h2>
      <input type="text" class="form-control" [(ngModel)]="reference" />
    </div>

    <div class="row">
      <div class="form-group col-12">
        <h2>Cupom de desconto</h2>
        <input type="text" class="form-control" [(ngModel)]="cupom" (blur)="validateCupom()" />
        <div class="error" *ngIf="error.cupom">
          Cupom inválido!
        </div>
        <small *ngIf="order?.cupom">
          * Válido apenas para a primeira compra em cada segmento
        </small>
        <div [inlineSVG]="'/assets/icons/check.svg'" class="cupom valid" *ngIf="order?.cupom"></div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-md-6 offset-md-3" *ngIf="company?.address?.street">
    <h2>&nbsp;</h2>
    <action-button label="{{order.next ? 'Próximo':'Finalizar pedido'}}" (click)="save()"></action-button>
  </div>
</div>

<!-- <div class="row mt-5 px-0">
  <div class="col-12 col-md-6 text-center my-2 my-md-0 p-0 pr-md-2 mb-5 mb-sm-2">
    <div class="continue-btn bg-highlight rounded hover pointer text-secondary-alt weight-medium font-md py-3" [routerLink]="['/category', order?.category]">
      CONTINUAR COMPRANDO
    </div>
  </div>
</div> -->
