import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loading = new ReplaySubject();

  constructor() { }

  show() { this.loading.next(true);   }
  hide() { this.loading.next(false);  }
}
