<form class="my-5 text-left" #formVar="ngForm">
    <h4 class="form-title">Criar nova senha</h4>
    <div class="my-2 p-2 rounded bg-light d-flex align-items-center" *ngIf="success || error">
        <ng-container *ngIf="success">
            <div [inlineSVG]="'/assets/icons/confirm.svg'" class="d-flex mr-2"></div>
            <small class="weight-medium">Senha redefinida com sucesso</small>
        </ng-container>
        <ng-container *ngIf="error">
            <div [inlineSVG]="'/assets/icons/cancel.svg'" class="d-flex mr-2"></div>
            <small class="weight-medium error">Ocorreu um erro</small>
        </ng-container>
    </div>
    <div class="form-group">
        <label for="password" [class.error]="password.invalid && password.touched || checkPassword(password, confirm)">
            Nova senha
        </label>
        <input type="password" class="form-control" id="password" placeholder="******" name="password" [(ngModel)]="user.password" #password="ngModel" required minlength="6" [class.error]="password.invalid && password.touched || checkPassword(password, confirm)">
        <div *ngIf="!checkPassword(password, confirm) && password.invalid && (password.dirty || password.touched)" class="error">
            <div *ngIf="password.errors.required">
                Este campo é obrigatório
            </div>
            <div *ngIf="password.errors.minlength">
                Senha muito curta
            </div>
        </div>
        <div class="error" *ngIf="checkPassword(password, confirm)">
            Senhas são diferentes
        </div>
    </div>
    <div class="form-group">
        <label for="confirm" [class.error]="confirm.invalid && confirm.touched">
            Confirmar nova senha
        </label>
        <input type="password" class="form-control" id="confirmPassword" placeholder="******" name="confirmPassword" [(ngModel)]="user.confirmPassword" #confirm="ngModel" required [class.error]="confirm.invalid && confirm.touched">
        <div *ngIf="confirm.invalid && (confirm.dirty || confirm.touched)" class="error">
            <div *ngIf="confirm.errors.required">
                Este campo é obrigatório
            </div>
        </div>
    </div>
    <button [disabled]="formVar.invalid || (user.password != user.confirmPassword)" class="btn mw-100 button primary mt-3" (click)="resetPassword()">
        Alterar Senha
    </button>
    <div class="text-left mt-2">
        <a class="form-link" routerLink="/">Voltar para o login</a>
    </div>
</form>