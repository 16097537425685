<nav
  class="navbar navbar-expand-lg fixed-top"
  [ngClass]="{ mobile: navbarOpen }"
>
  <ng-container *ngIf="!navbarOpen">
    <a class="navbar-brand" routerLink="{{ me?.admin ? '/' : '/order' }}">
      <img
        src="/assets/images/logo-izza-icon.png"
        class="d-none d-lg-inline-block img-lg"
        alt="logo izza"
      />
      <img
        src="/assets/images/logo.png"
        class="d-inline-block d-lg-none img-lg"
        alt="logo izza"
      />
    </a>

    <button
      class="navbar-toggler ml-auto"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleNavbar()"
    >
      <div [inlineSVG]="'/assets/icons/menu.svg'"></div>
    </button>
  </ng-container>

  <div
    class="collapse navbar-collapse"
    id="navbarNav"
    [ngClass]="{ show: navbarOpen }"
  >
    <ul class="navbar-nav w-100 d-flex justify-content-center text-center">
      <li *ngIf="navbarOpen" class="nav-item header" (click)="toggleNavbar()">
        <img
          src="/assets/images/logo-izza-icon.png"
          class="d-inline-block d-lg-none img-lg"
          alt="logo izza"
        />
        <div [inlineSVG]="'/assets/icons/close-menu.svg'" class="ml-auto"></div>
      </li>

      <li
        class="d-flex align-items-center company"
        *ngIf="me?.manager && !me.admin && company?.name"
      >
        <a
          *ngIf="navbarOpen"
          class="align-items-center navbar-brand text-left p-0 mw-100"
        >
          <img
            [src]="company?.logo || '/assets/icons/avatar.svg'"
            alt="logo izza"
            class="mr-2 rounded-circle img-xl"
          />
          <span class="text-wrap">{{ company?.name }}</span>
          <div [inlineSVG]="'/assets/icons/edit.svg'" class="ml-2"></div>
        </a>
      </li>
      <!-- <li *ngIf="me?.admin" class="nav-item ml-lg-auto" routerLink="./home" routerLinkActive="active" (click)="toggleNavbar()">
        <div class="navbar-item-icon" [inlineSVG]="'/assets/icons/home.svg'"></div>
        <span class="nav-item-name"> Início </span>
      </li> -->
      <li
        class="nav-item d-flex d-lg-none"
        *ngIf="me?.manager && !me.admin"
        (click)="toggleSchools()"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/school.svg'"
        ></div>
        <span class="nav-item-name"> Escolas </span>
        <div
          class="icon-xs icon-white d-flex align-items-center ml-3"
          [ngClass]="{ 'mr-3 rotated': schoolsCollapsed }"
          [inlineSVG]="'/assets/icons/arrow-down.svg'"
        ></div>
      </li>
      <li class="nav-item d-flex d-lg-none p-0">
        <div
          id="collapseBasic"
          class="mr-0 w-100"
          [isAnimated]="true"
          [collapse]="!schoolsCollapsed"
          #collapse="bs-collapse"
        >
          <div
            class="w-100 d-flex align-items-center py-2 px-4"
            *ngFor="let school of companies?.data"
            (click)="select(school); toggleNavbar(); toggleSchools()"
          >
            <img
              class="img-sm rounded-circle mr-2"
              [src]="school.logo || '/assets/icons/avatar.svg'"
            />
            <small class="weight-medium text-white">{{
              school.name | uppercase
            }}</small>
          </div>
        </div>
      </li>
      <li
        class="nav-item ml-lg-auto"
        routerLink="/home"
        routerLinkActive="active"
        (click)="toggleNavbar()"
        *ngIf="me?.manager && !me.admin"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/home.svg'"
        ></div>
        <span class="nav-item-name"> Novo Pedido </span>
      </li>
      <li
        class="nav-item"
        [class.ml-lg-auto]="!me?.manager"
        routerLink="./order"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="toggleNavbar()"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/receipt.svg'"
        ></div>
        <span class="nav-item-name">
          {{ me?.admin ? "Pedidos" : "Meus Pedidos" }}
        </span>
      </li>
      <li
        class="nav-item d-flex d-lg-none"
        routerLink="/order/confirm"
        routerLinkActive="active"
        (click)="toggleNavbar()"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="me?.manager && !me.admin"
      >
        <div
          class="navbar-item-icon"
          [class.disabled]="me?.manager && !me?.admin && !company?.name"
          [inlineSVG]="'/assets/icons/shopping-bag.svg'"
        ></div>
        <span class="nav-item-name"> Sacola </span>
      </li>
      <li
        *ngIf="me?.admin"
        class="nav-item"
        routerLink="./client"
        routerLinkActive="active"
        (click)="toggleNavbar()"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/people.svg'"
        ></div>
        <span class="nav-item-name"> Clientes </span>
      </li>
      <li
        *ngIf="!me?.admin && company.type == 'pf'"
        class="nav-item"
        routerLink="./client/{{company._id}}/edit"
        routerLinkActive="active"
        (click)="toggleNavbar()"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/settings.svg'"
        ></div>
        <span class="nav-item-name"> Cadastro </span>
      </li>
      <li
        *ngIf="me?.admin"
        class="nav-item"
        routerLink="./provider"
        routerLinkActive="active"
        (click)="toggleNavbar()"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/truck.svg'"
        ></div>
        <span class="nav-item-name"> Fornecedores </span>
      </li>
      <li
        *ngIf="me?.admin"
        class="nav-item"
        routerLink="./product"
        routerLinkActive="active"
        (click)="toggleNavbar()"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/cart.svg'"
        ></div>
        <span class="nav-item-name"> Produtos </span>
      </li>
      <!-- <li class="nav-item" [class.disabled]="me?.manager && !me?.admin && !company?.name" routerLink="./user-management" routerLinkActive="active" (click)="toggleNavbar()">
        <div class="navbar-item-icon" [inlineSVG]="'/assets/icons/people.svg'"></div>
        <span class="nav-item-name"> Usuários </span>
      </li> -->
      <li
        class="nav-item mr-auto"
        routerLink="./profile"
        routerLinkActive="active"
        (click)="toggleNavbar()"
      >
        <div
          class="navbar-item-icon"
          [inlineSVG]="'/assets/icons/profile.svg'"
        ></div>
        <span class="nav-item-name"> Perfil </span>
      </li>

      <div
        *ngIf="me?.manager && !me?.admin"
        class="d-none d-lg-flex align-items-center my-auto"
      >
        <a
          [class.disabled]="
            (me?.manager && !me?.admin && !company?.name) ||
            cart?.products?.length === 0
          "
          class="navbar-cart my-auto mr-3"
          [routerLink]="['order/provider']"
        >
          <div
            class="d-flex icon-lg"
            [inlineSVG]="'/assets/icons/shopping-bag.svg'"
          ></div>
          <div
            class="item-indicator weight-medium"
            *ngIf="cart?.products?.length > 0"
          >
            {{ cart.products.length }}
          </div>
        </a>
        <a class="navbar-brand d-flex my-auto pointer">
          <img
            [src]="company?.logo || '/assets/icons/avatar.svg'"
            [class.disabled]="me?.manager && !me?.admin && !company?.name"
            alt="logo izza"
            [routerLink]="company?.name ? ['school', company._id] : null"
            class="img-lg rounded-circle hover-fade"
          />
          <div class="btn-group" dropdown>
            <button
              dropdownToggle
              type="button"
              class="dropdown-toggle pointer"
              aria-controls="dropdown-basic"
            >
              <div
                class="d-flex icon-xs icon-light my-auto ml-1 hover-fade"
                [inlineSVG]="'/assets/icons/arrow-down.svg'"
              >
                <!-- (click)="dropdownOpen = !dropdownOpen" [class.rotated]="dropdownOpen" -->
              </div>
            </button>

            <ul
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-basic"
            >
              <div class="schools-list">
                <li role="menuitem" *ngFor="let school of companies?.data">
                  <div
                    class="dropdown-item d-flex align-items-center pointer"
                    [ngClass]="{ selected: school._id === company._id }"
                    (click)="select(school)"
                  >
                    <img
                      class="img-sm rounded-circle mr-2"
                      [src]="school.logo || '/assets/icons/avatar.svg'"
                    />
                    <span
                      [title]="school.name"
                      class="school-name text-primary weight-medium"
                    >
                      {{ school.name }}
                    </span>
                  </div>
                </li>
              </div>
              <li class="dropdown-divider"></li>
              <li role="menuitem" (click)="logout()">
                <a class="dropdown-item d-flex align-items-center" href="#">
                  <div
                    class="icon-medium icon-sm d-flex align-items-center mr-2"
                    [inlineSVG]="'/assets/icons/log-out.svg'"
                  ></div>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </a>
        <!-- <a class="navbar-brand my-auto" (click)="logout()">
          <div class="navbar-item-icon" [setSVGAttributes]="{width: 22}" [inlineSVG]="'/assets/icons/log-out.svg'"></div>
        </a> -->
      </div>

      <li
        class="nav-item"
        (click)="logout()"
        [class.d-lg-none]="me?.manager && !me.admin"
      >
        <div
          class="navbar-item-icon"
          [setSVGAttributes]="{ width: 22 }"
          [inlineSVG]="'/assets/icons/log-out.svg'"
        ></div>
        <span class="nav-item-name"> Sair </span>
      </li>
    </ul>
  </div>
</nav>

<div class="overlay" *ngIf="navbarOpen" (click)="toggleNavbar()"></div>
