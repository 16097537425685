<div class="item-wrapper bg-light p-3 pointer mb-2" [routerLink]="[school._id]">
  <div class="d-flex align-items-center justify-content-start">
    <img *ngIf="school.logo" [src]="school.logo" class="profile size-30">
    <img *ngIf="!school.logo" [src]="'/assets/icons/avatar.svg'" class="profile rounded-0">
    <h2 class="ml-2 mb-0">{{ school.name }}</h2>
    <div class="btn btn-sm btn-success ml-auto" (click)="buy(school, $event)">+ Pedido</div>
  </div>
  <div class="d-flex align-items-center justify-content-start mt-2">
    <span class="text-medium">{{ school.email }}</span>
  </div>
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <span class="text-medium">{{ school?.director?.name }}</span>
      <span class="text-medium ml-1">{{ school?.director?.phone |  mask:'(00) 0 0000-0000' }}</span>
    </div>
    <div class="d-flex align-items-center actions">
      <div
        class="action-btn icon-lg"
        [inlineSVG]="'/assets/icons/edit-lg.svg'"
        (click)="$event.stopPropagation();"
        [routerLink]="[school._id, 'edit']">
      </div>
      <div
        class="action-btn icon-lg ml-1"
        [inlineSVG]="'/assets/icons/cancel-lg.svg'"
        (click)="openModal($event, school._id)">
      </div>
    </div>
  </div>
</div>