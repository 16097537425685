import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  user: any = { isNew: true };
  error: any = false
  token: string;

  constructor(private userService: UserService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(query => {
      if(!this.token) return
      
      this.token = query.get('token');
      const payload = JSON.parse(atob(this.token.split('.')[1]));
      
      this.userService.get(payload._id).subscribe((u: any) => {
        this.user.email = u.email;
        this.user.name = u.name;
      }, e => {})
    });
  }

  signup() {
    this.error = {}
    this.userService.setToken(this.token);

    let service = this.userService.create(this.user)
    
    if(!this.user.isNew) {
      this.user.first = true;
      service = this.userService.edit(this.user)
    }

    if(this.user.password !== this.user.repeat) {
      this.error = { password: true }
      return
    } 

    let { repeat, ...data } = this.user
    
    service.subscribe((data: any) => {
      this.userService.login(this.user).subscribe(res => {
        this.router.navigate(['/home']);
      });
    }, err => {
      this.error = { email: true }
    });
  }

}
