import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { UserService } from "src/app/shared/services/user.service";
import { CompanyService } from "src/app/shared/services/company.service";
import { ImageInput } from "src/app/shared/classes/ImageInput";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  me: any;
  file;
  preview;
  company;

  @ViewChild("photoInput", { static: false }) photoInput: ElementRef;
  @ViewChild("photoPreview", { static: false }) previewEl;

  constructor(
    private service: UserService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.getMe();

    this.companyService.getCompany().subscribe((company) => {
      this.companyService.get(company._id).subscribe((company) => {
        this.company = company;
      });
    });
  }

  ngAfterViewInit() {
    const photoInput = new ImageInput({ input: this.photoInput.nativeElement });
    photoInput.fileChange().subscribe(({ file, base64 }) => {
      this.file = file;
      this.preview = base64;
    });
  }

  getMe() {
    this.service.me().subscribe((me) => {
      this.me = me;
    });
  }

  save() {
    if (this.me.newPassword != this.me.confirmPassword) return;

    this.service.edit(this.me).subscribe((me) => {
      this.service.setMe(me);

      if (this.file) this.service.upload(this.file).subscribe(() => {});
    });
  }
}
