<div class="container">
  <div class="col-12 col-lg-8 offset-lg-2 d-flex flex-wrap mb-3 p-2">
    <div class="col-12 px-2">
      <h4 class="mb-3 px-2">Editar perfil</h4>
    </div>
    <div class="col-12">
      <form #formVar="ngForm">
        <div id="school" class="content">
          <div class="row">
            <div class="col-12 d-flex justify-content-center my-4">
              <label for="photo">
                <img
                  [src]="preview || me?.photo || 'assets/icons/avatar.svg'"
                  class="photo rounded-circle pointer"
                  #photoPreview
                />
              </label>
              <input
                type="file"
                name="photo"
                id="photo"
                class="d-none"
                #photoInput
              />
            </div>
            <div class="col-md-12 mb-3">
              <label for="name">Nome Completo</label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                [(ngModel)]="me && me.name"
                #name="ngModel"
                required
              />
              <div
                *ngIf="name.invalid && (name.dirty || name.touched)"
                class="error"
              >
                <div *ngIf="name.errors.required">Este campo é obrigatório</div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label
                for="phone"
                [class.error]="phone.invalid && (phone.dirty || phone.touched)"
              >
                Telefone
              </label>
              <input
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                [class.error]="phone.invalid && (phone.dirty || phone.touched)"
                [(ngModel)]="me && me.phone"
                #phone="ngModel"
                mask="(00) 0000-0000 || (00) 0 0000-0000"
              />
              <div
                *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                class="error"
              >
                <div *ngIf="phone.errors.required">
                  Este campo é obrigatório
                </div>
                <div *ngIf="phone.errors.mask">
                  O Telefone deve ter 10 dígitos
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label> E-mail </label>
              <input
                type="email"
                class="form-control"
                name="email"
                [ngModel]="me && me.email"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="pointer">Alterar Senha</label>
              <input
                type="password"
                class="form-control"
                name="password"
                [(ngModel)]="me && me.password"
                placeholder="Senha Atual"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <input
                type="password"
                class="form-control"
                name="newPassword"
                [(ngModel)]="me && me.newPassword"
                placeholder="Nova Senha"
              />
            </div>
            <div class="col-md-6 mb-3">
              <input
                type="password"
                class="form-control"
                name="confirmPassword"
                #confirmPassword="ngModel"
                [(ngModel)]="me && me.confirmPassword"
                placeholder="Repita a Senha"
              />
            </div>
            <div
              *ngIf="confirmPassword.dirty || confirmPassword.touched"
              class="col-md-6 error"
            >
              <div *ngIf="me.confirmPassword != me.newPassword">
                Senhas são diferentes
              </div>
            </div>
          </div>

          <!-- <div class="row my-3" *ngIf="company?.limit > 0">
            <div class="col-12">
              <h2>Crédito</h2>
            </div>
            <div class="col-12">
              <span class="text-medium weight-medium">
                Limite: {{ company.limit | currency: "BRL" }}
              </span>
            </div>
            <div class="col-12">
              <span class="text-medium weight-medium"
                >Pedidos em aberto:
                {{ company.creditBill | currency: "BRL" }}
              </span>
            </div>
          </div> -->

          <div class="text-center mt-4 p-0">
            <action-button
              label="Salvar alterações"
              (click)="save()"
              routerLink="/order"
              class="w-100"
            ></action-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
