import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  me: any = {};
  companies: any = [];
  selected;

  constructor(private userService: UserService,
              private service: CompanyService) { }

  ngOnInit() {
    this.selected = this.service.company;
    this.getMe();
    this.list();
  }

  getMe() {
    return this.userService.me()
    .subscribe((me: any) => this.me.name = 'Bem vindo(a), ' + me.name);
  }

  list() {
    return this.service.list()
    .subscribe((companies: any) => { this.companies = companies;});
  }

  updateImage(event) {
    event.target.classList.remove('rounded-circle');
    event.target.src = '/assets/icons/image.svg';
  }

  set(company) {
    this.selected = company;
    this.service.set(company);
  }
}