<div class="item-wrapper bg-light blue-highlight p-3 m-0">
  <img [src]="data.mainImage ? data.mainImage : data.images ? data.images[0] : data.logo" class="rounded-circle mb-2 pointer logo" routerLink="./{{ data._id }}" (error)="updateImage($event)" [class.rounded-circle]="data?.images || data?.logo">
  <p class="pointer item-name" routerLink="./{{ data._id }}/edit">{{ data.name }}</p>

  <p *ngIf="data.brand">
    {{ data.brand }}
  </p>
  <p *ngIf="data.phone" class="highlight">
    {{ data.phone | mask:'(00) 0000-0000' }}
  </p>
  <p *ngIf="data.whatsapp && !data.phone" class="highlight">
    {{ data.whatsapp | mask:'(00) 0 0000-0000' }}
  </p>

  <div class="d-flex align-items-center actions justify-content-center">
    <img class="action-btn mt-2" src="/assets/icons/edit-lg.svg" routerLink="./{{ data._id }}/edit">
    <img class="action-btn ml-2 mt-2" src="/assets/icons/cancel.svg" (click)="openModal(data._id)">
  </div>
</div>