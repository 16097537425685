<div class="row mb-3">
  <div class="col-md-12 header">
    <h3 class="mb-3">
      {{ title }}
      <add-btn *ngIf="link" [routerLink]="link">+</add-btn>
    </h3>
    
    <div class="search-box" *ngIf="search">
      <form>
        <input
          (keyup)="onInputChange($event)"
          (change)="onInputChange($event)"
          type="search"
          name="search"
          placeholder="Buscar {{title | lowercase}}"
          class="bg-light icon form-control"
        />
      </form>
    </div>
  </div>
</div>
