import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest } from 'rxjs';

import { OrderService } from 'src/app/shared/services/order.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ExportService } from 'src/app/shared/services/export.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ConfirmWindowComponent } from 'src/app/shared/ui/confirm-window/confirm-window.component';
import { map } from 'rxjs/operators';
import { Query } from 'src/app/shared/util/query';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  query = new Query();
  orders: any = [];
  company: any;
  me: any;
  routeId: any;
  modalRef: BsModalRef;
  order: any = {};
  loading = true;

  status = { 
    'w_waiting': { class: 'bg-gray text-medium', icon: '/assets/icons/analysis.svg', label: 'Realizado' },
    'i_accepted': { class: 'bg-orange text-white', icon: '/assets/icons/delivery.svg', label: 'Confirmado' },
    'i_inprogress': { class: 'bg-blue text-white', icon: '/assets/icons/delivery.svg', label: 'Faturado' },
    'f_canceled': { class: 'bg-danger text-white', icon: '/assets/icons/way.svg', label: 'Cancelado' },
    'f_completed': { class: 'bg-green text-white', icon: '/assets/icons/complete.svg', label: 'Finalizado' }
  };

  constructor(private service: OrderService,
              private userService: UserService,
              private companyService: CompanyService,
              private modalService: BsModalService,
              private exportService: ExportService,
              private route: ActivatedRoute) { }

  ngOnInit() {

    this.getOrder();
    this.routeId = this.route.snapshot.params.id;

    this.query.limit = 20
    
    const companyObserver = this.companyService.watchCompany();
    const userObserver = this.userService.me();
    combineLatest([companyObserver, userObserver]).pipe(
      map(results => ({ company: results[0], user:  results[1] }) )
    ).subscribe(({ company, user }) => {
      if (user.manager && !user.admin) this.query.search.company = company._id;
      this.company = company;
      this.me = user;
      this.list();
    });
  }

  list() {
    this.loading = true;
    return this.service.list(this.query).subscribe((orders) => {
      this.orders = orders;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  getOrder() {
    this.service.getCart().subscribe((order) => { this.order = order });
  }

  confirm(event, order) {
    event.preventDefault();
    event.stopPropagation();

    order.status = 'f_completed'

    this.service.save(order).subscribe(e => this.list())
  }

  export() {
    this.exportService.getAndExport('Pedidos', 'report/order')
  }

}
