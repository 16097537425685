import { UserService } from 'src/app/shared/services/user.service';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private userService: UserService) { }

  canActivate() {
    if (!this.userService.TOKEN) {
      this.router.navigateByUrl('/user/signin');
      return false;
    }

    return true;
  }
}
