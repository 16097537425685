import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[pw]'
})
export class PasswordDirective {

  private show = false;

  constructor(private element: ElementRef) {
    this.setup();
  }

  setup() {
    const parent = this.element.nativeElement.parentNode;
    const div = document.createElement('span');
    const icon = document.createElement("img");
    icon.setAttribute("src", "/assets/icons/eye.svg");
    icon.setAttribute("width", "100%");
    div.appendChild(icon);
    div.setAttribute("class", "show-password");
    icon.addEventListener('click', (e) => { this.toggle(icon); });
    parent.appendChild(div);
  }

  toggle(icon: HTMLElement) {
    this.show = !this.show;
    if (this.show) {
      this.element.nativeElement.setAttribute('type', 'text');
      icon.setAttribute("src", "/assets/icons/eye-closed.svg");
    } else {
      this.element.nativeElement.setAttribute('type', 'password');
      icon.setAttribute("src", "/assets/icons/eye.svg");
    }
  }
}