<div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 d-flex flex-wrap p-3">
  <div class="col-12 p-0 mb-2">
    <back-button [routerLink]="['/order', 'confirm']"></back-button>
  </div>

  <div class="col-12 p-0 mb-2">
    <h5 class="m-0">Outro endereço</h5>
  </div>
  <form #newAddress="ngForm" class="w-100">
    <div class="row w-100 py-2 m-0">
      <div class="col-12 mb-3 p-0">
        <label [class.error]="cep.invalid && (cep.dirty || cep.touched)" for="cep">
          CEP
        </label>
        <input [class.error]="cep.invalid && (cep.dirty || cep.touched)" type="text" class="form-control" id="cep" name="cep" [(ngModel)]="order.address.cep" #cep="ngModel" (change)="getCep($event, cep.valid)" mask="00000-000" required>
        <div *ngIf="cep.invalid && (cep.dirty || cep.touched)" class="error">
          <div *ngIf="cep.errors.required">
            Este campo é obrigatório
          </div>
          <div *ngIf="cep.errors.mask">
            O CEP deve ter 8 dígitos
          </div>
        </div>
      </div>

      <div class="col-12 mb-3 p-0">
        <label [class.error]="street.invalid && (street.dirty || street.touched)" for="street">
          Rua/Logradouro
        </label>
        <input [class.error]="street.invalid && (street.dirty || street.touched)" type="text" class="form-control" id="street" name="street" [(ngModel)]="order.address.street" #street="ngModel" required>
        <div *ngIf="street.invalid && (street.dirty || street.touched)" class="error">
          <div *ngIf="street.errors.required">
            Este campo é obrigatório
          </div>
        </div>
      </div>

      <div class="col-8 mb-3 p-0 pr-3">
        <label [class.error]="city.invalid && (city.dirty || city.touched)" for="city">
          Cidade
        </label>
        <input disabled [class.error]="city.invalid && (city.dirty || city.touched)" type="text" class="form-control" id="city" name="city" [(ngModel)]="order.address.city" #city="ngModel" required>
        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="error">
          <div *ngIf="city.errors.required">
            Este campo é obrigatório
          </div>
        </div>
      </div>

      <div class="col-4 mb-3 p-0">
        <label [class.error]="state.invalid && (state.dirty || state.touched)" for="state">
          UF/Estado
        </label>
        <input disabled [class.error]="state.invalid && (state.dirty || state.touched)" type="text" class="form-control" id="state" name="state" [(ngModel)]="order.address.state" #state="ngModel" required>
        <div *ngIf="state.invalid && (state.dirty || state.touched)" class="error">
          <div *ngIf="state.errors.required">
            Este campo é obrigatório
          </div>
        </div>
      </div>

      <div class="col-4 mb-3 p-0 pr-3">
        <label [class.error]="number.invalid && (number.dirty || number.touched)" for="number">
          Número
        </label>
        <input [class.error]="number.invalid && (number.dirty || number.touched)" type="text" class="form-control" id="number" name="number" [(ngModel)]="order.address.number" #number="ngModel" required>
        <div *ngIf="number.invalid && (number.dirty || number.touched)" class="error">
          <div *ngIf="number.errors.required">
            Este campo é obrigatório
          </div>
        </div>
      </div>

      <div class="col-8 mb-3 p-0">
        <label [class.error]="complement.invalid && (complement.dirty || complement.touched)" for="complement">
          Complemento
        </label>
        <input [class.error]="complement.invalid && (complement.dirty || complement.touched)" type="text" class="form-control" id="complement" name="complement" [(ngModel)]="order.address.complement" #complement="ngModel" required>
        <div *ngIf="complement.invalid && (complement.dirty || complement.touched)" class="error">
          <div *ngIf="complement.errors.required">
            Este campo é obrigatório
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="col-12 p-0">
    <action-button [label]="'Confirmar'" class="w-100" (click)="save()"></action-button>
  </div>
</div>
