import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDate, CurrencyPipe } from '@angular/common';

import { UserService } from 'src/app/shared/services/user.service';
import { OrderService } from 'src/app/shared/services/order.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OrderEdit } from 'src/app/shared/ui/order-edit/order-edit.component';

import getProductPrice from 'src/app/shared/util/getProductPrice';
import getOrderTotal from 'src/app/shared/util/getOrderTotal';

@Component({
  selector: 'app-order-print',
  templateUrl: './order-print.component.html',
  styleUrls: ['./order-print.component.scss']
})
export class OrderPrintComponent implements OnInit {

  routeId;
  order: any = {};
  
  confirm = false;
  printButton = true;
  
  sameAddress;
  modalRef;

  getPrice = getProductPrice;
  getTotal = getOrderTotal;

  constructor(
    private userService: UserService,
    private service: OrderService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private currency: CurrencyPipe) {
      userService.showMenu.next(false);
    }

  ngOnInit() {
    this.routeId = this.route.snapshot.params.id;
    this.confirm = this.route.snapshot.queryParams.confirm;

    this.get()
  }

  get() {
    this.service.get(this.routeId).subscribe((order: any) => {
      this.order = order;
      this.order.date = new Date(this.order.date);

      this.sameAddress = (
        !order.address?.street ||
        (order.address?.street == order.client?.address?.street) &&
        (order.address?.number == order.client?.address?.number) 
      )

      this.order.offers.sort((a, b) => (a.offer?.product.name > b.offer?.product.name ? 1:-1))
      
      document.title = `IZZA #${this.order.number || ''} - ${this.order.client.name} (${formatDate(this.order.date, 'dd/MM/yyyy', 'en-US')})`;
    });
  }

  getUnitaryPrice(offer, show = 'un') {
    let price = 0;
    let unity = offer.unit;

    if(unity.label == show) price = offer.price

    if(unity.label != 'un' && show == 'un') {
      price = offer.price * unity.amount
    }
    if(price > 0) {
      return this.currency.transform(price, 'R$', 'symbol', '1.2-2');
    }else return '-'
  }

  payment() {
    return this.order.client.paymentMethod || 'Faturado'
  }
  
  countItems() {
    let count = 0
    this.order.offers &&
    this.order.offers.forEach(offer => {
      count += offer.amount
    });

    return count
  }

  openModal() {
    this.modalRef = this.modalService.show(OrderEdit, {
      initialState: { 
        id: this.order._id,
        nfe: this.order.nfe,
        date: this.order.date,
        nfeDate: this.order.date,
        total: this.order.total,
        nfeTotal: this.order.nfeTotal,
        number: this.order.number,
        status: 'i_inprogress',
        mode: 'provider'
      }
    })

    this.modalService.onHide.subscribe((r) => {
      this.get()
    })
  }

  accept() {
    if(this.confirm && this.order.status == 'w_waiting') {
      this.service.changeStatus(this.routeId, { status: 'i_accepted', total: this.order.total }).subscribe((s) => {
        this.order.status = 'i_accepted'
      }, () => {});
    }
  }

  print() {
    window.print()
  }
}
