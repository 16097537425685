<div class="row">
  <div class="col-12 px-2 mb-2">
    <back-button
      [routerLink]="['/home', order.category, order.subcategory]"
      class="mb-2"
    ></back-button>
  </div>
  <div class="col-12">
    <h2 class="mb-0">Sacola</h2>
    <!-- <span class="tag mr-auto ml-2" *ngIf="selectedOffer">
      {{ selectedOffer.total | currency:'BRL' }}
    </span> -->

    <!-- <div class="d-flex pointer" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
      <p class="mr-2 mb-0">{{ order.products?.length }} Itens</p>
      <div *ngIf="isCollapsed" inlineSVG="/assets/icons/arrow-down.svg"></div>
      <div *ngIf="!isCollapsed" inlineSVG="/assets/icons/arrow-up.svg"></div>
    </div> -->
  </div>

  <div class="col-12">
    <div
      id="collapseBasic"
      class="cart"
      [collapse]="isCollapsed"
      [isAnimated]="true"
    >
      <div
        class="border-bottom item py-2 row"
        *ngFor="let product of order?.products; index as i"
      >
        <div class="col-lg-6">
          <div class="d-flex align-items-center">
            <img
              [class.disabled]="product.disabled"
              class="img-lg"
              [src]="product.mainImage || '/assets/icons/avatar.svg'"
            />
            <h6 [class.disabled]="product.disabled" class="m-0 pl-2 pr-1">
              <div>
                {{ product.name || product.product?.name }}
              </div>
              <small>{{ product.brand || product.product?.brand }}</small>
            </h6>

            <app-unit-selection
              class="d-flex d-lg-none ml-auto"
              [product]="product"
              (unitSelected)="calculateProvidersTotals()"
            ></app-unit-selection>
          </div>
        </div>
        <div class="col-lg-6 mt-2">
          <div class="d-flex justify-content-between">
            <div class="quant" *ngIf="!product.disabled">
              <div
                inlineSVG="/assets/icons/subtract.svg"
                [ngClass]="{
                  'subtract-color pointer': product && product.amount > 0
                }"
                class="ml-2 mt-2 subtract"
                (click)="updateAmount(product, -1)"
              ></div>
              <input
                type="number"
                class="form-control text-center"
                min="1"
                mask
                name="product"
                (change)="updateAmount(product)"
                [(ngModel)]="product && product.amount"
              />
              <div
                inlineSVG="/assets/icons/add.svg"
                class="pointer mt-2"
                (click)="updateAmount(product, 1)"
              ></div>
            </div>

            <app-unit-selection
              class="d-none d-lg-flex"
              [product]="product"
              (unitSelected)="calculateProvidersTotals()"
            ></app-unit-selection>

            <div class=""></div>
            <div class="price">
              <span class="small" *ngIf="!selectedOffer">
                Selecione o fornecedor
              </span>
              <div
                class="d-flex align-items-center"
                [class.disabled]="product.disabled"
                *ngIf="selectedOffer"
              >
                <span
                  class="unity"
                  *ngIf="!product.disabled && product.amount > 1"
                >
                  {{ getPrice(product, true) | currency: "BRL" }}
                </span>
                <span class="total" *ngIf="!product.disabled">
                  <img
                    class="best-price"
                    *ngIf="selectedOffer && isCheapest(product)"
                    src="/assets/icons/best-price.svg"
                    title="Melhor Preço"
                  />
                  {{ getPrice(product) | currency: "BRL" }}
                </span>
                <span class="disabled" *ngIf="product.disabled">
                  Em falta
                </span>
              </div>

              <div
                inlineSVG="/assets/icons/cancel.svg"
                title="Remover"
                class="ml-2 pointer"
                (click)="
                  product.amount = 0; updateAmount(product); getOffers(true)
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12" *ngIf="!company?.address?.street">
    <div
      class="alert alert-warning"
      routerLink="/client/{{ company._id }}/edit"
    >
      <b>ATENÇÃO: </b>
      Complete seu cadastro para finalizar suas compras.
    </div>
  </div>

  <div class="col-12 d-flex justify-content-between align-items-center my-3">
    <h2 class="m-0">Fornecedores</h2>
  </div>
  <div class="col-12 col-lg-6 mb-2" *ngIf="company?.bestOrder && allProvidersOffers?.length > 1">
    <div class="item-wrapper pointer bg-secondary p-2 mx-0 d-flex justify-content-between align-items-center best text-white"
      [class.selected]="selectedOffer?.isMultiOrder"
      (click)="selectOffer(bestOrder)">
      <div class="d-flex align-items-center mr-2">
        <img
            src="/assets/images/logo-izza-icon.png"
            class="img-lg rounded-circle mr-2"
          />
        <div class="text-left">
          <h5 class="m-0">econom<span class="text-green">izza</span></h5>
          <small *ngIf="bestOrder?.total && bestOrder?.providers?.length <= 1">Sem economia para este pedido.</small>
          <small *ngIf="bestOrder?.providers?.length > 1 && (allProvidersOffers[0].total - bestOrder?.total) > 0">Comprando em {{bestOrder?.providers?.length}} fornecedores.</small>
          <small *ngIf="allProvidersOffers[0].sort == 3">Pedido completo em {{bestOrder?.providers?.length}} fornecedores.</small>
        </div>
      </div>
      <div>
        <div class="text-highlight highlight" *ngIf="!bestOrder?.total || bestOrder?.providers?.length > 1">
          <span *ngIf="!bestOrder?.total">Calculando...</span>
          <span *ngIf="bestOrder?.total">{{ bestOrder?.total | currency: "BRL" }}</span>

          <div *ngIf="bestOrder?.total && (allProvidersOffers[0].total - bestOrder?.total) > 0" class="sub text-white">Economia de {{ (allProvidersOffers[0].total - bestOrder?.total)| currency: "BRL" }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 mb-2" *ngFor="let offer of allProvidersOffers">
    <div
      class="item-wrapper pointer bg-light p-2 mx-0 d-flex justify-content-between align-items-center"
      [class.best]="offer.sort == 1"
      [class.disabled]="offer.sort == 4"
      [class.missing]="offer.sort == 3"
      [class.selected]="selectedOffer?.provider?._id === offer.provider._id"
      (click)="selectOffer(offer)"
    >
      <div class="d-flex align-items-center mr-2">
        <img
          *ngIf="offer?.provider.logo"
          [src]="offer?.provider.logo"
          class="img-lg rounded-circle mr-2"
        />
        <div class="text-left">
          <h5 class="m-0">{{ offer?.provider.name }}</h5>
          <small>{{ getDayRanges(offer?.city?.days) }}</small>
        </div>
      </div>
      <div>
        <div class="text-highlight highlight">
          {{ offer?.total | currency: "BRL" }}

          <div class="sub">{{ offerStatus(offer) }}</div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-12 col-md-4 offset-md-4 mt-5"
    *ngIf="company?.address?.street"
  >
    <action-button
      [label]="isValidOffer() ? 'Continuar' : 'Selecione o fornecedor'"
      class="mx-auto"
      (click)="continue()"
      [disabled]="!isValidOffer()"
    >
    </action-button>
  </div>
</div>
