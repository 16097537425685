import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'terms-window',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  @Input() onYes;
  @Input() onNo;

  accept = false;

  constructor(public modalRef: BsModalRef,
              public service: UserService) {}

  confirm() {
    this.service.acceptTerms().subscribe(r => {
      this.onYes()
      this.modalRef.hide()
    })
  }
}
