export default function getPrice(product, unitary = false, offerPrice?) {
  const price = (offerPrice ? offerPrice : product.price);
  
  if (product.unit?.sellUnit || product.offer?.product?.unit?.sellUnit) {
    if (product.selectedUnit?.label === 'un') {
      return product.unit.amount * price * (unitary ? 1 : product.amount);
    } else if (!product.selectedUnit) {
      return product.unit.amount * price * (unitary ? 1 : product.amount);
    } else {
      return price * (unitary ? 1 : product.amount);
    }
  } else {
    return price * (unitary ? 1 : product.amount);
  }
};
