import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormStepper } from 'src/app/shared/classes/FormStepper';

import { ProductService } from 'src/app/shared/services/product.service';
import { OfferService } from 'src/app/shared/services/offer.service';
import { ProviderService } from 'src/app/shared/services/provider.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { Query } from 'src/app/shared/util/query';
import { FormControl, NgForm } from '@angular/forms';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  routeId;
  product: any = {
    address: { cep: '' },
    categories: [],
    unit: { provider: 'un', sellUnit: false },
    images: [null],
    tags: [],
    color: '#000'
  };

  titles: any = [];
  title: any = '';
  step: number = 0;

  mainFile = '';
  mainImage = '';
  fileImages: any = [];
  files: any = [];

  offers: any = [];
  providers: any = [];
  categories: any = [];

  @ViewChild('step1', { static: false }) step1: NgForm;
  stepper
  constructor(private route: ActivatedRoute,
              private router: Router,
              private loading: LoadingService,
              private service: ProductService,
              private offerService: OfferService,
              private providerService: ProviderService,
              private categoryService: CategoryService) { }

  ngOnInit() {
    this.routeId = this.route.snapshot.params.id;
    if (this.routeId) { this.get(); }

    while (this.fileImages.length < 5) {
      this.fileImages.push(undefined);
    }

    this.listCategories();
    this.listProviders();
    if (this.routeId) this.listOffers();

    this.stepper = new FormStepper(document.getElementById('stepper1'), { 
      linear: false,
      animation: false,
    });

    this.stepper.titles = document.querySelectorAll('.bs-stepper-desc');
    this.stepper.title = this.stepper.titles[this.stepper.step].innerText;
  }

  save() {
    this.service.save(this.product).subscribe((product: any) => {
      // if (!this.routeId) { this.routeId = product._id; }
      
      if (this.offers) {
        this.offers.forEach((offer) => {
          offer.price = parseFloat(offer.price);
          this.offerService.save(offer).subscribe((_) => {});
        });
      }
    });
  }

  saveProduct() {
    const validImg = this.fileImages.filter(f => !!f).length;
    const validOldImg = this.product.images.filter(f => !!f).length;
    
    if (this.product._id &&  validImg < validOldImg) {
      this.product.images = this.fileImages;
    }
    
    if (!this.mainImage) { this.product.mainImage = null; }
    this.service.save(this.product).subscribe((data: any) => {
      this.product._id = data._id
      this.doUpload(this.product._id);
      this.router.navigate(['product/', this.product._id, 'edit'])
      
      this.routeId = data._id
    }, err => {
      if (err.status === 409)
        for (const key in err.error) 
          this.step1.controls[key].setErrors({ duplicate: true });
    });
  }

  get() {
    this.service.get(this.routeId).subscribe((product: any) => {
      this.product = product;

      this.product.unit = this.product.unit || {};
      
      if (!product.color) product.color = '#000';
      this.fileImages = Object.assign([], product.images);
      this.mainImage  = product.mainImage;
      while (this.fileImages.length < 5) {
        this.fileImages.push(undefined);
      }
      
    });
  }

  doUpload(id) {
    if (this.mainFile) {
      this.service.upload(id, this.mainFile).subscribe(() => {});
    }
    if (this.files.length > 0) {
      this.files.map((file, index) => {
        if (file) {
          this.service.upload(id, file, String(index)).subscribe(() => {});
        }
      });
    }

    this.files = [];
  }

  addTag(tag) { 
    if (this.product.tags.indexOf(tag) < 0 && tag !== '') this.product.tags.push(tag); 
  }

  removeTag(tag) { this.product.tags = this.product?.tags.filter(t => t !== tag); }

  reader(file, mainFile = false, i?) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      if (mainFile) { this.mainImage = e.target.result; }
      else if (i >= 0) { this.fileImages[i] = e.target.result; }
      else { this.fileImages.push(e.target.result); }
    };
  }

  onMainFileSelect(event) {
    this.mainFile = event.target.files[0];
    this.reader(this.mainFile, true);
  }

  onFileSelect(event, i?) {
    if (this.files.filter(e => e != null).length === 5) { return; }

    if (i >= 0) {
      this.files[i] = event.target.files[0];
      this.reader(event.target.files[0], false, i);
    } else {
      this.reader(event.target.files[0]);
    }
  }

  emptyMainImage() {
    delete this.mainImage;
    delete this.mainFile;
  }

  emptyImageSelect(i) {
    delete this.files[i];
    delete this.fileImages[i];
  }

  previous() {
    this.stepper.previous();
    this.step--;
    this.title = this.titles[this.step].innerText;
  }

  forward() {
    this.stepper.next();
    this.step++;
  }

  next() {
    if (this.step === 0) {
      if (!this.product._id) {
        this.service.save(this.product).subscribe((data: any) => {
          this.product._id = data._id;
          this.forward();
        }, err => {
          if (err.status === 409)
            for (const key in err.error) this.step1.controls[key].setErrors({ duplicate: true });
        });
      } else this.forward();
    } else if (this.step === 1) {
      if (this.routeId) this.listOffers();
      this.listProviders();
      this.forward();
    } else this.forward();

    this.title = this.titles[this.step].innerText;
  }

  async listOffers() {
    const query = new Query();
    query.search.product = this.routeId;
    this.offerService.list(query).subscribe((offers: any) => this.offers = offers.data)
  }

  async listProviders() {
    this.providerService.list()
      .subscribe((providers: any) => { this.providers = providers; });
  }

  async listCategories() {
    this.categoryService.list().subscribe((categories: any) => {
      this.categories = categories;
    });
  }

  addProviderInput() {
    this.offers.push({
      product: this.routeId || this.product._id,
      provider: {},
      price: 0,
    });
  }

  removeProviderInput(i, offer) {
    if (offer._id) {
      this.offerService.delete(offer._id).subscribe( () => 
        this.offers.splice(i, 1)
      );
    } else this.offers.splice(i, 1);
  }

  updateUnit() {
    if(this.product.unit.provider === 'un') {
      this.product.unit = {
        label: 'un',
        provider: 'un',
        sellUnit: false
      }
    }
  }
}
