import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CompanyService } from '../../services/company.service';
import { ProviderService } from '../../services/provider.service';
import { ProductService } from '../../services/product.service';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-confirm-window',
  templateUrl: './confirm-window.component.html',
  styleUrls: ['./confirm-window.component.scss']
})
export class ConfirmWindowComponent {
  @Input() onYes;
  @Input() onNo;
  id: any;
  title: any;
  service: any;

  constructor(public modalRef: BsModalRef,
              public school: CompanyService,
              public provider: ProviderService,
              public product: ProductService,
              public user: UserService,
              public router: Router) {}

  confirm() {
    this.modalRef.hide();
    this.onYes(this.id);
  }

  decline(): void {
    this.modalRef.hide();
  }
}
