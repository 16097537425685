import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'web-app';
  showMenu = false;
  showLoading = false;
  loadingType = ngxLoadingAnimationTypes.circleSwish;

  constructor(private service: UserService,
              private loadingService: LoadingService,
              private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute) { }

  ngOnInit() {
    let token = this.service.TOKEN;

    if (token) this.showMenu = true;
    this.service.showMenu.subscribe((data) => {
      this.showMenu = data;
    });

    this.loadingService.loading.subscribe( (loading: boolean) => {
      this.showLoading = loading;
      this.changeDetectorRef.detectChanges();
    });
  }
}
