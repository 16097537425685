<div class="modal-body col-12 text-center">
  <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
    <span aria-hidden="true">&times;</span>
  </button>
  <p class="weight-medium">Navegar para outra categoria esvaziará sua sacola atual. Deseja ver os produtos dessa categoria?</p>

  <div class="d-flex align-items-center justify-content-center">
    <div class="action-btn mr-3" (click)="decline()">Cancelar</div>
    <div class="action-btn bg-secondary" (click)="confirm()">Ver produtos</div>
  </div>
</div>