
<div class="row mb-3">
  <div class="col-12 col-md-6 align-items-center">
    <h2>{{ category?.name }}</h2>
    <div class="pointer" *ngIf="company?.setAddress" (click)="company.setAddress = false">
      Entregar em: {{ calculateDelivery() }}
    </div>
  </div>
  <div class="col-12 col-md-6 d-flex align-items-center justify-content-end mt-3 mt-md-0" *ngIf="company?.setAddress">
    <app-search-input (inputChange)="setFilter($event);" class="col px-0 col-md-8"></app-search-input>
  </div>
</div>

<div *ngIf="!company?.setAddress">
  Selecione o endereço de entrega
  <div class="d-flex flex-column flex-md-row" *ngIf="company?.address?.street">
    <div
      class="item-wrapper border bg-light rounded d-flex p-3 m-0 mb-2 mr-md-2 pointer"
      *ngIf="company?.deliveryAddress?.street"
      (click)="setAddress(company.deliveryAddress)"
      [class.selected]="company.deliveryAddress?._id == company.setAddress?._id"
    >
      <div
        [inlineSVG]="'assets/icons/delivery.svg'"
        class="bg-secondary rounded-circle icon-white img-lg icon-sm d-flex justify-content-center align-items-center"
      ></div>
      <div class="d-flex flex-column flex-grow-1 align-items-end pl-4 text-left">
        <!--<small class="weight-medium text-medium">Endereço de entrega</small> -->
        <span class="address weight-medium text-align-end font-md">
          {{ company?.deliveryAddress?.street }},
          {{ company?.deliveryAddress?.number }} <br />
          <span *ngIf="company?.deliveryAddress?.complement">{{ company?.deliveryAddress?.complement }} - </span>
          {{ company?.deliveryAddress?.neighbourhood }}<br />
          {{ company?.deliveryAddress?.city }}, {{ company?.deliveryAddress?.state }}<br />
          CEP: {{ company?.deliveryAddress?.cep | mask: "00000-000" }}<br />
          {{ company?.deliveryAddress?.reference }}
        </span>
      </div>
    </div>

    <div
      class="item-wrapper border bg-light rounded d-flex p-3 m-0 mb-2 mr-md-2 pointer"
      (click)="setAddress(company.address)"
      [class.selected]="company.address?._id == company.setAddress?._id"
    >
      <div
        [inlineSVG]="'assets/icons/home.svg'"
        class="bg-secondary rounded-circle icon-white img-lg icon-sm d-flex justify-content-center align-items-center"
      ></div>
      <div class="d-flex flex-column flex-grow-1 align-items-end pl-4 text-left">
        <span class="address weight-medium text-align-end font-md">
          {{ company?.address?.street }},
          {{ company?.address?.number }} <br />
          <span *ngIf="company?.address?.complement">{{ company?.address?.complement }} - </span>
          {{ company?.address?.neighbourhood }}<br />
          {{ company?.address?.city }}, {{ company?.address?.state }}<br />
          CEP: {{ company?.address?.cep | mask: "00000-000" }}<br />
          {{ company?.address?.reference }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="company?.setAddress">
  <div class="mt-2 types-list">
    <a (click)="type = ''" class="wrapper m-2 py-2 px-4" style="cursor: pointer;" [ngClass]="{ 'active': !type }">Todos</a>
    <a *ngFor="let t of types" (click)="type = t" class="wrapper m-2 py-2 px-4" style="cursor: pointer;" [ngClass]="{ 'active': type == t }">{{t}}</a>
  </div>
  <div class="col-md-12 products-wrapper">
    <ng-container *ngFor="let product of products.data; let i = index">
      <div class="row item-wrapper px-0 py-3 m-0 border-bottom" *ngIf="showItem(product, i)">
        <div class="col-lg-6 d-flex align-items-center p-0 mb-md-0" [ngClass]="{'mb-2': !!product.unit}" >
          <img [src]="product?.mainImage ? product.mainImage : (product?.images?.length > 0) ? product.images[0] : '/assets/icons/avatar.svg'" class="product-photo img-lg">
          <h6 class="ml-2 mb-0 text-left text-medium">
            <div>{{ product?.name }}</div>
            <small *ngIf="product?.brand" class="mr-2">{{ product?.brand }}</small>
            <small *ngIf="product?.unit?.provider == 'kg'">{{ product?.price | currency:'BRL' }} / kg</small>
          </h6>

          <app-unit-selection class="d-flex d-lg-none ml-auto" [product]="product" (unitSelected)="change(product, false)" [ngClass]="{'disabled' : !product?.amount }"></app-unit-selection>
        </div>
        <div class="col-lg-6 p-0 d-flex align-items-center">
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex align-items-center col-6 p-0">
              <span>Menor Preço: </span>
              <span class="text-highlight highlight ml-2">
                {{getPrice(product, true) | currency:'BRL'}}
              </span>
            </div>

            <app-unit-selection class="d-none d-lg-flex" [product]="product" (unitSelected)="change(product, false)" [ngClass]="{'disabled' : !product?.amount }"></app-unit-selection>

            <div class="d-flex align-items-center justify-content-end p-0 ml-auto">
              <div [inlineSVG]="'/assets/icons/subtract.svg'" [ngClass]="{'subtract-color pointer' : product?.amount }" class="d-flex align-items-center mr-2 subtract" (click)="subtract(product)"></div>
              <form>
                <input type="number" class="form-control text-center" size="1" name="product" min="0" max="999" [(ngModel)]="product && product.amount" (focus)="oldValue = product.amount" (change)="change(product)">
              </form>
              <div inlineSVG="/assets/icons/add.svg" class="d-flex align-items-center ml-2 pointer" (click)="add(product)"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="mt-4 text-center" *ngIf="count == maxItems">
      <div
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="10"
        (scrolled)="maxItems = maxItems + 5">
      </div>
    </div>
  </div>
  <!-- <div class="col mt-2">
    <pagination
      [totalItems]="products?.total"
      [itemsPerPage]="query.limit"
      (pageChanged)="query.page = $event.page; list()"
      [boundaryLinks]="true"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
    ></pagination>
  </div> -->
  <div class="button-wrapper">
    <action-button *ngIf="total > 0" label="Avançar" className="action-button" routerLink="/order/provider">
    </action-button>
  </div>
</div>