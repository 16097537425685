<form class="my-5 text-left" #formVar="ngForm">
    <h4 class="form-title">Esqueci minha senha</h4>
    <div class="callback-message text-justify" *ngIf="callback">
        <img src="/assets/icons/confirm.png" class="mr-2" alt="confirm icon">
        <span>Redefinição solicitada com sucesso. Se este e-mail corresponde a uma conta ativa, você receberá uma mensagem de recuperação em sua caixa de entrada. </span>
    </div>
    <div class="form-group">
        <label for="email" class="text-secondary" [class.error]="email.invalid && (email.dirty || email.touched)">
          E-mail
        </label>
        <input type="text" [class.error]="email.invalid && (email.dirty || email.touched)" class="form-control" id="email" placeholder="email@email.com.br" name="email" [(ngModel)]="user.email" #email="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z00-9.-]+\.[a-zA-Z00-9\s]{1,}" required>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
            <div *ngIf="email.errors.required">
              Este campo é obrigatório
            </div>
            <div *ngIf="email.errors.pattern">
              E-mail inválido
            </div>
        </div>
    </div>
    <button [disabled]="formVar.form.invalid" class="btn button primary mt-3 mw-100 d-flex align-items-center justify-content-center" (click)="!loading ? recoveryPassword(user) : null">
      Redefinir senha
      <div class="spinner white ml-2" *ngIf="loading"></div>
    </button>
    <div class="text-left mt-2">
        <a class="form-link" routerLink="/">Voltar para o login</a>
    </div>
</form>