import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ProviderService } from "src/app/shared/services/provider.service";
import { OfferService } from "src/app/shared/services/offer.service";
import { ProductService } from "src/app/shared/services/product.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { ExportService } from 'src/app/shared/services/export.service';

import { FormStepper } from "src/app/shared/classes/FormStepper";
import { NgForm } from "@angular/forms";

import { ConfirmWindowComponent } from 'src/app/shared/ui/confirm-window/confirm-window.component';

import { Query } from 'src/app/shared/util/query';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import { utils as XLSXUtils, read as XLSXRead } from 'xlsx';
import { off } from "process";

@Component({
  selector: "app-provider-form",
  templateUrl: "./provider-form.component.html",
  styleUrls: ["./provider-form.component.scss"],
})
export class ProviderFormComponent implements OnInit, AfterViewInit {
  provider: any = {
    address: { cep: "" }, cities: [],
    contacts: { commercial: {}, orders: {}, financial: {} },
  };
  offers: any;
  products: any;
  newOffer: any = {};
  _city: any = {}
  _cities = []
  routeId;
  logoFile;
  logoImage;

  errors = { cep: false, submit: false };
  loadings = { cep: false, submit: false };

  @ViewChild("providerForm") form: NgForm;
  stepper;
  contacts = {
    commercial: "Comercial",
    financial: "Financeiro",
    orders: "Pedidos",
  };

  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private service: ProviderService,
    private exportService: ExportService,
    private sharedService: SharedService,
    private offerService: OfferService,
    private productService: ProductService,
  ) {}

  ngOnInit() {
    this.stepper = new FormStepper(document.getElementById("stepper1"), {
      linear: false,
      animation: false,
    });
    
    this.routeId = this.route.snapshot.params.id;

    if (this.routeId) this.getProviderDetails();

    this.sharedService.getCities().subscribe((cities: any) => {
      this._cities = cities
    })
  }

  ngAfterViewInit() {
    this.stepper.titles = document.querySelectorAll(".bs-stepper-desc");
    this.stepper.title = this.stepper.titles[this.stepper.step].innerText;
  }

  async save() {
    const valid = await this.stepper.validate(this.form);
    if (!valid) return;

    this.provider.address.cep = String(this.provider.address.cep).replace(/[_-]/g,"");
    this.provider.cnpj = String(this.provider.cnpj).replace(/[\/_-]/g, "");
    this.provider.phone = String(this.provider.phone).replace(/[\/()_-]/g, "");

    this.service.save(this.provider).subscribe((res: any) => {
      if (this.logoFile) {
        this.service.upload(res._id, this.logoFile).subscribe(() => {
          this.router.navigate(["/provider"]);
        });
      } else {
        this.router.navigate(["/provider"]);
      }
    });
  }

  export() {
    this.exportService.getAndExport('Ofertas ' + this.provider.name, 'report/offer/' + this.provider._id)
  }

  async import(e) {
    const file = e.target.files[0];
  
    const data = await file.arrayBuffer();

    const workbook = XLSXRead(data)

    const ws = XLSXUtils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
    
    for (const p of ws) {
      const offer: any = {}

      offer.provider = this.provider._id
      offer.price = parseFloat(p["Preco"].toString().replace(',','.')),
      offer.product = {
        name: p["Nome"],
        code: p["Codigo"],
      }

      this.offerService.save(offer).subscribe(() => {}) 
    }
  }

  removeItem(arr, index) { arr.splice(index, 1) }

  addEmail(email) {
    if (!this.provider.emails) this.provider.emails = [];

    this.provider.emails.push(email);
  }

  addCity() {
    if (!this.provider.cities) this.provider.cities = [];

    this.provider.cities.push({ c_edit: true, p_edit: true, t_edit: true, days: [1,2,3,4,5] })
  }

  getTerm(item) {
    return item?.term / 24 > 1 ? (Math.trunc(item?.term / 24) + ' dias') : 'Mesmo dia'
  }

  getProviderDetails() {
    this.service.get(this.routeId).subscribe((provider: any) => {
      if (!provider.contacts)
        provider.contacts = { commercial: {}, orders: {}, financial: {} };
      else {
        for (const key in this.contacts)
          if (!provider.contacts[key]) provider.contacts[key] = {};
      }

      this.provider = { ...this.provider, ...provider };
      this.logoImage = provider.logo;
    });

    this.getOffers();
    this.getProducts();
  }

  getOffers() {
    const query = new Query();
    query.search.provider = this.routeId;
    query.search.populate = 'product';
    query.limit = 0;

    this.offerService.list(query).subscribe((offers) => {
      this.offers = offers;

      this.offers.data = this.offers.data.sort((o1, o2) => {
        if(!o1.product || !o2.product) return 1
        return o1.product.name > o2.product.name ? 1 : -1;
      });
    });
  }

  getProducts() {
    const query = new Query();
    query.limit = 100000;

    this.products = []
    this.productService.list(query).subscribe((products: any) => {
      products.data.forEach(p => {
        this.products.push({
          _id: p._id,
          name: `${p.name} - ${p.brand || ''}`
        });
      });

    });
  }

  toggleDays(city, day) {
    if(!city.days) city.days = []

    let index = city.days.indexOf(day)

    if(index < 0) {
      city.days.push(day)
    }else city.days.splice(index, 1)

    city.days.sort((d1,d2) => d1 - d2)
  }

  editOfferPrice(offer) {
    offer.newPrice = offer.price;
    offer.newPricePF = offer.pricePF;
    offer.isEditing = true;
  }

  setNewOfferProduct(event) {
    this.newOffer.product = event.item._id;
  }

  setOfferPrice(offer) {
    offer.price = offer.newPrice;
    offer.pricePF = offer.newPricePF;
    this.offerService.save(offer).subscribe(res => {
      offer.isEditing = false;
    });
  }

  createNewOffer() {
    this.newOffer.provider = this.routeId;
    this.offerService.save(this.newOffer).subscribe(res => {
      this.newOffer.open = false;
      this.newOffer.price = null;
      this.newOffer.product = null;
      this.getOffers();
    });
  }

  confirm(event, id) {
    event.preventDefault();
    event.stopPropagation();

    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        onYes: () => this.deleteOrder()(id)
      }
    });
  }

  deleteOrder() {
    return (id) => {
      this.offerService.delete(id).subscribe(() => {
        this.offers.data = this.offers.data.filter(o => o._id !== id);
      });
    };
  }

  readFile(e) {
    this.logoFile = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(this.logoFile);
    reader.onload = (ev: any) => {
      this.logoImage = ev.target.result;
    };
  }

  openForm() {
    this.newOffer.open = true;
  }

  getCep(e, valid) {
    if (!valid) {
      return;
    }

    this.loadings.cep = true;
    const cep = e.target.value;

    this.sharedService.getCep(cep).subscribe(
      (res: any) => {
        if (!res.erro) {
          this.errors.cep = false;
          this.provider.address.state = res.uf;
          this.provider.address.neighbourhood = res.bairro;
          this.provider.address.city = res.localidade;
          this.provider.address.street = res.logradouro;
          this.provider.address.complement = res.complemento;
          this.loadings.cep = false;
        } else {
          this.errors.cep = true;
          this.loadings.cep = false;
        }
      },
      (err) => {
        this.errors.cep = true;
        this.loadings.cep = false;
      }
    );
  }
}
