import Stepper from 'bs-stepper';

export class FormStepper extends Stepper {
  public step = 0;
  public titles: HTMLElement[];
  public title: string;
  private stepper;

  constructor(element: Element, options?) {
    super(element, options);
  }

  updateTitle() {
    this.title = this.titles[this.step].innerText;
  }

  previous() {
    super.previous();
    this.step--;
    this.updateTitle();
  }

  next() {
    super.next();
    this.step++;
    this.updateTitle();
  }

  to(step) {
    super.to(step + 1);
    this.step = step;
    this.updateTitle();
  }

  scrollTo(el) {
    const parent: HTMLElement = el.closest('.content');
    const step = parseInt(parent.getAttribute('data-step'), 10);
    this.to(step);
    const elementRect = el.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - (window.innerHeight / 2);
    return window.scrollTo({ top: middle, behavior: 'smooth' });
  }

  async validate(form): Promise<boolean> {
    for (const key in form.form.controls) {
      const formControl: any = form.form.controls[key];
      if (formControl.invalid) {
        formControl.markAsTouched();
        this.scrollTo(formControl.nativeElement);
        return false;
      }
    }
    return true;
  }
}
