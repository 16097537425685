import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ConfirmWindowComponent } from '../confirm-window/confirm-window.component';


@Component({
  selector: 'box-item',
  templateUrl: './box-item.component.html',
  styleUrls: ['./box-item.component.scss']
})
export class BoxItemComponent implements OnInit {

  @Input() data: any;
  @Input() title: any;
  @Input() onDelete;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  openModal(id) {
    this.modalRef = this.modalService.show(ConfirmWindowComponent, {
      initialState: {
        title: this.title,
        id,
        onYes: this.onDelete
      }
    });
  }

  updateImage(event) {
    event.target.classList.remove('rounded-circle');
    event.target.src = '/assets/icons/avatar.svg';
  }
}
