import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Input() title: any;
  @Input() link: any;
  @Input() search: boolean;
  @Output() textChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  timeout;
  onInputChange(event) {
    clearTimeout(this.timeout)
    
    this.timeout = setTimeout(() => {
      this.textChange.emit(event.target.value);
    }, 300)
  }
}
